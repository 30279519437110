function erp_match_all(item) {
    item.color = 'black'
    item.bgcolor = '#A5D7A7'
    item.left = 0
}
function erp_match_some(item) {
    item.color = 'black'
    item.bgcolor = '#42A5F5'
}
function erp_match_fail(item) {
    item.color = 'white'
    item.bgcolor = '#C10015'
}
function erp_match_none(item) {
    item.color = null
    item.bgcolor = null
    item.left = item.QUANTITY
}

//三个表格数据
const orderbundleData = {
    ordertableSet: {
        tableName: 'orderbundleData.ordertableSet',
        height: "824px",
        loading: false,
        rowkey: 'FEntryID',
        selection: 'single',
        selected: [],
        header: true,//表头添加*验证的插槽是否显示
        pagination: { rowsPerPage: 0 },
        //dataItem中如果有bgColor属性，背景变色；有color属性，文字变色
        data: [],
        columns: [
            {
                check: true, name: 'F_LineNo', label: 'ERP订单行号', align: 'right', field: 'F_LineNo', sortable: true, required: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, name: 'F_MaterialNo', label: '订货号', align: 'left', field: 'F_MaterialNo', sortable: true, required: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_ProductOption', label: '选件', align: 'left', field: 'F_ProductOption', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true,  name: 'QUANTITY', label: '数量', align: 'left', field: 'QUANTITY', sortable: true, required: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_ERPMaterialCode', label: 'ERP物料编码', align: 'left', field: 'F_ERPMaterialCode', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_ERPMaterialName', label: 'ERP物料名称', align: 'left', field: 'F_ERPMaterialName', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_ERPUnit', label: 'ERP单位', align: 'left', field: 'F_ERPUnit', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'L2_PRICE', label: '表价', align: 'left', field: 'L2_PRICE', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'DISCOUNT', label: '折扣（%）', align: 'left', field: 'DISCOUNT', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_Amount', label: '金额', align: 'left', field: 'F_Amount', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, name: 'F_ARVDate', label: '期望交货日期', align: 'left', field: 'F_ARVDate', sortable: true, required: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ProductListPrice', label: '西门子表价', align: 'left', field: 'ProductListPrice', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ZDIS_Rate', label: '物料折扣', align: 'left', field: 'ZDIS_Rate', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_status', label: '物料状态', align: 'left', field: 'Material_status', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Substitute', label: '替代物料', align: 'left', field: 'Substitute', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Substitute2', label: '替代物料2', align: 'left', field: 'Substitute2', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
        ],
        tools: {
            show_toolbar: true,

            title: '采购订单详情',

        }
    },
    BOMtableSet: {
        tableName: 'orderbundleData.BOMtableSet',
        bom: true,
        height: '400px',
        loading: false,
        // rowkey: 'id',
        // selection: 'single',
        // selected: [],
        separator: 'cell',
        pagination: { rowsPerPage: 0 },
        data: [],
        dealedData: {}, //处理后的数据
        columns: [
            {
                check: true, required: false, name: 'MLFB_Sort', label: '订货号（Sort）（主物料）', align: 'left', field: 'MLFB_Sort', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Quantity', label: '数量（主物料）', align: 'left', field: 'Quantity', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component_Sort', label: '订货号（Sort）（子物料）', align: 'left', field: 'Component_Sort', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Conponent_Quantity', label: '数量（子物料）', align: 'left', field: 'Conponent_Quantity', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'BOMUsg', label: 'BOM类型', align: 'left', field: 'BOMUsg', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'BOMNo', label: 'BOM编号', align: 'left', field: 'BOMNo', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'BOMSt', label: 'BOM状态', align: 'left', field: 'BOMSt', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'AltBOM', label: 'BOM组号', align: 'left', field: 'AltBOM', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material', label: '西门子物料编号（主物料)', align: 'left', field: 'Material', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Description', label: '物料描述（主物料)', align: 'left', field: 'Description', sortable: false,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'Option', label: '选件（主物料）', align: 'left', field: 'Option', sortable: false,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'Unit', label: '单位（主物料）', align: 'left', field: 'Unit', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Status', label: '物料状态（主物料）', align: 'left', field: 'Status', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Del_Flag', label: '物料删除标志（主物料）', align: 'left', field: 'Del_Flag', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component_Description', label: '物料描述（子物料）', align: 'left', field: 'Component_Description', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Item', label: '序号（子物料）', align: 'left', field: 'Item', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ICt', label: '分类（子物料）', align: 'left', field: 'ICt', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component', label: '西门子物料编号（子物料）', align: 'left', field: 'Component', sortable: false,
                format: (val) => `${val ? val : ''}`
            },


            {
                check: true, required: false, name: 'Component_Option', label: '选件（子物料）', align: 'left', field: 'Component_Option', sortable: false,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'Component_Unit', label: '单位（子物料）', align: 'left', field: 'Component_Unit', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ValidFrom', label: '开始时间', align: 'left', field: 'ValidFrom', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ValidTo', label: '结束时间', align: 'left', field: 'ValidTo', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component_Status', label: '物料状态（子物料）', align: 'left', field: 'Component_Status', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component_Del_Flag', label: '物料删除标志（子物料）', align: 'left', field: 'Component_Del_Flag', sortable: false,
                format: (val) => `${val ? val : ''}`
            },

        ],
        tools: {
            show_toolbar: true,
            title: 'BOM数据',
            input: { search: "" },
            // btns: [
            //     {
            //         sign: 'send',
            //         index: 2,
            //         name: ['订单发送', ''],
            //         icon: 'fas fa-cloud-upload-alt',
            //         click: ['outbound', 'tools_set'],
            //     },
            // ]
        }
    },
    bundletableSet: {
        tableName: 'orderbundleData.bundletableSet',
        height: '400px',
        loading: false,
        // rowkey: 'id',
        // selection: 'single',
        // selected: [],
        separator: 'cell',
        bundle:true,
        pagination: { rowsPerPage: 0 },
        data: [],
        columns: [
            {
                check: true, required: false, name: 'O_LineNo', label: '订单行号', align: 'right', field: 'O_LineNo', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_LineNo', label: 'ERP订单行号', align: 'right', field: 'F_LineNo', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'MLFB_Sort', label: '订货号（Sort）（主物料）', align: 'left', field: 'MLFB_Sort', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Quantity', label: '数量（主物料）', align: 'left', field: 'Quantity', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component_Sort', label: '订货号（Sort）（子物料）', align: 'left', field: 'Component_Sort', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Conponent_Quantity', label: '数量（子物料）', align: 'left', field: 'Conponent_Quantity', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            // {
            //     check: true, required: false, name: 'F_MaterialNo_DDD', label: '订货号', align: 'left', field: 'F_MaterialNo_DDD', sortable: false,
            //     format: (val) => `${val ? val : ''}`
            // },
            // {
            //     check: true, required: false, name: 'QUANTITY_DDD', label: '数量', align: 'left', field: 'QUANTITY_DDD', sortable: false,
            //     format: (val) => `${val ? val : ''}`
            // },
            {
                check: true, required: false, name: 'Option', label: '选件(主物料)', align: 'left', field: 'Option', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_ERPMaterialCode', label: 'ERP物料编码', align: 'left', field: 'F_ERPMaterialCode', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_ERPMaterialName', label: 'ERP物料名称', align: 'left', field: 'F_ERPMaterialName', sortable: false,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'F_ProductOption', label: '选件', align: 'left', field: 'F_ProductOption', sortable: false,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'F_ERPUnit', label: 'ERP单位', align: 'left', field: 'F_ERPUnit', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'L2_PRICE', label: '表价', align: 'left', field: 'L2_PRICE', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'DISCOUNT', label: '折扣（%）', align: 'left', field: 'DISCOUNT', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_Amount', label: '金额', align: 'left', field: 'F_Amount', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_ARVDate', label: '期望交货日期', align: 'left', field: 'F_ARVDate', sortable: false,
                format: (val) => `${val ? val : ''}`
            },

        ],
        tools: {
            show_toolbar: true,
            title: 'bundle订单详情',

        }
    },
    isUseCache: false,   //是否使用缓存
    lastSelectIndex: 0,  //上次访问的第几项
    isNeedInitOrderTabData: true,
    isNeedInitBomTabData: true,
}

const baseData = {
    baseData: {
        showQuery: true,
    }
}

const selectData = {
    country: sessionStorage.getItem('basedata') ? JSON.parse(sessionStorage.getItem('basedata')).country : [],
    payment: sessionStorage.getItem('basedata') ? JSON.parse(sessionStorage.getItem('basedata')).payment : [],
    receipt_address: sessionStorage.getItem('basedata') ? JSON.parse(sessionStorage.getItem('basedata')).receipt_address : []
}

const orderpostData = {
    tableSet: {
        tableName: 'orderpostData.tableSet',
        loading: false,
        rowkey: 'FEntryID',
        selection: 'single',
        selected: [],
        header: true,
        pagination: { rowsPerPage: 0 },
        data: [],
        columns: [
            {
                check: true,  name: 'F_LineNo', label: 'ERP订单行号', align: 'right', field: 'F_LineNo', sortable: true, required: true,
                format: (val) => `${val ? val : ''}`
            },
            //F_SPRNo
            {
                check: true, required: false, name: 'F_SPRLineNo', label: '特价单行号', align: 'right', field: 'F_SPRLineNo', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_ERPMaterialCode', label: 'ERP物料编码', align: 'left', field: 'F_ERPMaterialCode', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_ERPMaterialName', label: 'ERP物料名称', align: 'left', field: 'F_ERPMaterialName', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, name: 'F_MaterialNo', label: '订货号', align: 'left', field: 'F_MaterialNo', sortable: true, required: true,
                format: (val) => `${val !== null ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_ProductOption', label: '选件', align: 'left', field: 'F_ProductOption', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, name: 'QUANTITY', label: '数量', align: 'left', field: 'QUANTITY', sortable: true, required: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_ERPUnit', label: 'ERP单位', align: 'left', field: 'F_ERPUnit', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Sales_Unit', label: '西门子销售单位', align: 'left', field: 'Sales_Unit', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Base_unit', label: '西门子基本单位', align: 'left', field: 'Base_unit', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'L2_PRICE', label: 'ERP表价', align: 'left', field: 'L2_PRICE', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'DISCOUNT', label: 'ERP折扣（%）', align: 'left', field: 'DISCOUNT', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_Amount', label: 'ERP金额', align: 'left', field: 'F_Amount', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, name: 'F_ARVDate', label: '期望交货日期', align: 'left', field: 'F_ARVDate', sortable: true, required: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ProductListPrice', label: '西门子表价', align: 'left', field: 'ProductListPrice', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ZDIS_Rate', label: '物料折扣（%）', align: 'left', field: 'ZDIS_Rate', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_status', label: '物料状态', align: 'left', field: 'Material_status', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Substitute', label: '替代物料', align: 'left', field: 'Substitute', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Substitute2', label: '替代物料2', align: 'left', field: 'Substitute2', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            //  根据接口返回会动态添加3个字段
        ],
        tools: {
            show_toolbar: true,
            show_toolbtns: false,
            title: '采购订单详情',
            // btns: [
            //     {
            //         sign: 'export',
            //         index: 1,
            //         name: ['导出数据', ''],
            //         icon: 'fas fa-file-export',
            //         click: ['outbound', 'tools_set'],
            //     },
            //     {
            //         sign: 'refresh',
            //         index: 2,
            //         name: ['重置页面数据', ''],
            //         icon: 'fas fa-redo',
            //         click: ['outbound', 'tools_set'],
            //     },
            // ]
        },
        is_sended: false,
    },
}

//两个form
const formData = {
    formSet: {
        form_class: 'base_form',
        loading: false,
        is_sended:true,
        body: [
            {
                index: 1, body_class: 'q-gutter-sm row items-start',
                items: [
                    {
                        index: 2, label: '采购订单', showlabel: true, name: 'F_BillNo', value: '', type: 'input', filled: true, outlined: true, disable: true, required: true, variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }, minWidth: 160
                    },
                    {
                        index: 3, label: '单据日期', showlabel: true, name: 'F_CreatorDate', value: '', type: 'input', filled: true, outlined: true, disable: true, required: true, variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }, minWidth: 160
                    },
                    {
                        index: 7, label: '制单人', showlabel: true, name: 'F_CreatorName', value: '', type: 'input', filled: true, outlined: true, disable: true, variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }, minWidth: 160
                    },
                    {
                        index: 8, label: '制单人邮箱', showlabel: true, name: 'F_CreatorEmail', value: '', type: 'input', filled: true, outlined: true, disable: true, variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }, minWidth: 160
                    },
                    {
                        index: 9, label: '制单人电话', showlabel: true, name: 'F_CreatorTel', value: '', type: 'input', filled: true, outlined: true, disable: true, variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }, minWidth: 160
                    },
                    {
                        index: 4, label: '期望交货日期', showlabel: true, name: 'F_ARVDate', value: '', type: 'date', filled: false, outlined: true, disable: false, variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }, minWidth: 160
                    },
                    {
                        index: 10, label: '特价单号', showlabel: true, name: 'F_SPRNo', value: '', type: 'input', filled: false, outlined: true, disable: false, variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }, minWidth: 160
                    },
                    { index: 13, label: '最终客户英文名称', showlabel: true, name: 'F_ENDCUSTOMER_NAME', value: '', type: 'input', filled: false, outlined: true, disable: false, minWidth: 160 },
                    {
                        index: 14, label: '最终客户国家', showlabel: true, name: 'F_ENDCUSTOMER_COUNTRY', value: '', type: 'select', filled: false,disable: false,
                        select_options: [],
                        minWidth: 160
                    },
                    { index: 15, label: 'OSD邮箱', showlabel: true, name: 'F_OSDEmail', value: '', type: 'input', filled: false, outlined: true, disable: false, minWidth: 160 },
                    {
                        index: 5, label: '付款方式', showlabel: true, name: 'F_PAYMENT_TERM', value: '', type: 'select', filled: false,disable: false,
                        select_options: [],
                        minWidth: 160
                    },
                    {
                        index: 6, label: '收货地址', showlabel: true, name: 'F_SHIP_2_PARTY', value: '',placeholder:'多个邮箱之间用英文分号分隔' ,type: 'select', filled: false,disable: false, required: true,
                        select_options: [],
                        minWidth: 160
                    },
                    {
                        index: 16, label: '邮件通知列表', showlabel: true, name: 'Notification_Email_List', value: '', placeholder:'多个邮箱之间用英文分号分隔', type: 'input', filled: false, outlined: true, required: false, disable: false,
                        rows: 1, minWidth: 160
                    },
                    { index: 18, label: '销售邮箱', showlabel: true, name: 'F_SALESEMPLOYEE_EMAIL', value: '', type: 'input', filled: false, outlined: true, disable: false, minWidth: 160 },
                    {
                        index: 17, label: '备注', showlabel: true, name: 'F_COMMENTS', value: '', type: 'input', filled: false, outlined: true, disable: false,
                        rows: 1, minWidth: 160
                    },
                ]
            },
            {
                index: 2, body_class: 'q-gutter-sm row items-start second-form',
                items: [
                    {
                        index: 1, label: 'ERP订单总金额', showlabel: true, name: 'Total_Amount', value: '', type: 'input', filled: true, outlined: true, disable: true,
                        variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }
                    },
                    {
                        index: 3, label: '订单总金额(参考)', showlabel: true, name: 'Total_NetValue', value: '', type: 'input', filled: true, outlined: true, disable: true,
                        variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }
                    },

                    {
                        index: 2, label: '信用余额', showlabel: true, name: 'F_Amount', value: '', type: 'input', filled: true, outlined: true, disable: true,
                        variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true, hint: 'F_Amount'
                        },
                        append: {
                            after: { btn: { label: '', showlabel: false, icon: 'fas fa-search', click: ['basedata', 'fetch_credit'] } }
                        }
                    },

                    {
                        index: 3,
                        btns: [
                            { label: '数据校验', showlabel: false, type: 'orderNo', disabled: false },
                            { label: '发送订单', showlabel: false, type: 'submit', disabled: false },
                            { label: '收起/展开', showlabel: false, type: 'fold' },
                        ]
                    },

                ]
            }
        ]
    },
    bundleForm: {
        form_class: 'base_form',
        loading: false,
        body: [
            {
                index: 1, body_class: 'q-gutter-sm row items-start',
                items: [
                    {
                        index: 2, label: '采购订单', showlabel: true, name: 'F_BillNo', value: '', type: 'input', filled: true, outlined: true, disable: true, required: true, variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }, minWidth: 160
                    },
                    {
                        index: 3, label: '单据日期', showlabel: true, name: 'F_CreatorDate', value: '', type: 'input', filled: true, outlined: true, disable: true, required: true, variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }, minWidth: 160
                    },
                    {
                        index: 7, label: '制单人', showlabel: true, name: 'F_CreatorName', value: '', type: 'input', filled: true, outlined: true, disable: true, variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }, minWidth: 160
                    },
                    {
                        index: 8, label: '制单人邮箱', showlabel: true, name: 'F_CreatorEmail', value: '', type: 'input', filled: true, outlined: true, disable: true, variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }, minWidth: 160
                    },
                    {
                        index: 9, label: '制单人电话', showlabel: true, name: 'F_CreatorTel', value: '', type: 'input', filled: true, outlined: true, disable: true, variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }, minWidth: 160
                    },
                    {
                        index: 4, label: '期望交货日期', showlabel: true, name: 'F_ARVDate', value: '', type: 'date', filled: false, outlined: true, disable: false, variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }, minWidth: 160
                    },

                    { index: 13, label: '最终客户英文名称', showlabel: true, name: 'F_ENDCUSTOMER_NAME', value: '', type: 'input', filled: false, outlined: true, disable: false, minWidth: 160 },
                    {
                        index: 14, label: '最终客户国家', showlabel: true, name: 'F_SHIP_2_COUNTRY', value: '', type: 'select',filled: false, disable: false,
                        select_options: [],
                        minWidth: 160
                    },
                    { index: 15, label: 'OSD邮箱', showlabel: true, name: 'F_SHIP_2_EMAIL', value: '', type: 'input', filled: false, outlined: true, disable: false, minWidth: 160 },
                    {
                        index: 5, label: '付款方式', showlabel: true, name: 'F_PAYMENT_TERM', value: '', type: 'select',filled: false, disable: false,
                        select_options: [],
                        minWidth: 160
                    },
                    {
                        index: 6, label: '收货地址', showlabel: true, name: 'F_SHIP_2_PARTY', value: '', type: 'select', required: true,filled: false, disable: false,
                        select_options: [],
                        minWidth: 160
                    },
                    {
                        index: 16, label: '邮件通知列表', showlabel: true, name: 'Notification_Email_List', value: '',placeholder:'多个邮箱之间用英文分号分隔', type: 'input', filled: false, outlined: true, required: true, disable: false,
                        rows: 1, minWidth: 160
                    },
                    {
                        index: 17, label: '备注', showlabel: true, name: 'F_COMMENTS', value: '', type: 'input', filled: false, outlined: true, disable: false,
                        rows: 1, minWidth: 160
                    },
                ]
            },
            {
                index: 2, body_class: 'q-gutter-sm row items-start second-form',
                items: [
                    {
                        index: 1, label: 'ERP订单总金额', showlabel: true, name: 'Total_Amount', value: '', type: 'input', filled: true, outlined: true, disable: true,
                        variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }
                    },
                    {
                        index: 3, label: '订单总金额(参考)', showlabel: true, name: 'Total_NetValue', value: '', type: 'input', filled: true, outlined: true, disable: true,
                        variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                        }
                    },

                    {
                        index: 2, label: '信用余额', showlabel: true, name: 'F_Amount', value: '', type: 'input', filled: true, outlined: true, disable: true,
                        variant: {
                            origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true, hint: 'F_Amount'
                        },
                        append: {
                            after: { btn: { label: '', showlabel: false, icon: 'fas fa-search', click: ['basedata', 'fetch_credit'] } }
                        }
                    },

                    {
                        index: 3,
                        btns: [
                            { label: '一键匹配', showlabel: false, type: 'match', disabled: false },
                            { label: '发送订单', showlabel: false, type: 'submit', disabled: false },
                            { label: '收起/展开', showlabel: false, type: 'fold' },
                        ]
                    },

                ]
            }
        ]
    }

}
const notifyData = {
    orderErrors: [],
    bomErrors: [],
    orderWarning: [],
    bomWarning: []
}

const state = () => ({
    orderpost: Object.assign({}, orderpostData, formData, baseData, selectData, orderbundleData, notifyData),
    tabMode: '',
    header: '',
})
const getters = {
    baseData: state => state.orderpost.baseData, //展开关闭状态
    isSendedForBundle: state => state.orderpost.ordertableSet.is_sended,
    isLoading :  state => state.orderpost.formSet.loading,
    formSet: state => state.orderpost.formSet,
    tableSet: state => state.orderpost.tableSet,

    bundleForm: state => state.orderpost.bundleForm,
    BOMtableSet: state => state.orderpost.BOMtableSet,
    ordertableSet: state => state.orderpost.ordertableSet,
    bundletableSet: state => state.orderpost.bundletableSet,

    orderErrors: state => state.orderpost.orderErrors,
    bomErrors: state => state.orderpost.bomErrors,
    orderWarning: state => state.orderpost.orderWarning,
    bomWarning: state => state.orderpost.bomWarning,

    isUseCache: state => state.orderpost.isUseCache,
    lastSelectIndex: state => state.orderpost.lastSelectIndex,
    isNeedInitOrderTabData: state => state.orderpost.isNeedInitOrderTabData,
    isNeedInitBomTabData: state => state.orderpost.isNeedInitBomTabData,
}
const mutations = {

    //data格式： true or false
    update_is_send(state, data){
        const is_sended = data
        state.orderpost.ordertableSet.is_sended = is_sended
        state.orderpost.bundleForm.body[1].items[3].btns[0].disabled = is_sended
        state.orderpost.bundleForm.body[1].items[3].btns[1].disabled = is_sended

        state.orderpost.bundleForm.body[1].items[3].btns[0].disabled = true
        state.orderpost.bundleForm.body[1].items[3].btns[1].disabled = true
        for (var i = 5; i < state.orderpost.bundleForm.body[0].items.length; i++) {
            state.orderpost.bundleForm.body[0].items[i].disable = true;
            state.orderpost.bundleForm.body[0].items[i].filled = true;
        }
    },

    //直接更新
    update_bundletableSet_data(state, data) {
        state.orderpost.bundletableSet.data = data
    },

    updateIsUseCache(state, data) {
        state.orderpost.isUseCache = data
        if (!state.orderpost.isUseCache) {
            state.orderpost.isNeedInitOrderTabData = true
            state.orderpost.isNeedInitBomTabData = true
        }
    },
    updateLastSelectIndex(state, data) {
        state.orderpost.lastSelectIndex = data
    },

    orderDetail(state, data) {
        if (data.basedata) {
            // sessionStorage.setItem('basedata', JSON.stringify(data.basedata))
            //设置selcet框
            state.orderpost.formSet.body[0].items[8].select_options = data.basedata.country.map(item => {
                return { label: item.name, value: item.code }
            })
            state.orderpost.formSet.body[0].items[10].select_options = data.basedata.payment.map(item => {
                return { label: item.name, value: item.code }
            })
            state.orderpost.formSet.body[0].items[11].select_options = data.basedata.receipt_address.map(item => {
                return { label: item.code + '-' + item.name, value: item.code }
            })


            state.orderpost.bundleForm.body[0].items[7].select_options = data.basedata.country.map(item => {
                return { label: item.name, value: item.code }
            })
            state.orderpost.bundleForm.body[0].items[9].select_options = data.basedata.payment.map(item => {
                return { label: item.name, value: item.code }
            })
            state.orderpost.bundleForm.body[0].items[10].select_options = data.basedata.receipt_address.map(item => {
                return { label: item.code + '-' + item.name, value: item.code }
            })



        }
        if (data.other) {
            //ERP订单总金额和订单总金额(参考)
            state.orderpost.formSet.body[1].items[0].value = Number(data?.other.o_c)?.toFixed(2) || 0.00;
            state.orderpost.formSet.body[1].items[1].value = Number(data?.other.o_m)?.toFixed(2) || 0.00;
        }
        if (data.credit) {
            state.orderpost.formSet.body[1].items[2].value =Number(data?.credit)?.toFixed(2) || 0.00;
        }
        if (data.entry && data.entry[0]) {
            //主页的表格
            //普通订单的动态字段
            if ('maamount' in data.entry[0] && 'maprice' in data.entry[0] && 'rate_' in data.entry[0]) {
                console.log('000有///');
                const rateObj = {
                    check: true, required: false, name: 'rate_', label: '订单折扣（%）', align: 'right', field: 'rate_', sortable: true,
                    // format: (val) => `${val ? val : ''}`
                }
                const priceObj = {
                    check: true, required: false, name: 'maprice', label: '折后单价', align: 'right', field: 'maprice', sortable: true,
                    format: (val) => `${val ? val : ''}`
                }
                const mountObj = {
                    check: true, required: false, name: 'maamount', label: '折后总价', align: 'right', field: 'maamount', sortable: true,
                    format: (val) => `${val ? val : ''}`
                }
                //columns 动态展示的字段
                state.orderpost.tableSet.columns[17] = rateObj
                state.orderpost.tableSet.columns[18] = priceObj
                state.orderpost.tableSet.columns[19] = mountObj
            }
            //特价单的动态字段
            else if ('rate_' in data.entry[0] && 'price_' in data.entry[0] && 'amount_' in data.entry[0]) {
                const rateObj = {
                    check: true, required: false, name: 'rate_', label: 'SPR折扣（%）', align: 'right', field: 'rate_', sortable: true,
                    format: (val) => `${val ? val : ''}`
                }
                const priceObj = {
                    check: true, required: false, name: 'price_', label: 'SPR折后单价', align: 'right', field: 'price_', sortable: true,
                    format: (val) => `${val ? val : ''}`
                }
                const mountObj = {
                    check: true, required: false, name: 'amount_', label: 'SPR折后金额', align: 'right', field: 'amount_', sortable: true,
                    format: (val) => `${val ? val : ''}`
                }
                //columns 动态展示的字段
                state.orderpost.tableSet.columns[17] = rateObj
                state.orderpost.tableSet.columns[18] = priceObj
                state.orderpost.tableSet.columns[19] = mountObj
            }

            else {
                state.orderpost.tableSet.columns.splice(17, 3)
            }
            state.orderpost.tableSet.data = data.entry
        }
        if (data.header) {
            state.orderpost.formSet.body[0].items[0].value = data.header.F_BillNo //采购订单
            state.orderpost.formSet.body[0].items[1].value = data.header.F_CreatorDate //单据日期
            state.orderpost.formSet.body[0].items[2].value = data.header.F_CreatorName //制单人
            state.orderpost.formSet.body[0].items[3].value = data.header.F_CreatorEmail //制单人邮箱
            state.orderpost.formSet.body[0].items[4].value = data.header.F_CreatorTel  //制单人电话
            state.orderpost.formSet.body[0].items[5].value = data.header.F_ARVDate    //期望交货日期

            state.orderpost.formSet.body[0].items[6].value = data.header.F_SPRNo   //特价单号
            state.orderpost.formSet.body[0].items[7].value = data.header.F_ENDCUSTOMER_NAME //F_CustomerName  最终客户英文名称
            state.orderpost.formSet.body[0].items[9].value = data.header.F_OSDEmail   //OSD邮箱

            state.orderpost.formSet.body[0].items[13].value = data.header.F_SALESEMPLOYEE_EMAIL   //销售邮箱
            state.orderpost.formSet.body[0].items[14].value = data.header.F_COMMENTS   //备注

            state.orderpost.formSet.body[0].items[8].value = data.header.F_ENDCUSTOMER_COUNTRY   //最终客户国家
            state.orderpost.formSet.body[0].items[10].value = data.header.F_PAYMENT_TERM   //付款方式
            state.orderpost.formSet.body[0].items[11].value = data.header.F_SHIP_2_PARTY   //收货地址
            state.orderpost.formSet.body[0].items[12].value = data.header.Notification_Email_List   //邮件通知列表
            state.orderpost.formSet.is_sended= !!data.header.is_sended;
            if (!data.header.is_sended && data.basedata.receipt_address.length == 1) {
                state.orderpost.formSet.body[0].items[11].value = data.basedata.receipt_address[0].code;
            }else{
                state.orderpost.formSet.body[0].items[11].value = data.header.F_SHIP_2_PARTY   //收货地址
            }
            if (data.header.is_sended) {
                state.orderpost.formSet.body[1].items[3].btns[0].disabled = true
                state.orderpost.formSet.body[1].items[3].btns[1].disabled = true
                for (let i = 5; i < state.orderpost.formSet.body[0].items.length; i++) {
                    state.orderpost.formSet.body[0].items[i].disable = true;
                    state.orderpost.formSet.body[0].items[i].filled = true;
                }
            } else {
                state.orderpost.formSet.body[1].items[3].btns[0].disabled = false
                state.orderpost.formSet.body[1].items[3].btns[1].disabled = false
                for (let i = 5; i < state.orderpost.formSet.body[0].items.length; i++) {
                    state.orderpost.formSet.body[0].items[i].disable = false;
                    state.orderpost.formSet.body[0].items[i].filled = false;
                }
            }
        }

    },
    bundledDetail(state, data) {
        // function test_data() {
        //     return {
        //         "bomdata": [
        //             {
        //                 "id": 32783,
        //                 "Plant": "1001",
        //                 "BOMUsg": "5",
        //                 "BOMNo": "01593484",
        //                 "BOMSt": "01",
        //                 "AltBOM": "01",
        //                 "Material": "101323549",
        //                 "Description": "6GK1561-2AM00  \\\\\\\\COMMUNICATION PROCESSOR",
        //                 "MLFB_Sort": "6GK15612AM00",
        //                 "Option": "",
        //                 "Quantity": "1.0",
        //                 "Unit": "PC",
        //                 "Status": "T2",
        //                 "Del_Flag": "X",
        //                 "Item": "0010",
        //                 "ICt": "L",
        //                 "Component": "101304888",
        //                 "Component_Description": "6GK1561-2AA00  \\\\\\\\Communication processor",
        //                 "Component_Sort": "6GK15612AA00",
        //                 "Component_Option": "",
        //                 "Conponent_Quantity": "1.0",
        //                 "Component_Unit": "PC",
        //                 "ValidFrom": "20140225",
        //                 "ValidTo": "99991231",
        //                 "Component_Status": "LF",
        //                 "Component_Del_Flag": "",
        //                 "custom_add": "",
        //                 "created_at": "2023-11-09 09:58:04",
        //                 "MLFB_rowspan": 2
        //             },
        //             {
        //                 "id": 32784,
        //                 "Plant": "1001",
        //                 "BOMUsg": "5",
        //                 "BOMNo": "01593484",
        //                 "BOMSt": "01",
        //                 "AltBOM": "01",
        //                 "Material": "101323549",
        //                 "Description": "6GK1561-2AM00  \\\\\\\\COMMUNICATION PROCESSOR",
        //                 "MLFB_Sort": "6GK15612AM00",
        //                 "Option": "",
        //                 "Quantity": "1.0",
        //                 "Unit": "PC",
        //                 "Status": "T2",
        //                 "Del_Flag": "X",
        //                 "Item": "0020",
        //                 "ICt": "L",
        //                 "Component": "100016081",
        //                 "Component_Description": "6ES7901-0BF00-0AA0\\\\\\\\S7 MPI Cable, 5m",
        //                 "Component_Sort": "6ES79010BF000AA0",
        //                 "Component_Option": "",
        //                 "Conponent_Quantity": "1.0",
        //                 "Component_Unit": "PC",
        //                 "ValidFrom": "20140225",
        //                 "ValidTo": "99991231",
        //                 "Component_Status": "LF",
        //                 "Component_Del_Flag": "",
        //                 "custom_add": "",
        //                 "created_at": "2023-11-09 09:58:04",
        //                 "MLFB_rowspan": 0
        //             },
        //             {
        //                 "id": 32910,
        //                 "Plant": "1001",
        //                 "BOMUsg": "5",
        //                 "BOMNo": "01638017",
        //                 "BOMSt": "01",
        //                 "AltBOM": "01",
        //                 "Material": "101155425",
        //                 "Description": "6AV2133-8AF00-0AA0 \\\\\\\\TRAINER PACKAGE HMI,",
        //                 "MLFB_Sort": "6AV21338AF000AA0",
        //                 "Option": "",
        //                 "Quantity": "1.0",
        //                 "Unit": "PC",
        //                 "Status": "T2",
        //                 "Del_Flag": "X",
        //                 "Item": "0010",
        //                 "ICt": "L",
        //                 "Component": "100687932",
        //                 "Component_Description": "6AV6642-0BA01-1AX1\\\\\\\\TP177B 6\" PN/DP Color",
        //                 "Component_Sort": "6AV66420BA011AX1",
        //                 "Component_Option": "",
        //                 "Conponent_Quantity": "1.0",
        //                 "Component_Unit": "PC",
        //                 "ValidFrom": "20140501",
        //                 "ValidTo": "99991231",
        //                 "Component_Status": "T1",
        //                 "Component_Del_Flag": "",
        //                 "custom_add": "",
        //                 "created_at": "2023-11-09 09:58:04",
        //                 "MLFB_rowspan": 5
        //             },
        //             {
        //                 "id": 32911,
        //                 "Plant": "1001",
        //                 "BOMUsg": "5",
        //                 "BOMNo": "01638017",
        //                 "BOMSt": "01",
        //                 "AltBOM": "01",
        //                 "Material": "101155425",
        //                 "Description": "6AV2133-8AF00-0AA0 \\\\\\\\TRAINER PACKAGE HMI,",
        //                 "MLFB_Sort": "6AV21338AF000AA0",
        //                 "Option": "",
        //                 "Quantity": "1.0",
        //                 "Unit": "PC",
        //                 "Status": "T2",
        //                 "Del_Flag": "X",
        //                 "Item": "0020",
        //                 "ICt": "L",
        //                 "Component": "101535222",
        //                 "Component_Description": "S79220-B5178-F888 \\\\\\\\TRAINER PACK HMI, WIN",
        //                 "Component_Sort": "S79220B5178F888",
        //                 "Component_Option": "",
        //                 "Conponent_Quantity": "1.0",
        //                 "Component_Unit": "PC",
        //                 "ValidFrom": "20140501",
        //                 "ValidTo": "99991231",
        //                 "Component_Status": "T2",
        //                 "Component_Del_Flag": "X",
        //                 "custom_add": "",
        //                 "created_at": "2023-11-09 09:58:04",
        //                 "MLFB_rowspan": 0
        //             },
        //             {
        //                 "id": 32912,
        //                 "Plant": "1001",
        //                 "BOMUsg": "5",
        //                 "BOMNo": "01638017",
        //                 "BOMSt": "01",
        //                 "AltBOM": "01",
        //                 "Material": "101155425",
        //                 "Description": "6AV2133-8AF00-0AA0 \\\\\\\\TRAINER PACKAGE HMI,",
        //                 "MLFB_Sort": "6AV21338AF000AA0",
        //                 "Option": "",
        //                 "Quantity": "1.0",
        //                 "Unit": "PC",
        //                 "Status": "T2",
        //                 "Del_Flag": "X",
        //                 "Item": "0030",
        //                 "ICt": "L",
        //                 "Component": "100220662",
        //                 "Component_Description": "6AV6691-1SA01-0AX0 \\\\\\\\SIMATIC HMI MANUAL C",
        //                 "Component_Sort": "6AV66911SA010AX0",
        //                 "Component_Option": "",
        //                 "Conponent_Quantity": "1.0",
        //                 "Component_Unit": "PC",
        //                 "ValidFrom": "20140501",
        //                 "ValidTo": "99991231",
        //                 "Component_Status": "T2",
        //                 "Component_Del_Flag": "X",
        //                 "custom_add": "",
        //                 "created_at": "2023-11-09 09:58:04",
        //                 "MLFB_rowspan": 0
        //             },
        //             {
        //                 "id": 32913,
        //                 "Plant": "1001",
        //                 "BOMUsg": "5",
        //                 "BOMNo": "01638017",
        //                 "BOMSt": "01",
        //                 "AltBOM": "01",
        //                 "Material": "101155425",
        //                 "Description": "6AV2133-8AF00-0AA0 \\\\\\\\TRAINER PACKAGE HMI,",
        //                 "MLFB_Sort": "6AV21338AF000AA0",
        //                 "Option": "",
        //                 "Quantity": "1.0",
        //                 "Unit": "PC",
        //                 "Status": "T2",
        //                 "Del_Flag": "X",
        //                 "Item": "0040",
        //                 "ICt": "L",
        //                 "Component": "100016081",
        //                 "Component_Description": "6ES7901-0BF00-0AA0\\\\\\\\S7 MPI Cable, 5m",
        //                 "Component_Sort": "6ES79010BF000AA0",
        //                 "Component_Option": "",
        //                 "Conponent_Quantity": "1.0",
        //                 "Component_Unit": "PC",
        //                 "ValidFrom": "20140501",
        //                 "ValidTo": "99991231",
        //                 "Component_Status": "LF",
        //                 "Component_Del_Flag": "",
        //                 "custom_add": "",
        //                 "created_at": "2023-11-09 09:58:04",
        //                 "MLFB_rowspan": 0
        //             },
        //             {
        //                 "id": 32914,
        //                 "Plant": "1001",
        //                 "BOMUsg": "5",
        //                 "BOMNo": "01638017",
        //                 "BOMSt": "01",
        //                 "AltBOM": "01",
        //                 "Material": "101155425",
        //                 "Description": "6AV2133-8AF00-0AA0 \\\\\\\\TRAINER PACKAGE HMI,",
        //                 "MLFB_Sort": "6AV21338AF000AA0",
        //                 "Option": "",
        //                 "Quantity": "1.0",
        //                 "Unit": "PC",
        //                 "Status": "T2",
        //                 "Del_Flag": "X",
        //                 "Item": "0050",
        //                 "ICt": "L",
        //                 "Component": "100562839",
        //                 "Component_Description": "6XV1870-3RH60  \\\\\\\\TP XP Cord RJ45/RJ45 6 m",
        //                 "Component_Sort": "6XV18703RH60",
        //                 "Component_Option": "",
        //                 "Conponent_Quantity": "1.0",
        //                 "Component_Unit": "PC",
        //                 "ValidFrom": "20140501",
        //                 "ValidTo": "99991231",
        //                 "Component_Status": "LF",
        //                 "Component_Del_Flag": "",
        //                 "custom_add": "",
        //                 "created_at": "2023-11-09 09:58:04",
        //                 "MLFB_rowspan": 0
        //             },
        //             {
        //                 "id": 33243,
        //                 "Plant": "1001",
        //                 "BOMUsg": "5",
        //                 "BOMNo": "01613393",
        //                 "BOMSt": "01",
        //                 "AltBOM": "01",
        //                 "Material": "100259974",
        //                 "Description": "6GK1561-1AM01  \\\\\\\\COMMUNICATION PROCESSOR",
        //                 "MLFB_Sort": "6GK15611AM01",
        //                 "Option": "",
        //                 "Quantity": "1.0",
        //                 "Unit": "PC",
        //                 "Status": "T2",
        //                 "Del_Flag": "X",
        //                 "Item": "0010",
        //                 "ICt": "L",
        //                 "Component": "100260159",
        //                 "Component_Description": "6GK1561-1AA01  \\\\\\\\Communication processor",
        //                 "Component_Sort": "6GK15611AA01",
        //                 "Component_Option": "",
        //                 "Conponent_Quantity": "1.0",
        //                 "Component_Unit": "PC",
        //                 "ValidFrom": "20140416",
        //                 "ValidTo": "99991231",
        //                 "Component_Status": "T1",
        //                 "Component_Del_Flag": "",
        //                 "custom_add": "",
        //                 "created_at": "2023-11-09 09:58:04",
        //                 "MLFB_rowspan": 2
        //             },
        //             {
        //                 "id": 33244,
        //                 "Plant": "1001",
        //                 "BOMUsg": "5",
        //                 "BOMNo": "01613393",
        //                 "BOMSt": "01",
        //                 "AltBOM": "01",
        //                 "Material": "100259974",
        //                 "Description": "6GK1561-1AM01  \\\\\\\\COMMUNICATION PROCESSOR",
        //                 "MLFB_Sort": "6GK15611AM01",
        //                 "Option": "",
        //                 "Quantity": "1.0",
        //                 "Unit": "PC",
        //                 "Status": "T2",
        //                 "Del_Flag": "X",
        //                 "Item": "0020",
        //                 "ICt": "L",
        //                 "Component": "100016081",
        //                 "Component_Description": "6ES7901-0BF00-0AA0\\\\\\\\S7 MPI Cable, 5m",
        //                 "Component_Sort": "6ES79010BF000AA0",
        //                 "Component_Option": "",
        //                 "Conponent_Quantity": "1.0",
        //                 "Component_Unit": "PC",
        //                 "ValidFrom": "20140416",
        //                 "ValidTo": "99991231",
        //                 "Component_Status": "LF",
        //                 "Component_Del_Flag": "",
        //                 "custom_add": "",
        //                 "created_at": "2023-11-09 09:58:04",
        //                 "MLFB_rowspan": 0
        //             },
        //             {
        //                 "id": 33245,
        //                 "Plant": "1001",
        //                 "BOMUsg": "5",
        //                 "BOMNo": "01590607",
        //                 "BOMSt": "01",
        //                 "AltBOM": "01",
        //                 "Material": "100732166",
        //                 "Description": "6GK1562-1AM00  \\\\\\\\COMMUNICATION PROCESSOR",
        //                 "MLFB_Sort": "6GK15621AM00",
        //                 "Option": "",
        //                 "Quantity": "1.0",
        //                 "Unit": "PC",
        //                 "Status": "T2",
        //                 "Del_Flag": "X",
        //                 "Item": "0020",
        //                 "ICt": "L",
        //                 "Component": "100016081",
        //                 "Component_Description": "6ES7901-0BF00-0AA0\\\\\\\\S7 MPI Cable, 5m",
        //                 "Component_Sort": "6ES79010BF000AA0",
        //                 "Component_Option": "",
        //                 "Conponent_Quantity": "1.0",
        //                 "Component_Unit": "PC",
        //                 "ValidFrom": "20140220",
        //                 "ValidTo": "99991231",
        //                 "Component_Status": "LF",
        //                 "Component_Del_Flag": "",
        //                 "custom_add": "",
        //                 "created_at": "2023-11-09 09:58:04",
        //                 "MLFB_rowspan": 2
        //             },
        //             {
        //                 "id": 33246,
        //                 "Plant": "1001",
        //                 "BOMUsg": "5",
        //                 "BOMNo": "01590607",
        //                 "BOMSt": "01",
        //                 "AltBOM": "01",
        //                 "Material": "100732166",
        //                 "Description": "6GK1562-1AM00  \\\\\\\\COMMUNICATION PROCESSOR",
        //                 "MLFB_Sort": "6GK15621AM00",
        //                 "Option": "",
        //                 "Quantity": "1.0",
        //                 "Unit": "PC",
        //                 "Status": "T2",
        //                 "Del_Flag": "X",
        //                 "Item": "0010",
        //                 "ICt": "L",
        //                 "Component": "100673078",
        //                 "Component_Description": "6GK1562-1AA00  \\\\\\\\COMMUNICATION PROCESSOR",
        //                 "Component_Sort": "6GK15621AA00",
        //                 "Component_Option": "",
        //                 "Conponent_Quantity": "1.0",
        //                 "Component_Unit": "PAC",
        //                 "ValidFrom": "20140220",
        //                 "ValidTo": "99991231",
        //                 "Component_Status": "T1",
        //                 "Component_Del_Flag": "",
        //                 "custom_add": "",
        //                 "created_at": "2023-11-09 09:58:04",
        //                 "MLFB_rowspan": 0
        //             }
        //         ],
        //         "header": {
        //             "RowNum": 1,
        //             "F_BillNo": "POORD000044",
        //             "F_SPRNo": "",
        //             "F_LineNo": 1,
        //             "F_CreatorDate": "2023-09-14 00:00-00",
        //             "F_CreatorName": "王雅兴",
        //             "F_CreatorEmail": null,
        //             "F_CreatorTel": null,
        //             "FEntryID": 1,
        //             "F_ERPMaterialCode": "SIE.FA.BUNDLE.6ES79010BF000AA0",
        //             "F_ERPMaterialName": "6GK15611AM01 - 6ES79010BF000AA0",
        //             "F_MaterialNo": "6ES79010BF000AA0",
        //             "F_ProductOption": "",
        //             "F_ERPUnit": "PCS",
        //             "QUANTITY": 3,
        //             "L2_PRICE": 0,
        //             "FAuxPrice": 0,
        //             "FCess": 13,
        //             "FTaxPrice": 0,
        //             "FAuxTaxPrice": 0,
        //             "DISCOUNT": 0,
        //             "FDescount": 0,
        //             "FPriceDiscount": 0,
        //             "FAuxPriceDiscount": 0,
        //             "F_Amount": 0,
        //             "FTaxAmount": 0,
        //             "FAllAmount": 0,
        //             "F_ARVDate": "2023-09-14 00:00-00",
        //             "F_DN_DUE_DATE": "2023-09-14 00:00-00"
        //         },
        //         "entry": [
        //             {
        //                 "RowNum": 1,
        //                 "F_BillNo": "POORD000044",
        //                 "F_SPRNo": "",
        //                 "F_LineNo": 1,
        //                 "F_CreatorDate": "2023-09-14 00:00-00",
        //                 "F_CreatorName": "王雅兴",
        //                 "F_CreatorEmail": null,
        //                 "F_CreatorTel": null,
        //                 "FEntryID": 1,
        //                 "F_ERPMaterialCode": "SIE.FA.BUNDLE.6ES79010BF000AA0",
        //                 "F_ERPMaterialName": "6GK15611AM01 - 6ES79010BF000AA0",
        //                 "F_MaterialNo": "6ES79010BF000AA0",
        //                 "F_ProductOption": "",
        //                 "F_ERPUnit": "PCS",
        //                 "QUANTITY": 3,
        //                 "L2_PRICE": 0,
        //                 "FAuxPrice": 0,
        //                 "FCess": 13,
        //                 "FTaxPrice": 0,
        //                 "FAuxTaxPrice": 0,
        //                 "DISCOUNT": 0,
        //                 "FDescount": 0,
        //                 "FPriceDiscount": 0,
        //                 "FAuxPriceDiscount": 0,
        //                 "F_Amount": 0,
        //                 "FTaxAmount": 0,
        //                 "FAllAmount": 0,
        //                 "F_ARVDate": "2023-09-14 00:00-00",
        //                 "F_DN_DUE_DATE": "2023-09-14 00:00-00",
        //                 "ProductListPrice": "396.63",
        //                 "ZDIS_Rate": "-43.0",
        //                 "Material_status": "LF",
        //                 "Substitute": "",
        //                 "Substitute2": "",
        //                 "left": 3,
        //                 "MLFB_Sort": "6ES79010BF000AA0",
        //                 "isBom": true
        //             },
        //             {
        //                 "RowNum": 2,
        //                 "F_BillNo": "POORD000044",
        //                 "F_SPRNo": "",
        //                 "F_LineNo": 2,
        //                 "F_CreatorDate": "2023-09-14 00:00-00",
        //                 "F_CreatorName": "王雅兴",
        //                 "F_CreatorEmail": null,
        //                 "F_CreatorTel": null,
        //                 "FEntryID": 2,
        //                 "F_ERPMaterialCode": "SIE.FA.BUNDLE.6GK15611AA00",
        //                 "F_ERPMaterialName": "6GK15611AM01 - 6GK15611AA00",
        //                 "F_MaterialNo": "6GK15612AA00",
        //                 "F_ProductOption": "",
        //                 "F_ERPUnit": "PCS",
        //                 "QUANTITY": 3,
        //                 "L2_PRICE": 0,
        //                 "FAuxPrice": 0,
        //                 "FCess": 13,
        //                 "FTaxPrice": 0,
        //                 "FAuxTaxPrice": 0,
        //                 "DISCOUNT": 0,
        //                 "FDescount": 0,
        //                 "FPriceDiscount": 0,
        //                 "FAuxPriceDiscount": 0,
        //                 "F_Amount": 0,
        //                 "FTaxAmount": 0,
        //                 "FAllAmount": 0,
        //                 "F_ARVDate": "2023-09-22 00:00-00",
        //                 "F_DN_DUE_DATE": "2023-09-22 00:00-00",
        //                 "ProductListPrice": "8280.75",
        //                 "ZDIS_Rate": "-57.25",
        //                 "Material_status": "LF",
        //                 "Substitute": "",
        //                 "Substitute2": "",
        //                 "left": 3,
        //                 "MLFB_Sort": "6GK15612AA00",
        //                 "isBom": true
        //             },
        //             {
        //                 "RowNum": 3,
        //                 "F_BillNo": "POORD000044",
        //                 "F_SPRNo": "",
        //                 "F_LineNo": 3,
        //                 "F_CreatorDate": "2023-09-14 00:00-00",
        //                 "F_CreatorName": "王雅兴",
        //                 "F_CreatorEmail": null,
        //                 "F_CreatorTel": null,
        //                 "FEntryID": 3,
        //                 "F_ERPMaterialCode": "SIE.FA.SPR.6AV21240QC020AX1",
        //                 "F_ERPMaterialName": "6AV21240QC020AX1",
        //                 "F_MaterialNo": "6AV21240QC020AX1",
        //                 "F_ProductOption": "",
        //                 "F_ERPUnit": "PCS",
        //                 "QUANTITY": 5,
        //                 "L2_PRICE": 0,
        //                 "FAuxPrice": 0,
        //                 "FCess": 13,
        //                 "FTaxPrice": 0,
        //                 "FAuxTaxPrice": 0,
        //                 "DISCOUNT": 0,
        //                 "FDescount": 0,
        //                 "FPriceDiscount": 0,
        //                 "FAuxPriceDiscount": 0,
        //                 "F_Amount": 0,
        //                 "FTaxAmount": 0,
        //                 "FAllAmount": 0,
        //                 "F_ARVDate": "2023-09-14 00:00-00",
        //                 "F_DN_DUE_DATE": "2023-09-14 00:00-00",
        //                 "ProductListPrice": "44215.16",
        //                 "ZDIS_Rate": "-60.52",
        //                 "Material_status": "LF",
        //                 "Substitute": "",
        //                 "Substitute2": "",
        //                 "left": 5,
        //                 "MLFB_Sort": "6AV21240QC020AX1",
        //                 "isBom": false
        //             }
        //         ],
        //         "other": {},
        //         "credit": 78052000,
        //         "errors": [],
        //         "warning": []
        //     }
        // }

        // //TODO:测试数据
        // if (localStorage.getItem('order_send_test') === 'open') {
        //     data = test_data()
        // }
        if (data.other) {
            state.orderpost.bundleForm.body[1].items[0].value = data.other.o_c,
                state.orderpost.bundleForm.body[1].items[1].value = data.other.o_m
        }
        if (data.credit) {
            state.orderpost.bundleForm.body[1].items[2].value = data.credit
        }
        if (data.header) {
            state.orderpost.bundleForm.body[0].items[0].value = data.header.F_BillNo //采购订单
            state.orderpost.bundleForm.body[0].items[1].value = data.header.F_CreatorDate //单据日期
            state.orderpost.bundleForm.body[0].items[2].value = data.header.F_CreatorName //制单人
            state.orderpost.bundleForm.body[0].items[3].value = data.header.F_CreatorEmail //制单人邮箱
            state.orderpost.bundleForm.body[0].items[4].value = data.header.F_CreatorTel  //制单人电话
            state.orderpost.bundleForm.body[0].items[5].value = data.header.F_ARVDate    //期望交货日期

            state.orderpost.bundleForm.body[0].items[6].value = data.header.F_ENDCUSTOMER_NAME //F_CustomerName  最终客户英文名称
            state.orderpost.bundleForm.body[0].items[8].value = data.header.F_OSDEmail   //OSD邮箱
            state.orderpost.bundleForm.body[0].items[12].value = data.header.F_COMMENTS   //备注

            state.orderpost.bundleForm.body[0].items[7].value = data.header.F_ENDCUSTOMER_COUNTRY   //最终客户国家
            state.orderpost.bundleForm.body[0].items[9].value = data.header.F_PAYMENT_TERM   //付款方式
            state.orderpost.bundleForm.body[0].items[10].value = data.header.F_SHIP_2_PARTY   //收货地址
            state.orderpost.bundleForm.body[0].items[11].value = data.header.Notification_Email_List   //收货地址

            //这行代码不要改，不要换位置
            state.orderpost.ordertableSet.is_sended = !!data.header.is_sended

            if (data.header.is_sended) {
                state.orderpost.bundleForm.body[1].items[3].btns[0].disabled = true
                state.orderpost.bundleForm.body[1].items[3].btns[1].disabled = true
                for (var i = 5; i < state.orderpost.bundleForm.body[0].items.length; i++) {
                    state.orderpost.bundleForm.body[0].items[i].disable = true;
                    state.orderpost.bundleForm.body[0].items[i].filled = true;
                }
            } else {
                state.orderpost.bundleForm.body[1].items[3].btns[0].disabled = false
                state.orderpost.bundleForm.body[1].items[3].btns[1].disabled = false
                for (var i = 5; i < state.orderpost.bundleForm.body[0].items.length; i++) {
                    state.orderpost.bundleForm.body[0].items[i].disable = false;
                    state.orderpost.bundleForm.body[0].items[i].filled = false;
                }
            }
        }
        if (data.entry && data.entry[0]) {
            //三个表的采购表
            if ('maamount' in data.entry[0] && 'maprice' in data.entry[0] && 'rate_' in data.entry[0]) {
                console.log('111有///');
                //columns 动态展示的字段
                const rateObj = {
                    check: true, required: false, name: 'rate_', label: '订单折扣（%）', align: 'right', field: 'rate_', sortable: true,
                    format: (val) => `${val ? val : ''}`
                }
                const priceObj = {
                    check: true, required: false, name: 'maprice', label: '折后单价', align: 'right', field: 'maprice', sortable: true,
                    format: (val) => `${val ? val : ''}`
                }
                const mountObj = {
                    check: true, required: false, name: 'maamount', label: '折后总价', align: 'right', field: 'maamount', sortable: true,
                    format: (val) => `${val ? val : ''}`
                }
                // state.orderpost.ordertableSet.columns.splice(16, 0, rateObj, priceObj, mountObj)
                state.orderpost.ordertableSet.columns[16] = rateObj
                state.orderpost.ordertableSet.columns[17] = priceObj
                state.orderpost.ordertableSet.columns[18] = mountObj
            }
            // else if ('rate_' in data.entry[0] && 'price_' in data.entry[0] && 'amount_' in data.entry[0]) {
            //     const rateObj = {
            //         check: true, required: false, name: 'rate_', label: 'SPR折扣（%）', align: 'right', field: 'rate_', sortable: true,
            //         format: (val) => `${val ? val : ''}`
            //     }
            //     const priceObj = {
            //         check: true, required: false, name: 'price_', label: 'SPR折后单价', align: 'right', field: 'price_', sortable: true,
            //         format: (val) => `${val ? val : ''}`
            //     }
            //     const mountObj = {
            //         check: true, required: false, name: 'amount_', label: 'SPR折后金额', align: 'right', field: 'amount_', sortable: true,
            //         format: (val) => `${val ? val : ''}`
            //     }
            //     //columns 动态展示的字段
            //     state.orderpost.ordertableSet.columns[16] = rateObj
            //     state.orderpost.ordertableSet.columns[17] = priceObj
            //     state.orderpost.ordertableSet.columns[18] = mountObj
            // }
            else {
                console.log('没有////');
                state.orderpost.ordertableSet.columns.splice(16, 3)
            }

            //left：还剩多少个没匹配
            for (let i = 0; i < data.entry.length; i++) {
                data.entry[i].left = data.entry[i].QUANTITY;
                data.entry[i].MLFB_Sort = data.entry[i].F_MaterialNo

                //是否是bom数据
                let isBom = false
                for (let j = 0; j < data.bomdata.length; j++) {
                    const bom_item = data.bomdata[j];
                    if (bom_item.Component_Sort === data.entry[i].MLFB_Sort) {
                        isBom = true
                        break
                    }
                }
                data.entry[i].isBom = isBom
            }
            state.orderpost.ordertableSet.data = data.entry

        }
        if (data.bomdata) {
            // state.orderpost.BOMtableSet.data = data.bomdata

            // 改造data
            const rows = data.bomdata
            function merageByMLFB() {
                let MLFB_rowspan = 1; // 初始 MLFB_rowspan 值
                let startIdx = 0; // 用于记录相同数据的起始索引
                // 遍历数组并设置 MLFB_rowspan 属性
                for (let i = 0; i < rows.length; i++) {
                    if (i === rows.length - 1 || rows[i].MLFB_Sort !== rows[i + 1].MLFB_Sort) {
                        for (let j = startIdx; j <= i; j++) {
                            if (j === startIdx) {
                                rows[j].MLFB_rowspan = MLFB_rowspan;
                            } else {
                                rows[j].MLFB_rowspan = 0;
                            }
                        }
                        MLFB_rowspan = 1; // 重置 MLFB_rowspan 为 1
                        startIdx = i + 1; // 更新起始索引为下一个不同数据的位置
                    } else {
                        MLFB_rowspan++; // 如果 id 相等，增加 MLFB_rowspan
                        rows[i].MLFB_rowspan = 0; // 标记当前对象的 MLFB_rowspan 为 0
                    }
                }
                return rows
            }

            merageByMLFB()
            state.orderpost.BOMtableSet.data = rows

            //自定义数据
            let dic = {}
            for (let i = 0; i < data.bomdata.length; i++) {
                const e = data.bomdata[i];
                if (!dic[`${e.MLFB_Sort}`]) {
                    dic[`${e.MLFB_Sort}`] = {}
                }
                dic[`${e.MLFB_Sort}`][`${e.Component_Sort}`] = Number(e.Conponent_Quantity)
            }
            state.orderpost.BOMtableSet.dealedData = dic
        }
        if (data.bundled_entry) {
            state.orderpost.bundletableSet.data = data.bundled_entry
            // for (let i = 0; i < data.bundled_entry.length; i++) {
            //     const element = data.bundled_entry[i];
            //     element.F_MaterialNo_DDD = element.F_MaterialNo
            //     element.QUANTITY_DDD = element.QUANTITY
            //     element.F_LineNo_DDD = element.F_LineNo
            //     element.F_ARVDate_DDD = element.F_ARVDate

            //     state.orderpost.bundletableSet.data.push(element)
            // }

        }
    },
    setSelect(state) {
        state.orderpost.formSet.body[0].items[8].select_options = state.orderpost.country.map(item => {
            return { label: item.name, value: item.code }
        })
        state.orderpost.formSet.body[0].items[10].select_options = state.orderpost.payment.map(item => {
            return { label: item.name, value: item.code }
        })
        state.orderpost.formSet.body[0].items[11].select_options = state.orderpost.receipt_address.map(item => {
            return { label: item.code + '-' + item.name, value: item.code }
        })


        //bundileForm
        state.orderpost.bundleForm.body[0].items[7].select_options = state.orderpost.country.map(item => {
            return { label: item.name, value: item.code }
        })
        state.orderpost.bundleForm.body[0].items[9].select_options = state.orderpost.payment.map(item => {
            return { label: item.name, value: item.code }
        })
        state.orderpost.bundleForm.body[0].items[10].select_options = state.orderpost.receipt_address.map(item => {
            return { label: item.code + '-' + item.name, value: item.code }
        })



    },

    orderErrors(state, data) {
        state.orderpost.orderErrors = data
    },
    bomErrors(state, data) {
        state.orderpost.bomErrors = data
    },
    orderWarning(state, data) {
        state.orderpost.orderWarning = data
    },
    bomWarning(state, data) {
        state.orderpost.bomWarning = data
    },
    clearOrderErrorAndWarning(state) {
        state.orderpost.orderErrors = []
        state.orderpost.orderWarning = []
    },
    clearBomErrorAndWarning(state) {
        state.orderpost.bomErrors = []
        state.orderpost.bomWarning = []
    },
    updataOrderCredit(state, data) {
        state.orderpost.formSet.body[1].items[2].value = data
    },
    updataOrderOther(state, data) {
        state.orderpost.formSet.body[1].items[0].value = data.o_c,
            state.orderpost.formSet.body[1].items[1].value = data.o_m
    },
    updataOrderEntry(state, data) {
        state.orderpost.tableSet.data = data
    },
    updataBundleCredit(state, data) {
        state.orderpost.bundleForm.body[1].items[2].value = data
    },
    updataBundleOther(state, data) {
        state.orderpost.bundleForm.body[1].items[0].value = data.o_c,
            state.orderpost.bundleForm.body[1].items[1].value = data.o_m
    },
    updataBundleEntry(state, data) {
        state.orderpost.ordertableSet.data = data
    },
    orderChecked(state, data) {
        // let ids = state.orderpost.tableSet.selected.map(item => item.FEntryID)
        // if (ids.includes(data.FEntryID)) {
        //     state.orderpost.tableSet.selected = state.orderpost.tableSet.selected.filter(item => item.FEntryID !== data.FEntryID)
        // } else {
        //     state.orderpost.tableSet.selected.push(data)
        // }
        state.orderpost.tableSet.selected = [data]
    },
    entryChecked(state, data) {
        // let ids = state.orderpost.ordertableSet.selected.map(item => item.FEntryID)
        // if (ids.includes(data.FEntryID)) {
        //     state.orderpost.ordertableSet.selected = state.orderpost.ordertableSet.selected.filter(item => item.FEntryID !== data.FEntryID)
        // } else {
        //     state.orderpost.ordertableSet.selected.push(data)
        // }
        state.orderpost.ordertableSet.selected = [data]
    },
    bomChecked(state, data) {
        // let ids = state.orderpost.BOMtableSet.selected.map(item => item.id)
        // if (ids.includes(data.id)) {
        //     state.orderpost.BOMtableSet.selected = state.orderpost.BOMtableSet.selected.filter(item => item.id !== data.id)
        // } else {
        //     state.orderpost.BOMtableSet.selected.push(data)
        // }
        state.orderpost.BOMtableSet.selected = [data]
    }

}
const actions = {
    olDBclick({ state, dispatch }, [o_type, row]) {
        console.log(row)
        let boms = state.orderpost.BOMtableSet.data
        let ol = state.orderpost.ordertableSet.data
        let bl = state.orderpost.bundletableSet.data
        let is_sended = state.orderpost.ordertableSet.is_sended
        if (is_sended) {
            dispatch('response/warning-tip', { message: '该订单已发送，只能查看' }, { root: true })
            return
        }

        let bom_f = []
        if(o_type=='ol_click'){
            let ck = state.orderpost.bundletableSet.data.filter(it=>{
                return it.lines.indexOf(row.F_LineNo) > -1
            })

            if(ck.length < 1){
            let click_mo = row.F_MaterialNo
            bom_f = mate_bom_used_mo(click_mo, boms)
            mate(bom_f)
            }else{
                dispatch('response/warning-tip', { message: '该行已添加' }, { root: true })
            }
        }
        if (o_type == 'bl_click') {
            bom_f = [row.MLFB_Sort]
            mate(bom_f)
        }
        // Quantity Conponent_Quantity
        if (o_type == 'all') {
            let f_arr = boms.map(_ => _.MLFB_Sort)
            bom_f = [...new Set(f_arr)]
            mate(bom_f)
            //处理非bom
            let s_arr = boms.map(_=>_.Component_Sort)
            let done_ol = ol.filter(o=>{ return s_arr.indexOf(o.F_MaterialNo) < 0 })
            done_ol.forEach(it=>{
                Object.assign(it, {
                    MLFB_Sort: it.F_MaterialNo,
                    Quantity: it.QUANTITY,
                    Component_Sort: it.F_MaterialNo,
                    Conponent_Quantity: it.QUANTITY,
                    Option:it.F_ProductOption,
                    // F_MaterialNo_DDD:it.F_MaterialNo,
                    // QUANTITY_DDD:it.QUANTITY,
                    lines:[it.F_LineNo],
                    // F_LineNo_DDD: it.F_LineNo,
                    // F_ARVDate_DDD: it.F_ARVDate,
                })
            })
            change_bundled('+', done_ol)


        }
        if (o_type == 'bd_click') {
            if (row.MLFB_Sort == row.F_MaterialNo) {
                change_bundled('-', [row])
            } else {
                let done_ol = state.orderpost.bundletableSet.data.filter(it => {
                    return it.MLFB_Sort == row.MLFB_Sort
                })
                change_bundled('-', done_ol)
            }
        }
        function mate(bom_f) {
            if (bom_f.length > 0) {
                let done_ol = mate_bom_list(bom_f)
                if (done_ol.length > 0) {
                    change_bundled('+', done_ol)
                } else {
                    //匹配失败提示
                    console.log('匹配失败')
                    dispatch('response/error-tip', { message: 'BOM匹配失败' }, { root: true })
                }
            } else {
                // 如果返回空代表非bom
                let done_ol = ol.filter(it=>{
                    return it.F_MaterialNo==row.F_MaterialNo && it.F_LineNo==row.F_LineNo
                })
                done_ol.forEach(it=>{
                    Object.assign(it, {
                        MLFB_Sort: it.F_MaterialNo,
                        Quantity: row.QUANTITY,
                        Component_Sort: it.F_MaterialNo,
                        Conponent_Quantity: row.QUANTITY,
                        Option:it.F_ProductOption,
                        // F_MaterialNo_DDD:it.F_MaterialNo,
                        // QUANTITY_DDD:row.QUANTITY,
                        lines:[it.F_LineNo],
                        // F_LineNo_DDD: it.F_LineNo,
                        // F_ARVDate_DDD: it.F_ARVDate,
                    })
                })

                change_bundled('+', done_ol)

            }
        }
        function mate_bom_list(bom) {
            let boms = state.orderpost.BOMtableSet.data
            let ol = state.orderpost.ordertableSet.data
            let all_mate_ol = []
            console.log('1', bom)
            bom.forEach(b => {
                let num = 0
                let mate_ol = []
                let mate_bom = []
                let b_os = boms.filter(it => { return it.MLFB_Sort == b })
                let b_has_ma = b_os.map(_ => _.Component_Sort)
                let mate_ols = ol.filter(it => { return b_has_ma.indexOf(it.F_MaterialNo) > -1 && !it['mate'] })
                // 判断是否包含相同物料
                let mate_ols__has_mos = mate_ols.map(_ => _.F_MaterialNo)
                let mate_ols__has_mos_ck = [...new Set(mate_ols__has_mos)]
                console.log('2', mate_ols__has_mos)
                console.log('2', mate_ols__has_mos_ck)
                if (mate_ols__has_mos.length == mate_ols__has_mos_ck.length) {
                    if (mate_ols.length == b_os.length) {
                        //判断行数相同
                        let mate_nums = []
                        mate_ols.forEach(it => {
                            let b__o = b_os.filter(b_ => { return b_.Component_Sort == it.F_MaterialNo })
                            mate_nums.push(
                                b__o.length > 0 && it.QUANTITY % b__o[0].Conponent_Quantity === 0 ?
                                    it.QUANTITY / b__o[0].Conponent_Quantity :
                                    0
                            )
                        })
                        console.log('3', mate_nums)
                        let set_mate_nums = [...new Set(mate_nums)]
                        if (set_mate_nums.length == 1 && set_mate_nums[0] != 0) {
                            num = set_mate_nums[0]
                            mate_ol = mate_ols
                            mate_bom = b_os
                            mate_ols.forEach(it => {
                                it['mate'] = true
                            })
                        }
                    }
                } else {
                    for (let m = 0; m < mate_ols.length; m++) {
                        let b__o = b_os.filter(b_ => { return b_.Component_Sort == mate_ols[m].F_MaterialNo })
                        let mate_num = b__o.length > 0 && mate_ols[m].QUANTITY % b__o[0].Conponent_Quantity === 0 ? mate_ols[m].QUANTITY / b__o[0].Conponent_Quantity : 0
                        if (mate_num != 0) {
                            let mate_mas = []
                            b_os.forEach(b => {
                                let b_mate_ma = {}
                                if (b.Component_Sort != mate_ols[m].F_MaterialNo) {
                                    let m__os = mate_ols.filter(m_ => { return m_.F_MaterialNo == b.Component_Sort && !m_['m_mate'] })
                                    for (let i = 0; i < m__os.length; i++) {
                                        let mate_num_ = m__os[i].QUANTITY % b.Conponent_Quantity === 0 ? m__os[i].QUANTITY / b.Conponent_Quantity : 0
                                        if (mate_num_ == mate_num) {
                                            b_mate_ma = m__os[i]
                                            m__os[i]['m_mate'] = true
                                            break
                                        }
                                    }

                                }
                                if (Object.keys(b_mate_ma).length > 0) {
                                    mate_mas.push(b_mate_ma)
                                }

                            })
                            if (mate_mas.length == b_os.length - 1) {
                                mate_mas.push(mate_ols[m])
                                num = mate_num
                                mate_ol = mate_mas
                                mate_bom = b_os
                                lineids = mate_mas.map(_ => _.F_LineNo)
                                mate_ols.forEach(it => {
                                    if (lineids.indexOf(it.F_LineNo) > -1) {
                                        it['mate'] = true
                                    }

                                })
                                break
                            }
                        }

                    }
                }
                if (num != 0 && mate_ol.length > 0) {
                    let m_s = mate_bom[0].MLFB_Sort
                    let m_q = mate_bom[0].Quantity * num
                    let m_o = mate_bom[0].Option

                    let min_line = 100000
                    let lines = []
                    let max_expect_date = ''
                    mate_ol.forEach(it=>{
                        min_line = Math.min(min_line,it.F_LineNo)
                        lines.push(it.F_LineNo)
                        max_expect_date = it.F_ARVDate > max_expect_date ? it.F_ARVDate : max_expect_date
                    })
                    mate_ol.forEach(it=>{
                        Object.assign(it, {
                            MLFB_Sort: m_s,
                            Quantity: m_q,
                            Component_Sort: it.F_MaterialNo,
                            Conponent_Quantity: it.QUANTITY,
                            Option:m_o,
                            // F_MaterialNo_DDD:m_s,
                            // QUANTITY_DDD:m_q,
                            // F_LineNo_DDD:min_line,
                            // F_ARVDate_DDD:max_expect_date,
                            lines,
                        })
                    })
                    let mate_ol_copy = JSON.parse(JSON.stringify(mate_ol))

                    // mate_ol_copy.forEach(it => {
                    //     Object.assign(it, {
                    //         F_ERPMaterialCode: '',
                    //         F_ERPMaterialName: '',
                    //         F_ProductOption: '',
                    //         F_ERPUnit: '',
                    //         L2_PRICE: '',
                    //         DISCOUNT: '',
                    //         F_Amount: '',
                    //     })
                    // })
                    all_mate_ol = all_mate_ol.concat(mate_ol_copy)
                }


            })


            return all_mate_ol

        }
        function change_bundled(change_type, data) {
            let c_mos = data.map(_ => _.F_LineNo)
            if (change_type == '+') {

                //只添加不存在的数据 并且合并bom数据
                data = data.filter(item => {
                    item['color'] =  null
                    item['bgcolor'] = '#81c784'
                    let isExist = false
                    state.orderpost.bundletableSet.data.forEach(element => {
                        if (element && element.lines.indexOf(item.F_LineNo) > -1) {
                            isExist = true
                        }
                    });
                    return !isExist
                })

                state.orderpost.bundletableSet.data = state.orderpost.bundletableSet.data.concat(data)
                state.orderpost.ordertableSet.data.forEach(it => {
                    if (c_mos.indexOf(it.F_LineNo) > -1) {
                        it['color'] = null
                        it['bgcolor'] = '#81c784'
                    }
                })
            } else {
                state.orderpost.bundletableSet.data = state.orderpost.bundletableSet.data.filter(it => {
                    return data.indexOf(it) < 0
                })
                state.orderpost.ordertableSet.data.forEach(it=>{
                    let is_change = false
                    data.forEach(item => {
                        if(item.lines.indexOf(it.F_LineNo) > -1) {
                            is_change = true
                        }
                    })
                    if(is_change){
                        it['color'] =  null
                        it['bgcolor'] = null
                        it['mate'] = false
                    }
                })
            }

            //生成订单行号 从1开始自增
            state.orderpost.bundletableSet.data.forEach((item,index,array) => {
                if (index>0) {
                    const last = array[index-1]
                    if (item.MLFB_Sort === last.MLFB_Sort) {
                        item.O_LineNo = Number(last.O_LineNo)
                    } else {
                        item.O_LineNo = Number(last.O_LineNo) + 1
                    }
                } else {
                    item.O_LineNo = 1
                }
            })

            //bundle表格合并
            form_merge('MLFB_Sort')
            form_merge('Quantity')
            form_merge('O_LineNo')

            //给bom设置颜色
            state.orderpost.BOMtableSet.data.forEach(item => {
                let is_march = false
                for (let i = 0; i < state.orderpost.bundletableSet.data.length; i++) {
                    const element = state.orderpost.bundletableSet.data[i];
                    if (element.MLFB_Sort === item.MLFB_Sort) {
                        is_march = true
                        break
                    }
                }
                if (is_march) {
                    item['color'] =  null
                    item['bgcolor'] = '#81c784'
                } else {
                    item['color'] =  null
                    item['bgcolor'] = null
                }
            })
        }
        // 通过子物料订货号返回bom 主
        function mate_bom_used_mo(mo, boms) {
            let bos = boms.filter(it => {
                return it.Component_Sort == mo
            })
            if (bos.length > 0) {
                let f_arr = bos.map(_ => _.MLFB_Sort)
                return [...new Set(f_arr)]
            } else {
                return []
            }

        }
        function form_merge(str) {
            const rows = state.orderpost.bundletableSet.data
            let MLFB_rowspan = 1; // 初始 MLFB_rowspan 值
            let startIdx = 0; // 用于记录相同数据的起始索引
            // 遍历数组并设置 MLFB_rowspan 属性
            for (let i = 0; i < rows.length; i++) {
                if (i === rows.length - 1 || rows[i][`${str}`] !== rows[i + 1][`${str}`]) {
                    for (let j = startIdx; j <= i; j++) {
                        if (j === startIdx) {
                            rows[j].MLFB_rowspan = MLFB_rowspan;
                        } else {
                            rows[j].MLFB_rowspan = 0;
                        }
                    }
                    MLFB_rowspan = 1; // 重置 MLFB_rowspan 为 1
                    startIdx = i + 1; // 更新起始索引为下一个不同数据的位置
                } else {
                    MLFB_rowspan++; // 如果 id 相等，增加 MLFB_rowspan
                    rows[i].MLFB_rowspan = 0; // 标记当前对象的 MLFB_rowspan 为 0
                }
            }
            return rows
        }

    },
    //设置数据校验，发送订单按钮状态，未发送的单据在加载时按钮禁用
    async ['setBtnState']({ state, dispatch, rootState }, payload) {
        if(!state.orderpost.formSet.is_sended){
            state.orderpost.formSet.body[1].items[3].btns[0].disabled = payload
            state.orderpost.formSet.body[1].items[3].btns[1].disabled = payload
        }
    },

    //用订单编号获取详情
    async ['getNormalDetail']({ state, dispatch, rootState }, payload) {
        let msg = { command: "purchase_post__detail", message: payload, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        state.orderpost.formSet.loading = true
        state.orderpost.tableSet.loading = true
    },
    async ['getBundleDetail']({ state, dispatch, rootState }, payload) {
        let msg = { command: "purchase_post__detail", message: payload, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        state.orderpost.bundleForm.loading = true
        state.orderpost.BOMtableSet.loading = true
        state.orderpost.ordertableSet.loading = true
        state.orderpost.bundletableSet.loading = true
    },
    async ['return_purchase_post__detail']({ state, commit }, payload) {
        if (payload.success && !payload.data.bomdata) {
            commit("orderDetail", payload.data)
            state.orderpost.formSet.loading = false
            state.orderpost.tableSet.loading = false
            state.orderpost.isNeedInitOrderTabData = false
        }
        if (payload.success && payload.data.bomdata) {
            commit("bundledDetail", payload.data)
            state.orderpost.bundleForm.loading = false
            state.orderpost.BOMtableSet.loading = false
            state.orderpost.ordertableSet.loading = false
            state.orderpost.bundletableSet.loading = false
            state.orderpost.isNeedInitBomTabData = false
        }

    },

    // 发送订单
    // message: {'order_type': 'default/bundled', 'header': {}, 'bundled_entry': {if order_type =bundled}}
    async ['send_order']({ state, dispatch, rootState, rootDispatch, commit }, payload) {
        if (payload.confirm) {
            //清掉error提示信息
            //有警告时,继续提交 message: {'order_type': 'default/bundled', 'header': {}, 'bundled_entry': {if order_type =bundled}, 'confirm': false}
            state.tabMode = payload.tabStatus
            state.confirm = payload.confirm
            const tab = payload.tabStatus
            let data = {}
            if (tab == 'order') {
                commit("clearOrderErrorAndWarning")
                if (state.orderpost.formSet.loading == true) return
                // rootDispatch('response/error-tip',{message:'请勿重复操作'})
                state.orderpost.formSet.loading = true
                data = { order_type: 'default', header: state.header, confirm: true }
            } else {
                commit("clearBomErrorAndWarning")
                if (state.orderpost.bundleForm.loading == true) return
                state.orderpost.bundleForm.loading = true
                const list = state.orderpost.bundletableSet.data.map(({
                    MLFB_rowspan, Quantity_rowspan,
                    // F_MaterialNo_DDD, QUANTITY_DDD, F_LineNo_DDD, F_ARVDate_DDD,
                    // MLFB_Sort, Quantity, Component_Sort, Conponent_Quantity,
                     ...rest }) => {
                    return {
                        ...rest,
                        // F_MaterialNo: F_MaterialNo_DDD,
                        // QUANTITY: QUANTITY_DDD,
                        // F_LineNo: F_LineNo_DDD,
                        // F_ARVDate: F_ARVDate_DDD,
                    }
                })
                data = { order_type: 'bundled', header: state.header, bundled_entry: list, confirm: true }
            }
            let msg = { command: "purchase__send", message: data, to: "client" }
            dispatch('channel/socket_send', msg, { root: true })


        } else {
            //首次订单发送
            state.tabMode = payload.tabStatus
            const tab = payload.tabStatus
            let params = {}
            payload.params.forEach(item => {
                params = { ...params, [item.name]: item.value }
            })
            state.header = params
            let data = {}
            if (tab == 'order') {
                if (state.orderpost.formSet.loading == true) return
                state.orderpost.formSet.loading = true
                //普通订单
                data = { order_type: 'default', header: params }
            } else {
                //bundle订单
                // data={order_type:'bundled',header:params,bundled_entry:[]}
                if (state.orderpost.bundleForm.loading == true) return
                state.orderpost.bundleForm.loading = true
                const list = state.orderpost.bundletableSet.data.map(({
                    MLFB_rowspan, Quantity_rowspan,
                    //  F_MaterialNo_DDD, QUANTITY_DDD, F_LineNo_DDD, F_ARVDate_DDD,
                    //  MLFB_Sort, Quantity, Component_Sort, Conponent_Quantity,
                      ...rest }) => {
                    return {
                        ...rest,
                        // F_MaterialNo: F_MaterialNo_DDD,
                        // QUANTITY: QUANTITY_DDD,
                        // F_LineNo: F_LineNo_DDD,
                        // F_ARVDate: F_ARVDate_DDD,
                    }
                })
                data = { order_type: 'bundled', header: params, bundled_entry: list }
            }
            data.header['is_sended'] = true;
            let msg = { command: "purchase__send", message: data, to: "client" }
            dispatch('channel/socket_send', msg, { root: true })
        }
    },
    setOrderErrors({ state, dispatch, commit }, payload){
        commit('orderErrors', payload)
    },
    async ['return_purchase__send']({ state, dispatch, commit }, payload) {
        state.orderpost.formSet.loading = false
        state.orderpost.bundleForm.loading = false
        if (payload.success) {
            if (state.tabMode == 'order') {
                //首次发送订单的返回
                if (payload.data.entry) {
                    if (payload.data.errors && payload.data.errors.length) {
                        commit('orderErrors', payload.data.errors)

                    } else if (payload.data.warning && payload.data.warning.length) {
                        commit('orderWarning', payload.data.warning)
                    }
                    if (payload.data.credit) {
                        commit('updataOrderCredit', payload.data.credit)
                    }
                    if (payload.data.other) {
                        commit('updataOrderOther', payload.data.other)
                    }
                    if (payload.data.entry) {
                        commit('updataOrderEntry', payload.data.entry)
                    }
                }
                else {
                    //处理完警告和错误发送的返回
                    if(payload.data.indexOf('审核') != -1){
                        dispatch('response/warning-tip', { message: payload.data }, { root: true })
                    }else{
                        dispatch('response/success-tip', { message: payload.data }, { root: true })
                    }
                    state.orderpost.formSet.body[1].items[3].btns[0].disabled = true
                    state.orderpost.formSet.body[1].items[3].btns[1].disabled = true
                    for (var i = 5; i < state.orderpost.formSet.body[0].items.length; i++) {
                        state.orderpost.formSet.body[0].items[i].disable = true;
                        state.orderpost.formSet.body[0].items[i].filled = true;
                    }
                }
            } else {
                if (payload.data.entry) {
                    //首次发送订单的返回
                    if (payload.data.errors && payload.data.errors.length) {
                        commit('bomErrors', payload.data.errors)
                    } else if (payload.data.warning && payload.data.warning.length) {
                        commit('bomWarning', payload.data.warning)
                    }
                    if (payload.data.credit) {
                        commit('updataBundleCredit', payload.data.credit)
                    }
                    if (payload.data.other) {
                        commit('updataBundleOther', payload.data.other)
                    }
                    if (payload.data.entry) {
                        commit('updataBundleEntry', payload.data.entry)
                    }
                }
                else {
                    //处理完警告和错误发送的返回
                    dispatch('response/success-tip', { message: payload.data }, { root: true })

                    commit('update_is_send', true)
                }
            }
        }
    },

    //数据校验按钮
    async ['sendSprNo']({ state, dispatch, rootState, rootDispatch, commit }, payload) {
        state.orderpost.formSet.loading = true
        //有警告时,继续提交 message: {'order_type': 'default/bundled', 'header': {}, 'bundled_entry': {if order_type =bundled}, 'confirm': false}
        let obj = {}
        state.orderpost.formSet.body[0].items.forEach((item) => {
            obj = { ...obj, [item.name]: item.value }
        });

        const data = { order_type: 'default', header: obj, bundled_entry: {} }
        let msg = { command: "purchase__check", message: data, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
    },
    async ['return_purchase__check']({ state, dispatch, commit }, payload) {
        if (payload.success) {
            if (payload.data.errors && payload.data.errors.length) {
                // dispatch('response/error-tip', { message: payload.data.errors[0] }, { root: true })
                commit('orderErrors', payload.data.errors)
            } else if (payload.data.warning && payload.data.warning.length) {
                commit('orderWarning', payload.data.warning)
            }
            // if (payload.data.credit) {
            //     commit('updataCredit', payload.data.credit)
            // }
            // if (payload.data.other) {
            //     commit('updataOther', payload.data.other)
            // }
            // if (payload.data.entry) {
            //     commit('updataEntry', payload.data.entry)
            // }
        }
        state.orderpost.formSet.loading = false
    },

}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
