import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
import routes from './routers'


//路由模式
const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

//登录验证
router.beforeEach(function (to, from, next) {
    const is_login_normal = store.getters["user/isLogin"]
    // 判断当前url是否要求验证
    if (to.matched.some(r => r.meta.requiresAuth)) {
        // 判断用户是否登陆
        if (!is_login_normal) {
            localStorage.setItem("preRoute", JSON.stringify({name:to.name, path:to.path}))
            localStorage.removeItem('authenticated')
            localStorage.removeItem('userBase')
            // 跳转到单点登录界面
            next('/sso')
        }
        else {
            // 保留在当前url
            next()
        }
    } else {
        next()
    }
})



export default router






