import useQuasar from 'quasar/src/composables/use-quasar.js';;
const $q = useQuasar();
function get_menu(tree, group) {
    let res = {};
    for (let i = 0; i < tree.length; i++) {
        if (tree[i].to == group) {
            res = tree[i];
            break;
        }
        if (tree[i].children && tree[i].children.length > 0) {
            res = get_menu(tree[i].children, group);
            if (res && JSON.stringify(res) !== '{}') {
                return res;
            }
        }
    }
    return res;
}
function recursive(tree, group) {
    let res = {}

    for (let i = 0; i < tree.length; i++) {
        res = get_menu(tree[i], group)
        if (res.icon) {
            break;
        }
    }
    return res
}

// 删除对象中空值数据
function sourceObject(data) {
    return Object.keys(data)
        .filter((key) => data[key] !== null && data[key] !== undefined)
        .reduce((acc, key) => ({ ...acc, [key]: data[key] }), {})
}

function getUrlParam(name = '') {
    let url = window.location.href
    let dz_url = url.split('#')[0]
    if (!dz_url) { return false }
    let cs = dz_url.split('?')[1]
    if (!cs) { return false }
    let cs_arr = cs.split('&')
    let ps = {}
    cs_arr.forEach(it => {
        ps[it.split('=')[0]] = it.split('=')[1]
    })
    ps = sourceObject(ps)
    if (name) {
        return ps[name] !== undefined ? ps[name] : false
    } else {
        return ps
    }

}

function ValidSelectedData(method, length) {
    if (method == 'multiple') {
        return {
            success: length > 0,
            message: length > 0 ? '' : '请选择至少一行数据进行操作'
        }
    }
    if (method == 'single') {
        return {
            success: length == 1,
            message: length == 1 ? '' : '请选择一行数据进行操作'
        }
    }
    return {
        success: false,
        message: '比对数据错误'
    }
}

//比较新旧对象
function funDifference(newObj, oldObj) {
    let diff = {};
    let vChildren;
    for (var key in newObj) {
        if (typeof newObj[key] === "object" && typeof oldObj[key] === "object" && newObj[key] && oldObj[key]) {
            vChildren = funDifference(newObj[key], oldObj[key]);
            if (Object.keys(vChildren).length > 0) {
                diff[key] = vChildren;
            }
        } else if (newObj[key] !== oldObj[key]) {
            diff[key] = newObj[key];
        }
    }
    return diff;
}
//格式化金额,保留两位小数并添加千分位
// function formattedPrice(price) {
//     let newPrice = parseFloat(price)
//     return newPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
// }

function formattedPrice(price) {
    let newPrice = parseFloat(price);

  if (isNaN(newPrice)) {
    return "Invalid Price";
  }

  const isNegative = newPrice < 0;
  newPrice = Math.abs(newPrice);

  const formattedAmount = newPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

  return isNegative ? "-" + formattedAmount : formattedAmount;
  }

//表格的某一列需要合并时候,改造数据格式
/** 
 *   @param tableData 表格数据
 *   @param key 要合并的列
 */
function mergeTableData(tableData,key){
    // 自定义排序函数，根据 key 字段进行排序   
    function sortList(arr) {
        const sortedArray = [];
        const categories = new Set(arr.map(item => item[key]));
        categories.forEach(field => {
            const itemsInCategory = arr.filter(item => 
                 item[key] === field
            );
            sortedArray.push(...itemsInCategory);
        });

        return sortedArray;
    }
    tableData = sortList(tableData)

    //打标识
    const idCountMap = {};
    for (const obj of tableData) {
        if (!idCountMap[obj[key]]) {
            idCountMap[obj[key]] = 0;
        }
        idCountMap[obj[key]]++;
    }
    for (const obj of tableData) {
        if (idCountMap[obj[key]] > 1) {
            obj.rowspan = 'multiple'; // 多次出现的标识
        } else {
            obj.rowspan = 'single';    // 单次出现的标识
        }
    }

    //只留首次出现的对象的标识
    const list = [];
    for (const item of tableData) {
        const existingItem = list.find((el) => el[key] === item[key]);
        if (existingItem) {
            const { rowspan, ...newItem } = item;
            existingItem.rowspan = 'multiple';
            list.push(newItem);
        } else {
            list.push(item);
        }
    }
    return list
}


//深拷贝判断数据类型
function getDataType(data){
    return Object.prototype.toString.call(data).slice(8,-1)
}
//深拷贝
function cloneDeep(data){
    let container
    const type = getDataType(data)
    console.log(type,typeof type)
    if(type === 'Object'){
        container = {}
    }else if(type === 'Array'){
        container = []
    }else {
        container = data
    }
    for(let i in data){
        const type = getDataType(data[i])
        if(type === 'Object' || type === 'Array'){
            container[i] = cloneDeep(data[i])
        }else{
            container[i] = data[i]
        }
    }
    return container
}


export default {
    recursive, getUrlParam, ValidSelectedData, funDifference, formattedPrice,mergeTableData,cloneDeep
}