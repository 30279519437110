const systemsettingsData = {
    settingsData: {
        loading: false,
        data: [],
        initData: []
    }
}
const dialogData = {
    addDialog: {
        dialog_class: 'defalut',
        btns: true,
        items: [
            {
                index: 0, label: 'SECTION', placeholder: '请输入SECTION', showlabel: true, name: 'code', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: true,
                rules: [
                    val => (val && val.length > 0) || '请输入SECTION'
                ],

            },
            {
                index: 1, label: 'KEY', placeholder: '请输入KEY', showlabel: true, name: 'name', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: true,
                rules: [
                    val => (val && val.length > 0) || '请输入KEY'
                ],

            },
            {
                index: 2, label: 'VALUE', placeholder: '请输入VALUE', showlabel: true, name: 'comms', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: true,
                rules: [
                    val => (val && val.length > 0) || '请输入VALUE'
                ],

            }
        ],
    }
}
const state = () => ({
    systemsettings: Object.assign({}, systemsettingsData, dialogData)
})
const getters = {
    settingsData: state => state.systemsettings.settingsData,
    addDialog: state => state.systemsettings.addDialog,
    }
const mutations = {
        client_get_config(state, data) {
        state.systemsettings.settingsData.data = data
        state.systemsettings.settingsData.initData = JSON.parse(JSON.stringify(data))
    },
    //清空
    clearDialog(state) {
        state.systemsettings.addDialog.items = state.systemsettings.addDialog.items.map(item => {
            return { ...item, value: '' }
        })
    },
}
const actions = {
    async ['fetch-client']({ state, dispatch, rootState }, payload) {
                if (state.systemsettings.settingsData.loading == true) return
                let msg = { command: "client_get__config", message: payload, to: "client" }
                dispatch('channel/socket_send', msg, { root: true })
                state.systemsettings.settingsData.loading = true
    },
    async ['return_client_get__config']({ state, commit }, payload) {
        state.systemsettings.settingsData.loading = false
        if (payload.success) {
            commit('client_get_config', payload.data)
        }
    },

    //新增
    async ['fetch_add']({ state, dispatch, rootState }) {

                let dialogItems = state.systemsettings.addDialog.items
                let message = {}
                message[dialogItems[0].value] = {}
                message[dialogItems[0].value][dialogItems[1].value] = dialogItems[2].value
                let msg = { command: "client_write__config", message: message, to: "client" }
                dispatch('channel/socket_send', msg, { root: true })

    },
    async ['return_client_write__config']({ state, commit, dispatch }, payload) {
        if (payload.success == true) {
            dispatch('response/success-tip', { message: '操作成功' }, { root: true })
            //刷新数据
            commit('client_get_config', payload.data)
        }
    },

    //保存修改（只提交有修改的item）
    async ['fetch_save']({ state, dispatch, rootState }, payload) {
                let message = payload
                let msg = { command: "client_write__config", message: message, to: "client" }
                dispatch('channel/socket_send', msg, { root: true })
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}