let cacheSize = parseInt(localStorage.getItem('pageSize'))
import API from '@/apis'
import date from 'quasar/src/utils/date.js';
const logsData = {
    tableSet: {
        tableName:'table_logs', 
        loading: false,
        rowkey: 'id',
        selection: 'single',
        selected: [],
        pagination: { rowsPerPage: cacheSize?cacheSize:30, rowsNumber: 3, page: 1, query: {} },
        data: [],
        columns: [
            // { name: 'id', align: 'left', label: 'ID', field: 'id', sortable: true },
            { name: 'create_by', align: 'left', label: '操作者', field: 'create_by', sortable: true },
            { name: 'object', align: 'left', label: '模块/对象', field: 'object', sortable: true },
            { name: 'operation', align: 'left', label: '操作名称', field: 'operation', sortable: true },
            { name: 'result', align: 'left', label: '结果', field: 'result', sortable: true },
            { name: 'create_at', align: 'left', label: '记录时间', field: 'create_at', sortable: true, format: (val)=> date.formatDate(val, 'YYYY-MM-DD HH:mm:ss') },
            { name: 'info', align: 'left', label: '记录内容', field: 'info', sortable: true },
        ],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [

                {
                    sign: 'refresh',
                    index: 2,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },
            ]
        },
        maxHeight: ''
    },
    errorDetail: null
}
const state = () => ({
    logs: Object.assign({}, logsData)
})
const getters = {
    tableSet: state => state.logs.tableSet,
    errorDetail: state => state.logs.errorDetail
}
const mutations = {
    ['GETLIST'](state,data){
        state.logs.tableSet.pagination.rowsNumber = data.count
        state.logs.tableSet.data = data.results
    },
    ['UPDATEERRORDETAIL'](state,data){
        state.logs.errorDetail = data
    },
    ['refreshTableHeight'](state,data){
        state.logs.tableSet.maxHeight = data + 'px'
    },
    ['tableChecked'](state,data){
        // let ids = state.logs.tableSet.selected.map(item => item.id)
        // if (ids.includes(data.id)) {
        //     state.logs.tableSet.selected = state.logs.tableSet.selected.filter(item => item.id !== data.id)
        // } else {
        //     state.logs.tableSet.selected.push(data)
        // }
        state.logs.tableSet.selected = [data]
    }

}
const actions = {
    async ['onSearch']({dispatch, state}, payload){
        dispatch('getList', payload)
    },
    async ['getList']({ state, commit }, payload) {
        if (state.logs.tableSet.loading == true) return
        state.logs.tableSet.loading = true
        const page = state.logs.tableSet.pagination.page
        const size = state.logs.tableSet.pagination.rowsPerPage
        let query = '?'
        if (page) { query += `&page=${page}` }
        if (size) { query += `&page_size=${size}` }

        if (Object.prototype.toString.call(payload) === '[object Object]') {
            for(const key of Object.keys(payload)){
                let val = payload[`${key}`]
                query += `&${key}=${val ? encodeURIComponent(val) : ''}`
            }
        }
        state.logs.tableSet.loading == true

        await API.logsGetList(query).then(res => {
            if (res.status == '200') {
                state.logs.tableSet.loading = false
                commit('UPDATEERRORDETAIL',null)
                commit('GETLIST', res.data)
            }
        }).catch(error => {
            commit('UPDATEERRORDETAIL',error.data.detail)
        })
    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}