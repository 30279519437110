const erpfieldsData = {
    settingsData: {
        loading: false,
        data: null,
        initData: null
    }
}
const state = () => ({
    erpfields: Object.assign({}, erpfieldsData)
})
const getters = {
    settingsData: state => state.erpfields.settingsData,
}
const mutations = {
    client_get_json(state, data) {
        state.erpfields.settingsData.data = JSON.stringify(data, null, 4)
        state.erpfields.settingsData.initData = JSON.parse(JSON.stringify(data))
    }
}
const actions = {
    async ['fetch-client']({ state, dispatch, rootState }, payload) {

                if (state.erpfields.settingsData.loading == true) return
                let msg = { command: "client_get__json", message: payload, to: "client" }
                dispatch('channel/socket_send', msg, { root: true })
                state.erpfields.settingsData.loading = true

    },
    async ['return_client_get__json']({ state, commit }, payload) {
        state.erpfields.settingsData.loading = false
        commit('client_get_json', payload.data)
    },

    //保存
    async ['fetch_save']({ state, dispatch, rootState }, payload) {

                let message = payload
                let msg = { command: "client_write__json", message: message, to: "client" }
                dispatch('channel/socket_send', msg, { root: true })
    },
    async ['return_client_write__json']({ state, commit, dispatch }, payload) {
        if (payload.success == true) {
            dispatch('response/success-tip', { message: '操作成功' }, { root: true })
            //刷新数据
            commit('client_get_json', payload.data)
        }
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}