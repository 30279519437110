import { createStore } from 'vuex'
import response from './response'
import user from './user'
import page from './page'
import channel from './channel'
import purchaseorder from './purchase-order'
import logs from './logs'
import basedata from './base-data'
import eorder from './eorder'
import purchasepost from './purchase-post'
import orderpost from './order-post'
import purchaseReceipt from './purchase-receipt'
import base from './base'
import systemsettings from './system-settings'
import erpfields from './erp-fields'
import servicestate from './service-state'
import interfacestate from './interface-state'
import netmanager from './net-manager'
import invoiceQuery from './invoice-query'
const store = createStore({
    modules: {
        response,
        user,
        page,
        channel,
        purchaseorder,
        logs,
        basedata,
        eorder,
        purchasepost,
        orderpost,
        purchaseReceipt,
        base,
        systemsettings,
        erpfields,
        servicestate,
        interfacestate,
        netmanager,
        invoiceQuery
    }
})


export default store
