import axios from 'axios'
import qs from 'qs'
import store from '../store'
// axios 配置

const Axios = axios.create({
  baseURL: process.env.VUE_APP_APIHOST,
  timeout: process.env.VUE_APP_APITIMEOUT,
})

// POST传参序列化
Axios.interceptors.request.use((config) => {

  const is_login = store.getters["user/isLogin"]
  

  if (config.method === 'get') {
    config.data = function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" })
    }
  }

  if (is_login) {
    const token = store.getters["user/userBase"].token
    config.headers.Authorization = 'Token ' + token
  }
  return config
}, (error) => {
  return Promise.reject(error)
})
// // 返回状态判断
Axios.interceptors.response.use(response => {
  // response.setHeader("Access-Control-Expose-Headers","Content-Disposition")
  console.log(response)  
  return response
}, error => {
  console.log(error.response)  
  store.dispatch('response/set-response', error.response)
  if (error.response.status == 401) {
    localStorage.removeItem('authenticated')

    //TODO:需要跳转到登录页面并显示错误提示
    // window.location.reload()
  }
  
  return Promise.reject(error.response)
})

export default Axios

