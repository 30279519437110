/**
 * 重写console.log方法
 */

console.log = (function (oldLogFunc) {
  return function () {
    if (process.env.NODE_ENV !== "development" && localStorage.getItem('wlog') !== 'open') {
      return
    }
    oldLogFunc.apply(this, arguments);
  }
})(console.log);
