// import Socket from '../tools/websocket'
import { ws_init, ws_send, ws_close } from '../tools/socket'

function fetchUserBase() {
    return localStorage.getItem('userBase') ? JSON.parse(localStorage.getItem('userBase')) : {}
}
const channelOptions = {
    wsurl: process.env.VUE_APP_SOCKETSERVERHOST + '/ws/edi/' + fetchUserBase().company_mark + '/',
    ws: null,
    hartbeat: { command: "front_check", message: "check contect", to: "server", for: fetchUserNameForWS() },
    new_message: {},
    messages: [],
    on: true,
}
const state = () => ({
    channel: Object.assign({}, channelOptions)
})

const getters = {
    getWSurl: state => state.channel.wsurl,
    getChannel: state => state.channel.ws,
    getHartbeat: state => state.channel.hartbeat,
    getNewMessage: state => state.channel.new_message,
    getMessages: state => state.channel.messages,
    isChannelOn: state => state.channel.on,
    getStateCode: state => state.channel.code,

    // isChannelOn: state => state.channel.ws ,
}

const mutations = {
    ['set_message'](state, data) {
        state.channel.new_message = data
        state.channel.messages.push(data)
    },

    ['update_channel_options'](state) {
        state.channel.wsurl = process.env.VUE_APP_SOCKETSERVERHOST + '/ws/edi/' + fetchUserBase().company_mark + '/'
        state.channel.hartbeat = { command: "front_check", message: "check contect", to: "server", for: fetchUserNameForWS() }
    },
}

const command_mapping = {
    open_payment__list: 'basedata/return_open_payment__list',
    credit_detail__online: 'basedata/return_credit_detail__online',
    bom__list: 'basedata/return_bom__list',
    bom__add:'basedata/return_bom__add',
    bom__delete:'basedata/return_bom__delete',
    zhdl__list: 'basedata/return_zhdl__list',
    spr__list:'basedata/return_spr__list',
    stock__search: 'basedata/return_stock__search',
    purchase__list:'purchaseorder/return_purchase__list',
    purchase__entry: 'purchaseorder/return_purchase__entry',
    material__list: 'basedata/return_material__list',
    base__list: 'base/return_base__list',
    base__add: 'base/return_base__add',
    base__delete: 'base/return_base__delete',
    base__patch: 'base/retrun_base__patch',
    dn__list: 'purchaseReceipt/return_dn__list',
    packing__list: 'basedata/return_packing__list',
    sn__list:'basedata/return_sn__list',
    purchase_post__detail: 'orderpost/return_purchase_post__detail',
    order__list:'eorder/return_order__list',
    order__entry:'eorder/return_order__entry',
    order__export:'eorder/return_order__export',
    order__dn__match:'eorder/return_order__dn__match',
    dn__export:'eorder/return_dn__export',
    purchase__send: 'orderpost/return_purchase__send',
    client_get__config:'systemsettings/return_client_get__config',
    client_write__config:'systemsettings/return_client_write__config',
    client_get__json:'erpfields/return_client_get__json',
    client_write__json:'erpfields/return_client_write__json',
    client_get__service_status:'servicestate/return_client_get__service_status',
    client_get__main_state:'servicestate/return_client_get__main_state',
    running_main_program_online:'servicestate/return_running_main_program_online',
    shutdown_main_program_online:'servicestate/return_shutdown_main_program_online',
    update_edi_online:'servicestate/return_update_main_program_online',
    client_get__task_status:'interfacestate/return_client_get__task_status',
    client_run__task:'interfacestate/return_client_run__task',

    material__export:'basedata/return_material__export',
    packing__export:'basedata/return_packing__export',
    sn__export:'basedata/return_sn__export',
    spr__export:'basedata/return_spr__export',
    zhdl__export:'basedata/return_zhdl__export',
    open_payment__export:'basedata/return_open_payment__export',

    material__erp: 'basedata/return_material__erp',
    packing__wms: 'basedata/return_packing__wms',
    dn__erp: 'purchaseReceipt/return_dn__erp',
    purchase__check: 'orderpost/return_purchase__check',
    purchase__clean_send_state: 'purchaseorder/return_purchase__clean_send_state',
    login_disable:'user/received_login_disable',

    billing__list:'invoiceQuery/return_billing__list',
    billing__dn:'invoiceQuery/return_billing__dn'
}

const _charStr = 'abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789';
/**
 * 随机生成索引
 * @param min 最小值
 * @param max 最大值
 * @param i 当前获取位置
 */
function RandomIndex(min, max, i){
    let index = Math.floor(Math.random()*(max-min+1)+min),

        // numStart = _charStr.length - 10;
        numStart = "abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789".length - 10;
    //如果字符串第一位是数字，则递归重新获取
    if(i==0&&index>=numStart){
        index = RandomIndex(min, max, i);
    }
    //返回最终索引值
    return index;
}
/**
 * 随机生成字符串
 * @param len 指定生成字符串长度
 */
function getRandomString(len){
    // let min = 0, max = _charStr.length-1, _str = '';
    let min = 0, max = "abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789".length-1, _str = '';
    //判断是否指定长度，否则默认长度为15
    len = len || 15;
    //循环生成字符串
    for(var i = 0, index; i < len; i++){
        index = RandomIndex(min, max, i);
        // _str += _charStr[index];
        _str += "abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789"[index];
    }
    return _str;
}

function fetchUserNameForWS() {
    let key = localStorage.getItem('user-key')
    if(!key){
        key = getRandomString(5)
        localStorage.setItem('user-key',key)
    }
    return fetchUserBase().username + (key ? ('%' + key) : '')
}

const actions = {
    //只需要在登录后初始化一次即可
    async ['socket_init']({ state, commit, dispatch }) {
        dispatch('netmanager/setup', null, {root: true})
        commit('update_channel_options')
        ws_init()
    },
    async ['socket_send']({ state, dispatch }, payload) {
        payload['for'] = fetchUserNameForWS()
        console.log('req//',payload);
        //加入到管理堆栈
        dispatch('netmanager/net_request', {api: payload.command}, {root: true})
        ws_send(JSON.stringify(payload))
    },
    async ['socket_close']({ state, dispatch }) {
        ws_close()
        dispatch('netmanager/stop', null, {root: true})
    },
    async ['socket_message']({ commit, dispatch }, payload) {
        //{command: "purchase_order", message: {}, to: "front"}
        let wsName = fetchUserNameForWS()
        if (payload.for === wsName) {
            console.log('res//', payload)
        }
        if (payload.to == 'front' && payload['for'] == wsName) {
            dispatch(`${command_mapping[payload.command]}`, payload.message, { root: true })
            if (payload.message && !payload.message.success) {
                if(payload.command == 'purchase__send'){
                    dispatch('orderpost/setOrderErrors',[payload.message.errors], { root: true })
                }
                dispatch('response/set-response', { status: 400, socket_error: payload.message.errors, command: payload.command }, { root: true })
                dispatch('netmanager/net_fail', {api: payload.command}, {root: true})
                return
            }
            dispatch('netmanager/net_sucess', {api: payload.command}, {root: true})
        }
    },
    async ['socket_msg_timeout']({ commit, dispatch }, payload) {
        dispatch(`${command_mapping[payload.command]}`, payload.message, { root: true })
    },
}

/*
指令格是: command
|command | dec |
|open_payment__list| 未清账款查询
|credit_detail__online| 信用查询
|bom__list| BOM列表|
|bom__add| 自定义BOM|
|bom__delete| 删除自定义BOM|
|zhdl__list| 大订单折扣列表|
|spr__list| 折扣列表|
|stock__search| 库存查询|
|po__list| 采购订单列表|
|po__entry| 采购订单详情|
|material__list| 物料主数据|
|base__list|基础数据| message: {'base_name': receipt_address/branch/company/country/payment}
|base__add|基础数据|
|base__delete|基础数据|
|base__patch|基础数据|
|dn__list|生成外购入库单|
|packing__list|装箱单信息|
|sn__list|序列号|
|order__list| 电子订单状态|
|order__entry| 电子订单子项|
|purchase__send | 订单发送|
|client_get__config | 获取系统配置项|
|client_write__config | 保存系统配置项 | message=Object|
|client_get__json | ERP回写配置项|
|client_write__json | 保存ERP回写配置项 | message=Object|
|client_get__service_status | 获取服务状态 | 需要发送两次 to='aid' to='client'|
|client_get__main_state|检测主程序属性| to: client|
|running_main_program_online|启动主程序|  to: aid|
|shutdown_main_program_online|关闭主程序| to: aid|
|update_main_program_online|在线更新主程序| to: aid|
|client_get__task_status|任务状态| to: client|
|client_run__task|任务状态| to: client message {'id': task_id}|

|material__export | 物料主数据导出|
|zhdl__export | 大订单折扣导出|
|open_payment__export | 未清账款导出|
|packing__export | 装箱单信息导出|
|sn__export | 序列号导出|

|material__erp| 物料主数据更新ERP|
|dn__erp|生成外购入库单|
|packing__wms|装箱单推送至wms|
|purchase__check| 数据校验|

|purchase__clean_send_state| 采购订单删除发送状态|
|login_disable| 登录状态已失效|

*/



export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
