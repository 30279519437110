// 普通api
import Axios from './axios'

export default {
  addPostHeader(config) {
    return {
      ...config,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' }
    }
  },
  getCaptcha() {
    return Axios.get("/v1/refresh_captcha/")
  },
  login(user, config) {
    return Axios.post("/v1/token/", user, config)
  },
  logout() {
    return Axios.get("/apis/v1/users/source/userlogout/")
  },
  //user
  userGetList(query) {
    return Axios.get(`/apis/v1/users/source/${query}`)
  },
  userAdd(params) {
    return Axios.post('/apis/v1/users/source/', params)
  },
  userEdit(id, params) {
    return Axios.patch(`/apis/v1/users/source/${id}/`, params)
  },
  userDelete(ids) {
    return Axios.post('/apis/v1/users/source/mutidelete/', ids)
  },

  //程序运行状态
  serviceState() {
    return Axios.get('/apis/v1/users/source/program/')
  },
  //检查程序是否有新版本
  checkServiceState() {
    return Axios.get('/apis/v1/users/source/updateprogram/')
  },

  //logs
  logsGetList(query) {
    return Axios.get(`/apis/v1/users/logs/${query}`)
  },
  logsDelete(ids) {
    return Axios.post('/apis/v1/users/logs/', params)
  },

  //所有权限
  getPermissionsList() {
    return Axios.get('/apis/v1/users/permissions/')
  },
  //用户组
  getGroupList(){
    return Axios.get('/apis/v1/users/group/')
  },
  //组内用户
  getUsersList(){
    return Axios.get('/apis/v1/users/source/')
  },
  //获取当前用户权限
  getNowUser(id){
    return Axios.get(`/apis/v1/users/source/${id}/`)
  },
  postAddGroup(data){
    return Axios.post('/apis/v1/users/group/', data)
  },
  deleteGroup(id){
    return Axios.delete(`/apis/v1/users/group/${id}`)
  },
  saveUser(id, data){
    return Axios.post(`/apis/v1/users/source/${id}/setgp/`, data)
  },
  saveGroup(id, data){
    return Axios.patch(`/apis/v1/users/group/${id}/`, {"permissions": data})
  },
}

