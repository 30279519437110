// 页面逻辑：
// work
// esc:  e-erp, s-success, c-count
// eec:  e-erp, e-error, c-count
// errors: 报错信息
// 
// jobs
// cron - 定时任务，不展示
// last_count - 上次运行的数量
const interfacestateData = {
    loading: false,
    //接口返回的原始数据
    netData: {
        "work": {
            "id": 6,
            "created_at": "2023-08-31 18:11:48",
            "material_count": null,
            "material_esc": null,
            "material_eec": null,
            "material_time": null,
            "material_errors": null,
            "zhdl_count": "3",
            "zhdl_time": "0秒",
            "zhdl_errors": "",
            "spr_count": "23",
            "spr_time": "0秒",
            "spr_errors": "",
            "payment_count": null,
            "payment_time": null,
            "payment_errors": null,
            "bom_count": null,
            "bom_time": null,
            "bom_errors": null,
            "packing_count": null,
            "packing_time": null,
            "packing_errors": null,
            "sn_count": null,
            "sn_time": null,
            "sn_errors": null,
            "mp_count": null,
            "mp_time": null,
            "mp_errors": null,
            "md_count": null,
            "md_time": null,
            "md_errors": null,
            "sh_count": null,
            "sh_time": null,
            "sh_errors": null,
            "si_count": null,
            "si_esc": null,
            "si_eec": null,
            "si_time": null,
            "si_errors": null,
            "dn_count": null,
            "dn_time": null,
            "dn_errors": null,
            "bl_count": null,
            "bl_time": null,
            "bl_errors": null,
            "po_count": null,
            "po_time": null,
            "po_errors": null
        },
        "jobs": [
            {
                "task": "material_difference",
                "material_time": "0秒",
                "material_count": 0,
                "material_error_api": 0,
                "material_errors": "",
                "next_run_time": "2023-10-26 14:40-32"
            },
            {
                "task": "packing_difference",
                "packing_time": "0秒",
                "packing_count": 0,
                "packing_error_api": 0,
                "packing_errors": "",
                "next_run_time": "2023-10-26 14:40-32"
            },
            {
                "task": "sn_difference",
                "sn_time": "0秒",
                "sn_count": 0,
                "sn_error_api": 0,
                "sn_errors": "",
                "next_run_time": "2023-10-26 14:40-32"
            },
            {
                "task": "mp_difference",
                "mp_time": "0秒",
                "mp_count": 0,
                "mp_error_api": 0,
                "mp_errors": "",
                "next_run_time": "2023-10-26 14:40-32"
            },
            {
                "task": "md_difference",
                "md_time": "0秒",
                "md_count": 0,
                "md_error_api": 0,
                "md_errors": "",
                "next_run_time": "2023-10-26 14:40-32"
            },
            {
                "task": "sh_difference",
                "sh_time": "0秒",
                "sh_count": 0,
                "sh_error_api": 0,
                "sh_errors": "",
                "next_run_time": "2023-10-26 14:40-32"
            },
            {
                "task": "si_difference",
                "si_time": "0秒",
                "si_count": 0,
                "si_error_api": 0,
                "si_errors": "",
                "si_esc": 0,
                "si_eec": 0,
                "next_run_time": "2023-10-26 14:40-32"
            },
            {
                "task": "dn_difference",
                "dn_time": "0秒",
                "dn_count": 0,
                "dn_error_api": 0,
                "dn_errors": "",
                "next_run_time": "2023-10-26 14:40-32"
            },
            {
                "task": "bl_difference",
                "bl_time": "0秒",
                "bl_count": 0,
                "bl_error_api": 0,
                "bl_errors": "",
                "next_run_time": "2023-10-26 14:40-32"
            },
            {
                "task": "po_difference",
                "po_time": "0秒",
                "po_count": 0,
                "po_error_api": 0,
                "po_errors": "",
                "next_run_time": "2023-10-26 14:40-32"
            }
        ]
    },

    //处理后的数据
    dealedData: {
        id: null,
        created_at: null,
        titleArr: [],
        taskIdArr: [],
        countArr: [],
        escArr: [],
        eecArr: [],
        timeArr: [],
        errorsArr: [],
        // idArr: [],
        // nameArr: [],
        // nextRunTimeArr: [],
        // jobs: [
        //     {
        //         "id": "so_item_cron_worker",
        //         "name": "Worker.so_item_cron",
        //         "next_run_time": "2023-09-05 13:34-45",
        //         title: ''
        //     },
        // ],
        jobs: {
            // titleArr: [],
            // idArr: [],
            // nameArr: [],
            // nextRunTimeArr: [],
            // lastCountArr: [],

            
            taskArr: [],//主键
            codeArr: [],//辅助项：task去掉_difference
            titleArr: [],//辅助项：标题
            timeArr: [],//{code}_time
            countArr: [],//{code}_count
            errorApiArr: [],//{code}_error_api
            errorsArr: [],//{code}_errors
            escArr: [],//{code}_esc
            eecArr: [],//{code}_eec
            nextRunTimeArr: [],//next_run_time
        }
    },

    //对应关系
    refs: {
        jobs: {
            // "billing_interval_worker": "Billing",
            // "dn_interval_worker": "DN",
            // "material_interval_worker": "物料",
            // "milestone_dn_interval_worker": "物流DN",
            // "milestone_po_interval_worker": "物流PO",
            // "order_interval_worker": "电子订单",
            // "packing_interval_worker": "装箱单",
            // "po_interval_worker": "PO",
            // "sn_interval_worker": "序列号",
            // "so_header_interval_worker": "SO Header",
            // "so_item_interval_worker": "SO Item",
            // "spr_interval_worker": "特价单",
            // "payment_interval_worker": "未清账款",
            'material': '物料',
            'packing': '装箱单', 
            'sn': '序列号',
            'mp': '物流PO',
            'md': '物流DN',
            'sh': 'SO Header',
            'si': 'SO Item',
            'dn': 'DN',
            'bl': 'Billing',
            'po': 'PO',
            'spr': '特价单',
            'payment': '未清账款',
            'order': '电子订单',
        }
    }
}
const state = () => ({
    interfacestate: Object.assign({}, interfacestateData)
})
const getters = {
    refs_jobs: state => state.interfacestate.refs.jobs,
    netData: state => state.interfacestate.netData,
    dealedData: state => state.interfacestate.dealedData,
    jobs: state => state.interfacestate.dealedData.jobs,
    loading: state => state.interfacestate.loading,
}
const mutations = {
    clear_data(state, data) {
        state.interfacestate.dealedData.titleArr = []
        state.interfacestate.dealedData.countArr = []
        state.interfacestate.dealedData.escArr = []
        state.interfacestate.dealedData.eecArr = []
        state.interfacestate.dealedData.timeArr = []
        state.interfacestate.dealedData.errorsArr = []
    },
    deal_data(state, payload) {
        // 柱状图数据处理
        let work = state.interfacestate.netData.work
        state.interfacestate.dealedData.id = work.id
        state.interfacestate.dealedData.created_at = work.created_at
        state.interfacestate.dealedData.titleArr = [
            "物料",
            "大订单折扣",
            // "特价单",
            // "未清账款",
            "BOM",
            "装箱单",
            "序列号",
            "物流PO",
            "物流DN",
            "SO Header",
            "SO Item",
            "DN",
            "Billing",
            "PO",
        ]
        state.interfacestate.dealedData.taskIdArr = [
            'material_cron_worker',
            'zhdl_cron_worker',
            // work.spr_count,
            // work.payment_count,
            'bom_cron_worker',
            'packing_cron_worker',
            'sn_cron_worker',
            'milestone_po_cron_worker',
            'milestone_dn_cron_worker',
            'so_header_cron_worker',
            'so_item_cron_worker',
            'dn_cron_worker',
            'billing_cron_worker',
            'po_cron_worker',
        ]
        state.interfacestate.dealedData.countArr = [
            work.material_count,
            work.zhdl_count,
            // work.spr_count,
            // work.payment_count,
            work.bom_count,
            work.packing_count,
            work.sn_count,
            work.mp_count,
            work.md_count,
            work.sh_count,
            work.si_count,
            work.dn_count,
            work.bl_count,
            work.po_count,
        ]
        state.interfacestate.dealedData.escArr = [
            work.material_esc,
            work.zhdl_esc,
            // work.spr_esc,
            // work.payment_esc,
            work.bom_esc,
            work.packing_esc,
            work.sn_esc,
            work.mp_esc,
            work.md_esc,
            work.sh_esc,
            work.si_esc,
            work.dn_esc,
            work.bl_esc,
            work.po_esc,
        ]
        state.interfacestate.dealedData.eecArr = [
            work.material_eec,
            work.zhdl_eec,
            // work.spr_eec,
            // work.payment_eec,
            work.bom_eec,
            work.packing_eec,
            work.sn_eec,
            work.mp_eec,
            work.md_eec,
            work.sh_eec,
            work.si_eec,
            work.dn_eec,
            work.bl_eec,
            work.po_eec,
        ]
        state.interfacestate.dealedData.timeArr = [
            work.material_time,
            work.zhdl_time,
            // work.spr_time,
            // work.payment_time,
            work.bom_time,
            work.packing_time,
            work.sn_time,
            work.mp_time,
            work.md_time,
            work.sh_time,
            work.si_time,
            work.dn_time,
            work.bl_time,
            work.po_time,
        ]
        state.interfacestate.dealedData.errorsArr = [
            work.material_errors,
            work.zhdl_errors,
            // work.spr_errors,
            // work.payment_errors,
            work.bom_errors,
            work.packing_errors,
            work.sn_errors,
            work.mp_errors,
            work.md_errors,
            work.sh_errors,
            work.si_errors,
            work.dn_errors,
            work.bl_errors,
            work.po_errors,
        ]

        // 底部图表数据处理
        let jobs = state.interfacestate.netData.jobs
        let ref_jobs = state.interfacestate.refs.jobs;

        //过滤掉id中包含cron的数据
        // jobs = jobs.filter((job) => job.id.indexOf("_cron_") === -1);

        //为数据增加title
        for (let i = 0; i < jobs.length; i++) {
            const element = jobs[i];
            // element.title = ref_jobs[`${element.id}`]
            // state.interfacestate.dealedData.jobs.titleArr[i] = ref_jobs[`${element.id}`]
            // state.interfacestate.dealedData.jobs.idArr[i] = element.id
            // state.interfacestate.dealedData.jobs.nameArr[i] = element.name
            // state.interfacestate.dealedData.jobs.nextRunTimeArr[i] = element.next_run_time
            // state.interfacestate.dealedData.jobs.lastCountArr[i] = element.last_count

            const task = element.task
            const code = task.slice(0, task.length - '_difference'.length)
            console.log('++++++++++++',code);
            const title = ref_jobs[`${code}`]

            state.interfacestate.dealedData.jobs.taskArr[i] = task  //主键
            state.interfacestate.dealedData.jobs.codeArr[i] = code  //辅助项：task去掉_difference
            state.interfacestate.dealedData.jobs.titleArr[i] = title ? title : code  //辅助项：中文名称
            state.interfacestate.dealedData.jobs.timeArr[i] = element[`${code}_time`]   //{code}_time
            state.interfacestate.dealedData.jobs.countArr[i] = element[`${code}_count`]   //{code}_count
            state.interfacestate.dealedData.jobs.errorApiArr[i] = element[`${code}_error_api`]  //{code}_error_api
            state.interfacestate.dealedData.jobs.errorsArr[i] = element[`${code}_errors`]   //{code}_errors
            state.interfacestate.dealedData.jobs.escArr[i] = element[`${code}_esc`]   //{code}_esc
            state.interfacestate.dealedData.jobs.eecArr[i] = element[`${code}_eec`]   //{code}_eec
            state.interfacestate.dealedData.jobs.nextRunTimeArr[i] = element.next_run_time  //next_run_time
        }

        // state.interfacestate.dealedData.jobs = jobs
        console.log('+++', state.interfacestate.dealedData.jobs)

    },
    update_net_data(state, payload) {
        state.interfacestate.netData = payload
    },
}
const actions = {
    async ['fetch-client']({ state, dispatch, rootState }) {
        if (state.interfacestate.loading == true) return
        let msg = { command: "client_get__task_status", message: {}, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        state.interfacestate.loading = true
    },
    async ['return_client_get__task_status']({ state, commit }, payload) {
        console.log(payload, '接口状态//')
        state.interfacestate.loading = false
        commit('update_net_data', payload.data)
        commit('deal_data')
    },

    //执行任务
    async ['run-task']({ state, dispatch, rootState }, payload) {
        if (state.interfacestate.loading == true) return
        dispatch('response/info-tip', { message: '执行中，耗时可能较长，请耐心等待或稍后查看' }, { root: true })
        let msg = { command: "client_run__task", message: payload, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        state.interfacestate.loading = true
    },
    async ['return_client_run__task']({ state, commit, dispatch }, payload) {
        console.log(payload, '执行任务//')
        state.interfacestate.loading = false
        //TODO:处理状态
        if (payload.success == true) {
            dispatch('response/success-tip', { message: '执行成功' }, { root: true })
            //刷新数据
            dispatch('fetch-client')
        }
    },

}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}