const api_response = {
    response: {},
    form_validate: {
        type:'错误类型', msg:'错误信息'
    },
    
}
const state = () => ({
    response: Object.assign({}, api_response)
})
const getters = {
    FormValidate: state=> state.response.form_validate,
    ApiResponse: state=> state.response.response,
}
const mutations = {}
const actions = {
    async ['set-response'] ({state}, payload){
        if(payload.status==500){ 
            state.response.response = { 
                type: 'negative', message: payload.statusText, caption:  `接口：${payload.request.responseURL}`
            }
        }
        if(payload.status==400){
            if(payload.data&&payload.data.error_type){
                //表单错误信息提示
                // state.response.form_validate = {type: payload.data.error_type[0], msg: payload.data.message[0]}
                state.response.form_validate = {type: payload.data.error_type, msg: payload.data.message}
            }else{
                if (payload.socket_error){
                    state.response.response = { 
                        type: 'negative', message: payload.socket_error, 
                        caption:  `客户端：${payload.command}`
                    }
                }else{
                    state.response.response = { 
                        type: 'negative', message: payload.data.message?payload.data.message:payload.statusText, 
                        caption:  `接口：${payload.request.responseURL}`
                    }
                }
                
            }
        }
    },
    async ['error-tip'] ({state},payload){
        state.response.response = {
            type:'negative',message:payload.message
        }
    },
    async ['success-tip'] ({state},payload){
        state.response.response = {
            type:'positive',message:payload.message
        }
    },
    async ['warning-tip'] ({state},payload){
        state.response.response = {
            type:'warning',message:payload.message
        }
    },
    async ['info-tip'] ({state},payload){
        state.response.response = {
            type:'info',message:payload.message
        }
    },
    async ['ongoing-tip'] ({state},payload){
        state.response.response = {
            type:'ongoing',message:payload.message
        }
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}