import store from '../store'

let socket = null
let heartbeatInterval = null //心跳包定时器

// WebSocket.CONNECTING	= 0
// WebSocket.OPEN	    = 1
// WebSocket.CLOSING    = 2
// WebSocket.CLOSED     = 3

//创建并初始化 - 仅在登录后调用一次即可
function ws_init() {
    return new Promise((resolve, reject) => {
        const isLoginDisabled = store.getters["user/isLoginDisabled"];
        if (isLoginDisabled) {
            console.log('登录信息已失效，禁止连接ws');
            return reject()
        }

        const token = store.getters["user/userBase"].token
        if (!token) {
            return reject(new Error('token is underfined'))
        }

        if (socket) {
            ws_close()
        }

        let socketUrl = store.getters['channel/getWSurl']
        socket = new WebSocket(socketUrl + '?token=' + token);
        socket.onopen = function (res) {
            console.log("onopen://", res)
            console.log("ws连接成功")
            start_heartbeat()
            resolve()
        };

        socket.onmessage = function (res) {
            if (JSON.parse(res.data).command !== 'front_check') {
                // console.log("onmessage://", res)
                store.dispatch('channel/socket_message', JSON.parse(res.data), { root: true })
            }
        };

        socket.onerror = function (res) {
            console.log("onerror://", res)
        };

        socket.onclose = function (res) {
            console.log("onclose://", res)
            socket = null
            stop_heartbeat()
            reject(new Error('socket close'))

            //重新连接
            ws_init()
        };
    })
}

//发送心跳包
function start_heartbeat() {
    if (localStorage.getItem('s_heart') === 'close') return

    const isLoginDisabled = store.getters["user/isLoginDisabled"];
    if (isLoginDisabled) {
        console.log('登录信息已失效，禁止发送心跳包');
        return
    }

    if (!heartbeatInterval) {
        heartbeatInterval = setInterval(() => {
            // slog('当前时间为：',Date.now());
            ws_check().then(res => {
                slog('发送心跳包：//', store.getters['channel/getHartbeat']);
                socket.send(JSON.stringify(store.getters['channel/getHartbeat']))
            })
        }, 10000);
    }
}
//停止发送心跳包
function stop_heartbeat() {
    clearInterval(heartbeatInterval);
    heartbeatInterval = null;
}

//检查连接状态，网络发起前都需要调用这个方法
function ws_check() {
    const isLoginDisabled = store.getters["user/isLoginDisabled"];
    if (isLoginDisabled) {
        console.log('登录信息已失效，禁止发送消息');
        return new Promise((resolve, reject) => {
            return reject()
        }) 
    }


    slog('ws_check')
    return new Promise((resolve, reject) => {
        //检查ws是否链接
        //0-未连接，1-已连接，2-连接中，3-连接断开
        if (socket.readyState === WebSocket.OPEN) {
            slog('WebSocket.OPEN 可以发送消息')
            return resolve()
        } else if (socket.readyState === WebSocket.CLOSED) {
            slog('WebSocket.CLOSED 即将重新连接')
            ws_init().then(res => {
                slog('连接成功', res)
                return resolve()
            }).catch(err => {
                slog('ws连接失败', err)
                return reject()
            })
        } else if (socket.readyState === WebSocket.CONNECTING) {
            slog('WebSocket.CONNECTING 正在连接...')
            setTimeout(() => {
                ws_check().then(res => {
                    slog('连接成功', res)
                    resolve()
                }).catch(err => {
                    console, log('ws连接失败', err)
                    reject()
                })
            }, 200);
        } else if (socket.readyState === WebSocket.CLOSING) {
            slog('WebSocket.CLOSING 正在关闭...')
            setTimeout(() => {
                ws_check().then(res => {
                    slog('连接成功', res)
                    resolve()
                }).catch(err => {
                    console, log('ws连接失败', err)
                    reject()
                })
            }, 200);
        } else {
            throw new Error('socket.readyState异常')
        }
    })
}

//断开连接 - 退出登录时需要调用
function ws_close() {
    console.log('登录信息已失效，ws断开连接');
    stop_heartbeat()
    socket.close()
    socket = null
}

//发送消息
function ws_send(msg) {
    if (socket === null) {
        ws_init().then(res => {
            socket.send(msg)
        })
        return
    }

    ws_check().then(res => {
        socket.send(msg)
    })
}

function slog(...args) {
    if (localStorage.getItem('slog') === 'open') {
        console.log(...args);
    }
}

export {
    ws_init,
    ws_send,
    ws_close,
}