let cacheSize = parseInt(localStorage.getItem('pageSize'))

const tableData = {
    invoiceTable: {//发票信息表格配置
        tableName:'table_milestoneDnTable',
        loading: false,
        rowkey: 'id',
        showSelection:true,
        selected: [],
        selection: 'single',
        orderby: 'order by SO_ITEM_NO',
        tableTitle: '发票(Billing)信息',
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        data: [],
        columns: [
            {
                check: true,  name: 'VATInvoiceNumber', label: 'VAT发票号', align: 'left', field: 'VATInvoiceNumber', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_SO_NO', label: '西门子销售订单号', align: 'left', field: 'F_SO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_ITEM_NO', label: 'SO行号', align: 'right', field: 'SO_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_CUSTOMER_PO_NO', label: '客户订单号', align: 'left', field: 'F_CUSTOMER_PO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'CUSTOMER_ITEM_NO', label: 'ERP订单行号', align: 'right', field: 'CUSTOMER_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DELIVERY_NO', label: '发货单号', align: 'right', field: 'DELIVERY_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DELIVERY_ITEM_NO', label: '发货单行号', align: 'right', field: 'DELIVERY_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Billing_number', label: 'Billing号', align: 'left', field: 'Billing_number', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'BILLING_ITEM_NO', label: 'Biiling Item', align: 'left', field: 'BILLING_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'VATInvoiceNumber', label: 'VAT发票号', align: 'left', field: 'VATInvoiceNumber', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Billing_Release_Date', label: 'VAT发票日期', align: 'left', field: 'Billing_Release_Date', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'BaselineDate', label: '记账日期', align: 'left', field: 'BaselineDate', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Accounting_Document_Date', label: '凭证日期', align: 'left', field: 'Accounting_Document_Date', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Billing_quantity', label: 'Billing数量', align: 'right', field: 'Billing_quantity', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Billing_creation_date', label: 'Billing创建日期', align: 'left', field: 'Billing_creation_date', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Net_due_date', label: '应付日期', align: 'left', field: 'Net_due_date', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Item_net_value_in_billing', label: '行项目净值', align: 'left', field: 'Item_net_value_in_billing', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Item_tax_value_in_billing', label: '行项目税值', align: 'left', field: 'Item_tax_value_in_billing', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Item_total_value_in_billing', label: '行项目总价', align: 'left', field: 'Item_total_value_in_billing', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Billing_Currency', label: '币种', align: 'left', field: 'Billing_Currency', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },

        ],
        tools: {
            show_toolbar: true,
        },
        height: ''
    },
    dnTable: {//dn发货信息表格配置
        tableName:'table_dn',
        loading: false,
        rowkey: 'id',
        selection: 'single',
        selected: [],
        orderby: 'order by SO_ITEM_NO',
        tableTitle: '发货dn信息',
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        data: [],
        columns: [
            {
                check: true,  name: 'F_SO_NO', label: '西门子销售订单号', align: 'left', field: 'F_SO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_ITEM_NO', label: 'SO行号', align: 'right', field: 'SO_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_CUSTOMER_PO_NO', label: '客户订单号', align: 'left', field: 'F_CUSTOMER_PO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'CUSTOMER_ITEM_NO', label: 'ERP订单行号', align: 'right', field: 'CUSTOMER_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DELIVERY_NO', label: '发货单号', align: 'left', field: 'DELIVERY_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DELIVERY_ITEM_NO', label: '发货单行号', align: 'right', field: 'DELIVERY_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Shippingpoint', label: '发货点', align: 'left', field: 'Shippingpoint', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'UCR_NO', label: '运单号', align: 'left', field: 'UCR_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DELIVERY_CREATEDATE', label: 'DN创建日期', align: 'left', field: 'DELIVERY_CREATEDATE', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : ''}`
            },
            {
                check: true,  name: 'DELIVERY_CREATETIME', label: 'DN创建时间', align: 'left', field: 'DELIVERY_CREATETIME', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DN_Creator', label: 'DN创建人', align: 'left', field: 'DN_Creator', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DELIVERY_SENDDATE', label: '货物出库时间', align: 'left', field: 'DELIVERY_SENDDATE', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : ''}`
            },
            {
                check: true,  name: 'DELIVERY_QUANTITY', label: '发货数量', align: 'right', field: 'DELIVERY_QUANTITY', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_Code', label: '收货点代码', align: 'left', field: 'SO_Ship_To_Code', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_City', label: '收货点城市', align: 'left', field: 'SO_Ship_To_City', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_Address', label: '收货点地址（中文）', align: 'left', field: 'SO_Ship_To_Address', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_Contact_Person', label: '收货点联系人', align: 'left', field: 'SO_Ship_To_Contact_Person', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_Phone_Number', label: '收货点联系电话', align: 'left', field: 'SO_Ship_To_Phone_Number', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
        ],
        tools: {
            show_toolbar: true,
        },
        height: ''
    },
}
const state = () => ({
    invoiceQuery: Object.assign({}, tableData),
})

const getters = {
    invoiceTable: state => state.invoiceQuery.invoiceTable,
    dnTable: state => state.invoiceQuery.dnTable,
    selectedData:state => state.invoiceQuery.invoiceTable.selected,
}
const mutations = {
    refreshTableHeight(state, data) {
        state.invoiceQuery.invoiceTable.maxHeight = data + 'px'
        state.invoiceQuery.dnTable.maxHeight = data + 'px'
    },
    setList(state, data) {
        state.invoiceQuery.invoiceTable.pagination.rowsNumber = data.count
        state.invoiceQuery.invoiceTable.data = data.data
        state.invoiceQuery.invoiceTable.selected = [data.data[0]]
    },
    setDnList(state, data) {
        state.invoiceQuery.dnTable.pagination.rowsNumber = data.count
        state.invoiceQuery.dnTable.data = data.data
    },
    clearDnData(state){
        state.invoiceQuery.dnTable.data = []
    },
    clearInvoiceData(state){
        state.invoiceQuery.invoiceTable.data = [];
        state.invoiceQuery.dnTable.data = [];
    }
}
const actions = {
    async ['fetch-invoice-list']({ state, dispatch, rootState }, payload) {
        if (state.invoiceQuery.invoiceTable.loading == true) return
        payload.orderby = state.invoiceQuery.invoiceTable.orderby;
        let msg = { command: "billing__list", message: payload, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        state.invoiceQuery.invoiceTable.loading = true
    },
    async ['return_billing__list']({ state, commit }, payload) {
        state.invoiceQuery.invoiceTable.loading = false
        if (payload.success) {
            commit('setList', payload.data)
        }
    },
    async ['fetch-dn-list']({ state, dispatch, rootState }, payload) {
        if (state.invoiceQuery.dnTable.loading == true) return
        payload.orderby = state.invoiceQuery.dnTable.orderby;
        let msg = { command: "billing__dn", message: payload, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        state.invoiceQuery.dnTable.loading = true
    },
    async ['return_billing__dn']({ state, commit }, payload) {
        state.invoiceQuery.dnTable.loading = false
        if (payload.success) {
            commit('setDnList', payload.data)
        }
    },
    async ['clear-dn-data']({ state, commit }, payload) {
        commit('clearDnData')
    },
    async ['clear-invoice-data']({ state, commit }, payload) {
        commit('clearInvoiceData')
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
