<template>
  <router-view />
</template>
<script>
export default {

}
</script>
<style scoped>
* {
  font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  
}
</style>

