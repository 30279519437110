import tools from '@/tools/index.js'
import date from 'quasar/src/utils/date.js';
let cacheSize = parseInt(localStorage.getItem('pageSize'))

function setRowColor(item) {
    item.color = 'white'
    item.bgcolor = '#C10015'
}

const tableData = {
    orderTable: {
        tableName:'table_orderTable',
        height: '310px',
        loading: false,
        showSelection:true,
        selected: [],
        selection: 'single',
        rowkey: 'id',
        orderby: 'order by F_SO_NO desc',
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        data: [

        ],
        columns: [
            // {check: true,name: 'F_OrderType',label: '订单类型',align: 'center',field: 'F_OrderType',sortable: true,
            // format: (val) => `${val==3?'Bundled订单':val==2?'特价订单':'普通订单'}`},
            // {
            //     check: true,  name: 'id', label: '序号', align: 'left', field: 'id', sortable: true,
            //     format: (val) => `${val ? val == 'None'?'':val : ''}`
            // },
            // {
            //     check: true,  name: 'CHANGE_TYPE', label: '变更类型', align: 'center', field: 'CHANGE_TYPE', sortable: true,
            //     // format: (val) => `${val ? val == 'None'?'':val : ''}`
            //     format: (val) => `${val == 'None' ? '' : val}`
            // },
            {
                check: true,  name: 'F_SO_NO', label: '西门子销售订单号', align: 'left', field: 'F_SO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_CUSTOMER_PO_NO', label: '客户订单号', align: 'left', field: 'F_CUSTOMER_PO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SPR_Number', label: 'SPR号', align: 'left', field: 'SPR_Number', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Create_Date', label: 'SO创建日期', align: 'left', field: 'SO_Create_Date', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Create_Time', label: 'SO创建时间', align: 'left', field: 'SO_Create_Time', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_OA_CREATEDATE', label: 'OA创建时间', align: 'left', field: 'F_OA_CREATEDATE', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_OC_CREATEDATE', label: 'OC创建时间', align: 'left', field: 'F_OC_CREATEDATE', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Requested_Delivery_Date_H', label: '期望交货日期（SO Head）', align: 'left', field: 'Requested_Delivery_Date_H', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Head_Status', label: '订单状态（整单）', align: 'left', field: 'SO_Head_Status', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'match_state', label: '差异状态', align: 'left', field: 'match_state', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`,
                isShowState:true,
                state:{
                    '1':{name:'fas fa-exclamation-circle',class:'text-warning'},
                }
            },
            {
                check: true,  name: 'CREDIT_BLOCK', label: '信用锁定', align: 'center', field: 'CREDIT_BLOCK', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'BSTH_Block', label: '订单异常提醒(Bstop-H)', align: 'left', field: 'BSTH_Block', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DWPY_Block', label: '预付款待付', align: 'left', field: 'DWPY_Block', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'ZBOC_Block', label: '合同代签', align: 'left', field: 'ZBOC_Block', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'ZRIH_Block', label: '采购信息缺失提醒', align: 'left', field: 'ZRIH_Block', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'AWV_Block', label: '出口控制锁定', align: 'left', field: 'AWV_Block', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Payment_Term', label: '付款方式', align: 'left', field: 'Payment_Term', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Total_NetValue', label: '订单总金额（净值）', align: 'right', field: 'Total_NetValue', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : tools.formattedPrice(val) : ''}`
            },
            {
                check: true,  name: 'Total_NetValue_Currency', label: '币种', align: 'left', field: 'Total_NetValue_Currency', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Customer_PO_Type', label: '下单方式', align: 'left', field: 'Customer_PO_Type', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Order_Reason', label: '下单原因', align: 'left', field: 'Order_Reason', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'End_Customer_Code', label: '最终用户代码', align: 'left', field: 'End_Customer_Code', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'End_Customer_Chinese_Name', label: '最终用户名（中文）', align: 'left', field: 'End_Customer_Chinese_Name', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_Code', label: '收货点代码', align: 'left', field: 'SO_Ship_To_Code', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_City', label: '收货点城市', align: 'left', field: 'SO_Ship_To_City', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_Address', label: '收货点地址（中文）', align: 'left', field: 'SO_Ship_To_Address', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_Post_Code', label: '收货点邮编', align: 'left', field: 'SO_Ship_To_Post_Code', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_Contact_Person', label: '收货点联系人', align: 'left', field: 'SO_Ship_To_Contact_Person', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_Phone_Number', label: '收货点联系电话', align: 'left', field: 'SO_Ship_To_Phone_Number', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_Email', label: '收货点邮件地址', align: 'left', field: 'SO_Ship_To_Email', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
        ],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [
                {
                    sign: 'export',
                    index: 1,
                    name: ['导出数据', ''],
                    icon: 'fas fa-file-export',
                    click: ['outbound', 'tools_set'],
                }
            ]
        }

    },
    soTable: {
        tableName:'table_soTable',
        loading: false,
        rowkey: 'id',
        selection: 'single',
        selected: [],
        tableTitle: 'PO明细',
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        orderby: 'order by SO_ITEM_NO',
        data: [],
        columns: [
            // {
            //     check: true,  name: 'id', label: 'ID', align: 'left', field: 'id', sortable: true,
            //     format: (val) => `${val ? val == 'None'?'':val : ''}`
            // },
            // {
            //     check: true,  name: 'CHANGE_TYPE', label: '变更类型', align: 'center', field: 'CHANGE_TYPE', sortable: true,
            //     format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            // },
            {
                check: true,  name: 'F_SO_NO', label: '西门子销售订单号', align: 'left', field: 'F_SO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_ITEM_NO', label: 'SO行号', align: 'right', field: 'SO_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'CUSTOMER_ITEM_NO', label: 'ERP订单行号', align: 'right', field: 'CUSTOMER_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Shippingpoint', label: '发货点', align: 'left', field: 'Shippingpoint', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_CUSTOMER_ARTICLE_NO', label: '客户物料编码', align: 'left', field: 'F_CUSTOMER_ARTICLE_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'MLFB_Sort', label: '订货号', align: 'left', field: 'MLFB_Sort', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Option', label: '选件', align: 'left', field: 'Option', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },{
                check: true,  name: 'Material', label: '物料编号', align: 'left', field: 'Material', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'MATERIAL_DESCRIPTION', label: '物料描述', align: 'left', field: 'MATERIAL_DESCRIPTION', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'MATERIAL_CATEGORY', label: '物料分类', align: 'left', field: 'MATERIAL_CATEGORY', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Reject_Reason', label: '拒单原因代码', align: 'left', field: 'Reject_Reason', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_LAST_CONFIRM_DATE', label: '最新确认交期', align: 'left', field: 'F_LAST_CONFIRM_DATE', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : ''}`
            },
            {
                check: true,  name: 'F_DN_DUE_DATE', label: '预计发货单日期', align: 'left', field: 'F_DN_DUE_DATE', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : ''}`
            },
            {
                check: true,  name: 'Requested_Delivery_Date_I', label: '期望交货日期（SO item）', align: 'left', field: 'Requested_Delivery_Date_I', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : ''}`
            },{
                check: true,  name: 'LGBK', label: 'LGBK', align: 'left', field: 'LGBK', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },{
                check: true,  name: 'SO_Item_Status', label: '订单状态（item）', align: 'left', field: 'SO_Item_Status', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },{
                check: true,  name: 'DLBL_Block', label: '发货锁定', align: 'left', field: 'DLBL_Block', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },{
                check: true,  name: 'ZMIN_Block', label: '最低价锁定', align: 'left', field: 'ZMIN_Block', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },{
                check: true,  name: 'ZIRI_Block', label: '采购信息缺失提醒', align: 'left', field: 'ZIRI_Block', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },{
                check: true,  name: 'BSTI_Block', label: '订单异常提醒（Bstop-I）', align: 'left', field: 'BSTI_Block', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },{
                check: true,  name: 'ECC_Block', label: '物料出口锁定', align: 'left', field: 'ECC_Block', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            }, {
                check: true,  name: 'L2_PRICE', label: '列表价', align: 'right', field: 'L2_PRICE', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true,  name: 'UNIT_PRICE', label: '不含税单价', align: 'right', field: 'UNIT_PRICE', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true,  name: 'MATERIAL_PRICE', label: '不含税总价', align: 'right', field: 'MATERIAL_PRICE', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true,  name: 'Item_Total_Tax', label: '税额', align: 'right', field: 'Item_Total_Tax', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true,  name: 'Sales_unit', label: '销售单位', align: 'left', field: 'Sales_unit', sortable: true,
                // format: (val) => `${val ? val : ''}`
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Item_Currency', label: '币种', align: 'left', field: 'SO_Item_Currency', sortable: true,
                // format: (val) => `${val ? val : ''}`
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'QUANTITY', label: '订购数量', align: 'right', field: 'QUANTITY', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DISCOUNT', label: '折扣', align: 'right', field: 'DISCOUNT', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true,  name: 'Fix_Price', label: '固定价格', align: 'right', field: 'Fix_Price', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true, name: 'erprewrite_status', label: 'ERP回写状态', align: 'center', field: 'erprewrite_status', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, name: 'erprewrite_msg', label: 'ERP回写信息', align: 'left', field: 'erprewrite_msg', sortable: true,
                format: (val) => `${val ? val : ''}`
            }
        ],
        tools: {
            show_toolbar: true,

        },
        height: ''

    },
    poTable: {
        tableName:'table_poTable',
        loading: false,
        rowkey: 'id',
        selection: 'single',
        selected: [],
        orderby: 'order by SO_ITEM_NO',
        tableTitle: '订单信息',
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        data: [],
        columns: [
            // {
            //     check: true,  name: 'id', label: 'ID', align: 'left', field: 'id', sortable: true,
            //     format: (val) => `${val ? val == 'None'?'':val : ''}`
            // },

            // {
            //     check: true,  name: 'Option', label: '变更类型', align: 'center', field: 'Option', sortable: true,
            //     format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            // },
            {
                check: true,  name: 'F_SO_NO', label: '西门子销售订单号', align: 'left', field: 'F_SO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_ITEM_NO', label: 'SO行号', align: 'right', field: 'SO_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_CUSTOMER_PO_NO', label: '客户订单号', align: 'left', field: 'F_CUSTOMER_PO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'CUSTOMER_ITEM_NO', label: 'ERP订单行号', align: 'right', field: 'CUSTOMER_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Shippingpoint', label: '发货点', align: 'left', field: 'Shippingpoint', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'MLFB_Sort', label: '订货号', align: 'left', field: 'MLFB_Sort', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Option', label: '选件', align: 'left', field: 'Option', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'PO_NO', label: '西门子内部po编号', align: 'left', field: 'PO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'PO_ITEM_NO', label: 'PO行号', align: 'right', field: 'PO_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'PO_Create_Date', label: 'PO创建日期', align: 'right', field: 'PO_Create_Date', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'VENDORNAME', label: '供应商', align: 'left', field: 'VENDORNAME', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },

            {
                check: true,  name: 'DELIVERY_STATUS', label: '工厂发货状态', align: 'center', field: 'DELIVERY_STATUS', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_EXTERNAL_DOCUMENT', label: '工厂运单号', align: 'left', field: 'F_EXTERNAL_DOCUMENT', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Confirmed_delivery_date', label: '供应商确认发货日期', align: 'left', field: 'Confirmed_delivery_date', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : ''}`
            },
            {
                check: true,  name: 'DELIVERY_DATE', label: '供应商发货日期', align: 'left', field: 'DELIVERY_DATE', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : ''}`
            },
            {
                check: true,  name: 'GR_DATE', label: '收货日期', align: 'left', field: 'GR_DATE', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : ''}`
            },
            {
                check: true,  name: 'GR_QUANTITY', label: '收货数量', align: 'right', field: 'GR_QUANTITY', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },

        ],
        tools: {
            show_toolbar: true,

        },
        height: ''

    },

    milestonePoTable: {
        tableName:'table_milestonePoTable',
        loading: false,
        rowkey: 'id',
    selection: 'single',
    selected: [],
        orderby: 'order by SO_ITEM_NO',
        tableTitle: '发货单DN信息',
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        data: [],
        columns: [
            // {
            //     check: true,  name: 'CHANGE_TYPE', label: '变更类型', align: 'center', field: 'CHANGE_TYPE', sortable: true,
            //     format: (val) => `${val == 'None' ? '' : val}`
            // },
            {
                check: true,  name: 'F_SO_NO', label: '西门子销售订单号', align: 'left', field: 'F_SO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_ITEM_NO', label: 'SO行号', align: 'right', field: 'SO_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_CUSTOMER_PO_NO', label: '客户订单号', align: 'left', field: 'F_CUSTOMER_PO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'CUSTOMER_ITEM_NO', label: 'ERP订单行号', align: 'right', field: 'CUSTOMER_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Shippingpoint', label: '发货点', align: 'left', field: 'Shippingpoint', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'MLFB_Sort', label: '订货号', align: 'left', field: 'MLFB_Sort', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Option', label: '选件', align: 'left', field: 'Option', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'PO_NO', label: '西门子内部po编号', align: 'left', field: 'PO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'PO_ITEM_NO', label: 'PO行号', align: 'right', field: 'PO_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_EXTERNAL_DOCUMENT', label: '工厂运单号', align: 'left', field: 'F_EXTERNAL_DOCUMENT', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'L310', label: '海外供应商仓库出库', align: 'left', field: 'L310', sortable: true,
                format: (val) => `${val ?  date.formatDate(val.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6'), "YYYY-MM-DD HH:mm:ss")  : ''}`
            },
            {
                check: true,  name: 'L315', label: '货交承运商', align: 'left', field: 'L315', sortable: true,
                format: (val) => `${val ?  date.formatDate(val.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6'), "YYYY-MM-DD HH:mm:ss")  : ''}`

            },
            {
                check: true,  name: 'L320', label: '国际运输开始', align: 'left', field: 'L320', sortable: true,
                format: (val) => `${val ?  date.formatDate(val.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6'), "YYYY-MM-DD HH:mm:ss")  : ''}`

            },
            {
                check: true,  name: 'L330', label: '到达目的港', align: 'left', field: 'L330', sortable: true,
                format: (val) => `${val ?  date.formatDate(val.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6'), "YYYY-MM-DD HH:mm:ss")  : ''}`

            },
            {
                check: true,  name: 'L335', label: '待清关', align: 'left', field: 'L335', sortable: true,
                format: (val) => `${val ?  date.formatDate(val.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6'), "YYYY-MM-DD HH:mm:ss")  : ''}`

            },
            {
                check: true,  name: 'L350', label: '清关结束', align: 'left', field: 'L350', sortable: true,
                format: (val) => `${val ?  date.formatDate(val.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6'), "YYYY-MM-DD HH:mm:ss")  : ''}`

            },
            {
                check: true,  name: 'L400', label: '货物送达', align: 'left', field: 'L400', sortable: true,
                format: (val) => `${val ?  date.formatDate(val.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6'), "YYYY-MM-DD HH:mm:ss")  : ''}`

            }


        ],
        tools: {
            show_toolbar: true,

        },
        height: ''

    },
    dn: {
        tableName:'table_dn',
        loading: false,
        rowkey: 'id',
    selection: 'single',
    selected: [],
        orderby: 'order by SO_ITEM_NO',
        tableTitle: '物料LOGISTIC信息',
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        data: [],
        columns: [
            // {
            //     check: true,  name: 'Option', label: '变更类型', align: 'center', field: 'Option', sortable: true,
            //     format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            // },
            {
                check: true,  name: 'F_SO_NO', label: '西门子销售订单号', align: 'left', field: 'F_SO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_ITEM_NO', label: 'SO行号', align: 'right', field: 'SO_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_CUSTOMER_PO_NO', label: '客户订单号', align: 'left', field: 'F_CUSTOMER_PO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'CUSTOMER_ITEM_NO', label: 'ERP订单行号', align: 'right', field: 'CUSTOMER_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'match_state', label: '差异状态', align: 'right', field: 'match_state', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`,
                isShowState:true,
                state:{
                    '1':{name:'fas fa-exclamation-circle',class:'text-warning'},
                }
            },
            {
                check: true,  name: 'match_msg', label: '差异信息', align: 'right', field: 'match_msg', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'MLFB_Sort', label: '订货号', align: 'left', field: 'MLFB_Sort', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Option', label: '选件', align: 'left', field: 'Option', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DELIVERY_NO', label: '发货单号', align: 'left', field: 'DELIVERY_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DELIVERY_ITEM_NO', label: '发货单行号', align: 'right', field: 'DELIVERY_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Shippingpoint', label: '发货点', align: 'left', field: 'Shippingpoint', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'UCR_NO', label: '运单号', align: 'left', field: 'UCR_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DELIVERY_CREATEDATE', label: 'DN创建日期', align: 'left', field: 'DELIVERY_CREATEDATE', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : ''}`
            },
            {
                check: true,  name: 'DELIVERY_CREATETIME', label: 'DN创建时间', align: 'left', field: 'DELIVERY_CREATETIME', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DN_Creator', label: 'DN创建人', align: 'left', field: 'DN_Creator', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DELIVERY_SENDDATE', label: '货物出库时间', align: 'left', field: 'DELIVERY_SENDDATE', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : ''}`
            },
            {
                check: true,  name: 'DELIVERY_QUANTITY', label: '发货数量', align: 'right', field: 'DELIVERY_QUANTITY', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_Code', label: '收货点代码', align: 'left', field: 'SO_Ship_To_Code', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_City', label: '收货点城市', align: 'left', field: 'SO_Ship_To_City', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_Address', label: '收货点地址（中文）', align: 'left', field: 'SO_Ship_To_Address', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_Contact_Person', label: '收货点联系人', align: 'left', field: 'SO_Ship_To_Contact_Person', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_Ship_To_Phone_Number', label: '收货点联系电话', align: 'left', field: 'SO_Ship_To_Phone_Number', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
        ],
        tools: {
            show_toolbar: true,
            btns: [
                {
                    sign: 'exportDn',
                    index: 1,
                    name: ['导出差异数据', ''],
                    icon: 'fas fa-file-export',
                    click: ['outbound', 'tools_set'],
                },
                {
                    sign: 'mate',
                    index: 2,
                    name: ['匹配行项目', ''],
                    icon: 'fas fa-draw-polygon',
                    click: ['outbound', 'tools_set'],
                }
            ]
        },
        height: ''

    },
    milestoneDnTable: {
        tableName:'table_milestoneDnTable',
        loading: false,
        rowkey: 'id',
        selection: 'single',
        selected: [],
        orderby: 'order by SO_ITEM_NO',
        tableTitle: '发票(Billing)信息',
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        data: [],
        columns: [
            // {
            //     check: true,  name: 'CHANGE_TYPE', label: '变更类型', align: 'center', field: 'CHANGE_TYPE', sortable: true,
            //     format: (val) => `${val == 'None' ? '' : val}`
            // },
            {
                check: true,  name: 'F_SO_NO', label: '西门子销售订单号', align: 'left', field: 'F_SO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_ITEM_NO', label: 'SO行号', align: 'right', field: 'SO_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_CUSTOMER_PO_NO', label: '客户订单号', align: 'left', field: 'F_CUSTOMER_PO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'CUSTOMER_ITEM_NO', label: 'ERP订单行号', align: 'right', field: 'CUSTOMER_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DELIVERY_NO', label: '发货单号', align: 'left', field: 'DELIVERY_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DELIVERY_ITEM_NO', label: '发货单行号', align: 'left', field: 'DELIVERY_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Shippingpoint', label: '发货点', align: 'left', field: 'Shippingpoint', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'MLFB_Sort', label: '订货号', align: 'left', field: 'MLFB_Sort', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Option', label: '选件', align: 'left', field: 'Option', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_EXTERNAL_DOCUMENT', label: '工厂运单号', align: 'left', field: 'F_EXTERNAL_DOCUMENT', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'L310', label: '海外供应商仓库出库', align: 'left', field: 'L310', sortable: true,
                format: (val) => `${val ?  date.formatDate(val.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6'), "YYYY-MM-DD HH:mm:ss")  : ''}`
            },
            {
                check: true,  name: 'L315', label: '货交承运商', align: 'left', field: 'L315', sortable: true,
                format: (val) => `${val ?  date.formatDate(val.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6'), "YYYY-MM-DD HH:mm:ss")  : ''}`
            },
            {
                check: true,  name: 'L320', label: '国际运输开始', align: 'left', field: 'L320', sortable: true,
                format: (val) => `${val ?  date.formatDate(val.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6'), "YYYY-MM-DD HH:mm:ss")  : ''}`
            },
            {
                check: true,  name: 'L330', label: '到达目的港', align: 'left', field: 'L330', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'L335', label: '待清关', align: 'left', field: 'L335', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'L350', label: '清关结束', align: 'left', field: 'L350', sortable: true,
                format: (val) => `${val ?  date.formatDate(val.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6'), "YYYY-MM-DD HH:mm:ss")  : ''}`
            },
            {
                check: true,  name: 'L400', label: '货物送达', align: 'left', field: 'L400', sortable: true,
                format: (val) => `${val ?  date.formatDate(val.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6'), "YYYY-MM-DD HH:mm:ss")  : ''}`
            },

        ],
        tools: {
            show_toolbar: true,
        },
        height: ''
    },
    billingTable: {
        tableName:'table_billingTable',
        loading: false,
        rowkey: 'id',
    selection: 'single',
    selected: [],
        orderby: 'order by SO_ITEM_NO',
        tableTitle: '发票(Billing)信息',
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        data: [],
        columns: [
            // {
            //     check: true,  name: 'Option', label: '变更类型', align: 'center', field: 'Option', sortable: true,
            //     format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            // },
            {
                check: true,  name: 'F_SO_NO', label: '西门子销售订单号', align: 'left', field: 'F_SO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'SO_ITEM_NO', label: 'SO行号', align: 'right', field: 'SO_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'F_CUSTOMER_PO_NO', label: '客户订单号', align: 'left', field: 'F_CUSTOMER_PO_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'CUSTOMER_ITEM_NO', label: 'ERP订单行号', align: 'right', field: 'CUSTOMER_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DELIVERY_NO', label: '发货单号', align: 'right', field: 'DELIVERY_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'DELIVERY_ITEM_NO', label: '发货单行号', align: 'right', field: 'DELIVERY_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Shippingpoint', label: '发货点', align: 'left', field: 'Shippingpoint', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'MLFB_Sort', label: '订货号', align: 'left', field: 'MLFB_Sort', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Option', label: '选件', align: 'left', field: 'Option', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Billing_number', label: 'Billing号', align: 'left', field: 'Billing_number', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'BILLING_ITEM_NO', label: 'Biiling Item', align: 'left', field: 'BILLING_ITEM_NO', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'VATInvoiceNumber', label: 'VAT发票号', align: 'left', field: 'VATInvoiceNumber', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Billing_Release_Date', label: 'VAT发票日期', align: 'left', field: 'Billing_Release_Date', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'BaselineDate', label: '记账日期', align: 'left', field: 'BaselineDate', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Accounting_Document_Date', label: '凭证日期', align: 'left', field: 'Accounting_Document_Date', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Billing_quantity', label: 'Billing数量', align: 'right', field: 'Billing_quantity', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Billing_creation_date', label: 'Billing创建日期', align: 'left', field: 'Billing_creation_date', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Net_due_date', label: '应付日期', align: 'left', field: 'Net_due_date', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Item_net_value_in_billing', label: '行项目净值', align: 'left', field: 'Item_net_value_in_billing', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Item_tax_value_in_billing', label: '行项目税值', align: 'left', field: 'Item_tax_value_in_billing', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Item_total_value_in_billing', label: '行项目总价', align: 'left', field: 'Item_total_value_in_billing', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Billing_Currency', label: '币种', align: 'left', field: 'Billing_Currency', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },

        ],
        tools: {
            show_toolbar: true,

        },
        height: ''

    },
}
const dialogData = {
    dnExportDialogData: {
        loading: false,
        dialog_class: 'default',
        btns: true,
        items: [
            {
                index: 2, label: '格式', showlabel: true, name: 'mode', value: 'XLSX', type: 'select', filled: false, outlined: true, disable: false,
                rules: [''],
                options: ['XLSX', 'CSV'],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                },
                minWidth: 160
            },
            {
                index: 2, label: '数据集', showlabel: true, name: 'record', value: '导出所有差异', type: 'select', filled: false, outlined: true, disable: false,
                options: ['导出所有差异', '导出本单差异'],
                rules: [''],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                },
                minWidth: 160
            },
        ]
    },
}
const state = () => ({
    eorder: Object.assign({}, tableData,dialogData),
})
const getters = {
    orderTable: state => state.eorder.orderTable,
    soTable: state => state.eorder.soTable,
    poTable: state => state.eorder.poTable,
    milestonePoTable: state => state.eorder.milestonePoTable,
    dn: state => state.eorder.dn,
    milestoneDnTable: state => state.eorder.milestoneDnTable,
    billingTable: state => state.eorder.billingTable,
    getEorderIsSelected: state => state.eorder.orderTable.selected.length > 0,
    dnExportDialogData: state => state.eorder.dnExportDialogData,
}
const mutations = {
    clearSelected(state, data) {
        state.eorder.orderTable.selected = []
    },
    changeSelect(state, data) {
        state.eorder.orderTable.selected = [data]
    },
    clearDnExportDialog(state,data) {
        state.eorder.dnExportDialogData.items.forEach(item => {
            if(item.name == 'mode'){
                item.value = 'XLSX'
            }else if(item.name == 'record'){
                item.value = '导出所有差异'
            }

            if(item.name == 'record'){
                if(data == 'eorder'){
                    item.options = ['导出所有差异', '导出本单差异']
                }else{
                    item.options = ['导出所有差异','导出所有','导出查询结果']
                }
            }
        })
    },
    setList(state, data) {
        state.eorder.orderTable.pagination.rowsNumber = data.count
        state.eorder.orderTable.data = data.data;
        state.eorder.orderTable.data.forEach(item=>{
            if(item.match_state == '1'){
                setRowColor(item);
            }
        });
        state.eorder.orderTable.selected = [data.data[0]]
    },
    setSoTable(state, data) {
        state.eorder.soTable.pagination.rowsNumber = data.count
        state.eorder.soTable.data = data.data
    },
    setPoTable(state, data) {
        state.eorder.poTable.pagination.rowsNumber = data.count
        state.eorder.poTable.data = data.data
    },
    setPo2Table(state, data) {
        state.eorder.milestonePoTable.pagination.rowsNumber = data.count
        state.eorder.milestonePoTable.data = data.data
    },
    setDnTable(state, data) {
        state.eorder.dn.pagination.rowsNumber = data.count
        state.eorder.dn.data = data.data;
        state.eorder.dn.data.forEach(item=>{
            if(item.match_state == '1'){
                setRowColor(item);
            }
        })
    },
    setDn2Table(state, data) {
        state.eorder.milestoneDnTable.pagination.rowsNumber = data.count
        state.eorder.milestoneDnTable.data = data.data

    },
    setBillingTable(state, data) {
        state.eorder.billingTable.pagination.rowsNumber = data.count
        state.eorder.billingTable.data = data.data

    },
    soChecked(state, data){
        // let ids = state.eorder.soTable.selected.map(item => item.id)
        // if (ids.includes(data.id)) {
        //     state.eorder.soTable.selected = state.eorder.soTable.selected.filter(item => item.id !== data.id)
        // } else {
        //     state.eorder.soTable.selected.push(data)
        // }
        state.eorder.soTable.selected = [data]
    },
    poChecked(state, data){
        // let ids = state.eorder.poTable.selected.map(item => item.id)
        // if (ids.includes(data.id)) {
        //     state.eorder.poTable.selected = state.eorder.poTable.selected.filter(item => item.id !== data.id)
        // } else {
        //     state.eorder.poTable.selected.push(data)
        // }
        state.eorder.poTable.selected = [data]
    },
    po2Checked(state, data){
        // let ids = state.eorder.milestonePoTable.selected.map(item => item.id)
        // if (ids.includes(data.id)) {
        //     state.eorder.milestonePoTable.selected = state.eorder.milestonePoTable.selected.filter(item => item.id !== data.id)
        // } else {
        //     state.eorder.milestonePoTable.selected.push(data)
        // }
        state.eorder.milestonePoTable.selected = [data]
    },
    dnChecked(state, data){
        // let ids = state.eorder.dn.selected.map(item => item.id)
        // if (ids.includes(data.id)) {
        //     state.eorder.dn.selected = state.eorder.dn.selected.filter(item => item.id !== data.id)
        // } else {
        //     state.eorder.dn.selected.push(data)
        // }
        state.eorder.dn.selected = [data]
    },
    dn2Checked(state, data){
        // let ids = state.eorder.milestoneDnTable.selected.map(item => item.id)
        // if (ids.includes(data.id)) {
        //     state.eorder.milestoneDnTable.selected = state.eorder.milestoneDnTable.selected.filter(item => item.id !== data.id)
        // } else {
        //     state.eorder.milestoneDnTable.selected.push(data)
        // }
        state.eorder.milestoneDnTable.selected = [data]
    },
    billingRowClick(state, data){
        // let ids = state.eorder.billingTable.selected.map(item => item.id)
        // if (ids.includes(data.id)) {
        //     state.eorder.billingTable.selected = state.eorder.billingTable.selected.filter(item => item.id !== data.id)
        // } else {
        //     state.eorder.billingTable.selected.push(data)
        // }
        state.eorder.billingTable.selected = [data]
    },
    ['refreshTableHeight'](state, data) {
        // state.eorder.orderTable.height = 296 + 'px'
        state.eorder.soTable.maxHeight = data + 'px'
        state.eorder.poTable.maxHeight = data + 'px'
        state.eorder.milestonePoTable.maxHeight = data + 'px'
        state.eorder.dn.maxHeight = data + 'px'
        state.eorder.milestoneDnTable.maxHeight = data + 'px'
        state.eorder.billingTable.maxHeight = data + 'px'
    },

}
const actions = {
    async ['fetch-list']({ state, dispatch, rootState }, payload) {

                if (state.eorder.orderTable.loading == true) return
                payload.orderby = state.eorder.orderTable.orderby

                //时间格式转换
                if (payload.query && payload.query.SO_Create_Date) {
                    if (payload.query.SO_Create_Date.from) {
                        payload.query.SO_Create_Date.from = payload.query.SO_Create_Date.from.replace('-','').replace('-','')
                    }
                    if (payload.query.SO_Create_Date.to) {
                        payload.query.SO_Create_Date.to = payload.query.SO_Create_Date.to.replace('-','').replace('-','')
                    }
                }
                let msg = { command: "order__list", message: payload, to: "client" }
                dispatch('channel/socket_send', msg, { root: true })
                state.eorder.orderTable.loading = true
    },
    async ['return_order__list']({ state, commit }, payload) {
        state.eorder.orderTable.loading = false
        if (payload.success) {
            commit('setList', payload.data)
        }
    },
    ['mateDn']({ state, dispatch, rootState }, payload) {
        if (state.eorder.orderTable.loading == true) return
        state.eorder.orderTable.loading = true;
        state.eorder.dn.loading = true
        let msg = { command: "order__dn__match", message: {F_SO_NO:payload}, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
    },
    ['return_order__dn__match']({ state, dispatch, rootState }, payload) {
        state.eorder.orderTable.loading = false;
        state.eorder.dn.loading = false;
        if (payload.success) {
            dispatch('response/success-tip', { message: '操作完成！' }, { root: true })
        }
    },
    async ['dnExport']({ state, dispatch, rootState }, payload) {
        let params = {};
        state.eorder.dnExportDialogData.items.forEach(item => {
            params[item.name] = item.value
        });
        let obj = {}
        obj.file_type = params.mode ? params.mode.toLowerCase() : 'xlsx';
        if(params.record == '导出所有差异'){
            obj.export_type = '4';
        }else if(params.record == '导出本单差异'){
            obj.export_type = '3';
            obj.F_SO_NO = state.eorder.orderTable.selected[0].F_SO_NO;
        }else if(params.record == '导出所有'){
            obj.export_type = '1';
        }else if(params.record == '导出查询结果'){
            obj.export_type = '2';
            obj = {...obj,...payload.params}
        }
        let msg = { command: "dn__export", message: obj, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        dispatch('response/info-tip', { message: '正在导出' }, { root: true })
        state.eorder.orderTable.loading = true;
        state.eorder.dn.loading = true;
    },
    async ['return_dn__export']({ state, commit, dispatch }, payload) {
        if (payload.success) {
            window.open(payload.data, '_blank')
            dispatch('response/success-tip', { message: '导出成功' }, { root: true })
        }
        state.eorder.orderTable.loading = false;
    },
    async ['fetch-detail']({ state, dispatch, rootState }, payload) {
        // state.tabMode = payload[1]

                let message = { 'F_SO_NO': payload[0], 'entry': payload[1] }
                // 'entry': 'so_item/po/milestone_po/dn/milestone_dn/billing'
                if (payload[1] == 'so_item') {
                    state.eorder.soTable.loading = true
                    message.pagination = state.eorder.soTable.pagination
                    message.orderby = state.eorder.soTable.orderby
                }
                if (payload[1] == 'po') {
                    state.eorder.poTable.loading = true
                    message.pagination = state.eorder.poTable.pagination
                    message.orderby = state.eorder.poTable.orderby
                }
                if (payload[1] == 'milestone_po') {
                    state.eorder.milestonePoTable.loading = true
                    message.pagination = state.eorder.milestonePoTable.pagination
                    message.orderby = state.eorder.milestonePoTable.orderby
                }
                if (payload[1] == 'dn') {
                    state.eorder.dn.loading = true
                    message.pagination = state.eorder.dn.pagination
                    message.orderby = state.eorder.dn.orderby
                }
                if (payload[1] == 'milestone_dn') {
                    state.eorder.milestoneDnTable.loading = true
                    message.pagination = state.eorder.milestoneDnTable.pagination
                    message.orderby = state.eorder.dn.orderby
                }
                if (payload[1] == 'billing') {
                    state.eorder.billingTable.loading = true
                    message.pagination = state.eorder.billingTable.pagination
                    message.orderby = state.eorder.billingTable.orderby
                }

                let msg = { command: "order__entry", message: message, to: "client" }
                dispatch('channel/socket_send', msg, { root: true })
    },
    async ['return_order__entry']({ state, commit }, payload) {
        // if (payload.success) {
        //     //哪个模块数据
        //     if (payload.entry == 'so_item') {
        //         state.eorder.soTable.loading = false
        //         commit('setSoTable', payload.data)
        //     }
        //     if (payload.entry == 'po') {
        //         state.eorder.poTable.loading = false
        //         commit('setPoTable', payload.data)
        //     }
        //     if (payload.entry == 'milestone_po') {
        //         state.eorder.milestonePoTable.loading = false
        //         commit('setPo2Table', payload.data)
        //     }
        //     if (payload.entry == 'dn') {
        //         state.eorder.dn.loading = false
        //         commit('setDnTable', payload.data)
        //     }
        //     if (payload.entry == 'milestone_dn') {
        //         state.eorder.milestoneDnTable.loading = false
        //         commit('setDn2Table', payload.data)
        //     }
        //     if (payload.entry == 'billing') {
        //         state.eorder.billingTable.loading = false
        //         commit('setBillingTable', payload.data)
        //     }
        // }
        if (payload.entry == 'so_item') {
            if (payload.success) {
                commit('setSoTable', payload.data)
            }
            state.eorder.soTable.loading = false
        }
        if (payload.entry == 'po') {
            if (payload.success) {
                commit('setPoTable', payload.data)
            }
            state.eorder.poTable.loading = false
        }
        if (payload.entry == 'milestone_po') {
            if (payload.success) {
                commit('setPo2Table', payload.data)
            }
            state.eorder.milestonePoTable.loading = false
        }
        if (payload.entry == 'dn') {
            if (payload.success) {
                commit('setDnTable', payload.data)
            }
            state.eorder.dn.loading = false
        }
        if (payload.entry == 'milestone_dn') {
            if (payload.success) {
                commit('setDn2Table', payload.data)
            }
            state.eorder.milestoneDnTable.loading = false
        }
        if (payload.entry == 'billing') {
            if (payload.success) {
                commit('setBillingTable', payload.data)
            }
            state.eorder.billingTable.loading = false
        }

    },
    async['export_excel']({ state, dispatch, rootState }, payload) {
        let obj = {}
        obj.file_type = 'xlsx';
        obj.entry = 'all';
        obj['F_SO_NO'] = state.eorder.orderTable.selected[0]['F_SO_NO'];
        let msg = { command: "order__export", message: obj, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        dispatch('response/info-tip', { message: '正在导出' }, { root: true })
        state.eorder.orderTable.loading = true
    },
    async['return_order__export']({ state, dispatch, rootState }, payload) {
        if (payload.success) {
            window.open(payload.data, '_blank')
            dispatch('response/success-tip', { message: '导出成功' }, { root: true })
        }
        state.eorder.orderTable.loading = false;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
