/***********
 * create by hu at 20230427
 * 延迟加载路由配置
 *
 * 待处理问题：
 * 1，不同类型用户登录变更页面布局
 * 2，不同登录方式变更布局
 *
 * 参数注释：
 * {requiresAuth} 认证路由
 **********/
const routes = [
    { path: '/login/', name: 'login', component: () => import('../views/login.vue') },
    { path: '/sso/', name: 'sso', component: () => import('../views/sso.vue') },
    {
        path: '/', name: 'default', component: () => import('../views/layout/base.vue'),
        // meta: {requiresAuth: true},
        children: [
            {
                path: '/', name: 'home', component: () => import('../views/home.vue'), meta: { requiresAuth: true },
                redirect: { name: 'purchase-order' },
            },
            {
                path: '/purchase-order', name: 'purchase-order', component: () => import('../views/purchase-order.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/purchase-post', name: 'purchasepost', component: () => import('../views/purchase-post.vue'), meta: { requiresAuth: true }
            },

            {
                path: '/eorder', name: 'eorder', component: () => import('../views/eorder.vue'), meta: { requiresAuth: true }
            },

            {
                path: '/stock', name: 'stock', component: () => import('../views/stock.vue'), meta: { requiresAuth: true }
            },
            // {
            //     path: '/erp-stock', name: 'erp-stock', component: () => import('../views/erp-stock.vue'), meta: { requiresAuth: true }
            // },

            {
                path: '/materials', name: 'materials', component: () => import('../views/materials.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/base', name: 'base', component: () => import('../views/base.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/bom', name: 'bom', component: () => import('../views/bom.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/spr', name: 'spr', component: () => import('../views/spr.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/zhdl', name: 'largeorderdiscounts', component: () => import('../views/zhdl.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/open-payment', name: 'outstandingaccount', component: () => import('../views/open-payment.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/credit', name: 'credit', component: () => import('../views/credit.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/purchase-receipt', name: 'purchaselist', component: () => import('../views/purchase-receipt.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/packing-list', name: 'packinglist', component: () => import('../views/packing-list.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/serial-number', name: 'serialnumber', component: () => import('../views/serial-number.vue'), meta: { requiresAuth: true }
            },

            {
                path: '/users', name: 'users', component: () => import('../views/users.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/user-permission', name: 'permission', component: () => import('../views/user-permission.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/service-state', name: 'servicestate', component: () => import('../views/service-state.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/interface-state', name: 'interfacestate', component: () => import('../views/interface-state.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/system-settings', name: 'systemsettings', component: () => import('../views/system-settings.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/erp-fields', name: 'erpfields', component: () => import('../views/erp-fields.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/system-logs', name: 'systemlogs', component: () => import('../views/system-logs.vue'), meta: { requiresAuth: true }
            },
            {
                path: '/invoice-query', name: 'invoiceQuery', component: () => import('../views/invoice-query.vue'), meta: { requiresAuth: true }
            },
        ]
    },


]

export default routes
