import tools from '@/tools/index.js'
let cacheSize = parseInt(localStorage.getItem('pageSize'))
const purchaseorderData = {
    tableSet: {
        tableName:'purchaseorderData.tableSet',
        height: '310px',
        loading: false,
        showSelection:true,
        selected: [],
        selection: 'single',
        rowkey: 'F_BillNo',
        orderby: 'order by F_CreatorDate desc',
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        data: [],
        columns: [
            { name: 'F_BillNo', align: 'left', label: '订单编号', field: 'F_BillNo', sortable: true },
            { name: 'F_SPRNo', align: 'left', label: '特价单号', field: 'F_SPRNo', sortable: true },
            { name: 'F_CreatorDate', align: 'left', label: '订单创建日期', field: 'F_CreatorDate', sortable: true },
            { name: 'F_CreatorEmail', align: 'left', label: '订单创建人E_Mail', field: 'F_CreatorEmail', sortable: true },
            { name: 'F_CreatorName', align: 'left', label: '订单创建人', field: 'F_CreatorName', sortable: true },
            { name: 'F_CreatorTel', align: 'left', label: '订单创建人电话', field: 'F_CreatorTel', sortable: true },

            { name: 'F_SO_NO', align: 'left', label: '西门子销售订单号', field: 'F_SO_NO', sortable: true },
            { name: 'CREATE_ORDER_STATUS', align: 'center', label: '订单发送状态', field: 'CREATE_ORDER_STATUS', sortable: true ,
                isShowState:true,
                state:{
                    '2':{name:'far fa-check-circle',class:'text-positive'},
                    '0':{name:'fas fa-history',class:'text-warning'},
                    '1':{name:'fas fa-history',class:'text-warning'},
                    '3':{name:'fas fa-times-circle',class:'text-negative'}
                }
            },
            { name: 'STATUS_DESC', align: 'left', label: '审核状态', field: 'STATUS_DESC', sortable: true },
            { name: 'RESULT', align: 'left', label: '审核结果说明', field: 'RESULT', sortable: true },
            { name: 'send_time', align: 'left', label: '订单发送时间', field: 'send_time', sortable: true },
        ],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [
                {
                    sign: 'send',
                    index: 2,
                    name: ['订单发送', ''],
                    icon: 'fas fa-cloud-upload-alt',
                    click: ['outbound', 'tools_set'],
                },
                { index: 3, name: ['清除电子订单发送记录'], icon: 'fas fa-trash', check_lines: 0, on_click: [], sign: 'delete', },

                {
                    sign: 'refresh',
                    index: 2,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },
            ]
        }
    },
    entrySet: {
        tableName:'purchaseorderData.entrySet',
        loading: false,
        pagination: { rowsPerPage: 0 },
        rowkey: 'FEntryID',
        selection: 'single',
        selected: [],
        data: [],
        columns: [
            { name: 'F_LineNo', align: 'right', label: '订单行号', field: 'F_LineNo', sortable: true ,format: (val) => `${ val == 'None'?'':val }`},
            { name: 'F_ERPMaterialCode', align: 'left', label: 'ERP物料编码', field: 'F_ERPMaterialCode', sortable: true ,format: (val) => `${ val == 'None'?'':val }`},
            { name: 'F_ERPMaterialName', align: 'left', label: 'ERP物料名称', field: 'F_ERPMaterialName', sortable: true ,format: (val) => `${ val == 'None'?'':val }`},
            { name: 'F_MaterialNo', align: 'left', label: '订货号', field: 'F_MaterialNo', sortable: true ,format: (val) => `${val !== null ? val : ''}`},
            { name: 'F_ProductOption', align: 'left', label: '选件', field: 'F_ProductOption', sortable: true ,format: (val) => `${ val == 'None'?'':val }`},
            { name: 'QUANTITY', align: 'right', label: '数量', field: 'QUANTITY', sortable: true ,format: (val) => `${ val == 'None'?'':val }`},
            { name: 'F_ERPUnit', align: 'left', label: 'ERP单位', field: 'F_ERPUnit', sortable: true ,format: (val) => `${ val == 'None'?'':val }`},
            { name: 'L2_PRICE', align: 'right', label: '列表价', field: 'L2_PRICE', sortable: true ,format: (val) => `${ val == 'None'?'':tools.formattedPrice(val) }`},
            { name: 'FCess', align: 'left', label: '税率', field: 'FCess', sortable: true ,format: (val) => `${ val == 'None'?'':val }`},
            { name: 'FTaxPrice', align: 'right', label: '含税单价', field: 'FTaxPrice', sortable: true ,format: (val) => `${ val == 'None'?'':tools.formattedPrice(val) }`},
            { name: 'DISCOUNT', align: 'left', label: '折扣率', field: 'DISCOUNT', sortable: true ,format: (val) => `${ val == 'None'?'':val }`},
            { name: 'FDescount', align: 'right', label: '折扣额', field: 'FDescount', sortable: true ,format: (val) => `${ val == 'None'?'':tools.formattedPrice(val) }`},
            { name: 'FPriceDiscount', align: 'right', label: '实际含税单价', field: 'FPriceDiscount', sortable: true ,format: (val) => `${ val == 'None'?'':tools.formattedPrice(val) }`},
            { name: 'F_Amount', align: 'right', label: '金额', field: 'F_Amount', sortable: true ,format: (val) => `${ val == 'None'?'':tools.formattedPrice(val) }`},
            { name: 'FAllAmount', align: 'right', label: '价税合计', field: 'FAllAmount', sortable: true ,format: (val) => `${ val == 'None'?'':tools.formattedPrice(val) }`},
            { name: 'F_ARVDate', align: 'left', label: '期望交货日期', field: 'F_ARVDate', sortable: true ,format: (val) => `${ val == 'None'?'':val }`},
        ],
        tools: { show_toolbar: true, },
        // height: ''
    }
}
const state = () => ({
    purchaseorder: Object.assign({}, purchaseorderData)
})
const getters = {
    tableSet: state => state.purchaseorder.tableSet,
    entrySet: state => state.purchaseorder.entrySet,
    getSelected: state => state.purchaseorder.tableSet.selected.length > 0
}
const mutations = {
    clearSelected(state) {
        state.purchaseorder.tableSet.selected = []
    },
    changeSelect(state, data) {
        state.purchaseorder.tableSet.selected = [data]
    },
    purchase_list(state, data) {
        state.purchaseorder.tableSet.data = data.data.data
        state.purchaseorder.tableSet.selected = [data.data.data[0]]
        state.purchaseorder.tableSet.pagination.rowsNumber = data.data.count
    },
    purchase__entry(state, data) {
        state.purchaseorder.entrySet.data = data.data
    },
    entryChecked(state, data){
        // let ids = state.purchaseorder.entrySet.selected.map(item => item.FEntryID)
        // if (ids.includes(data.FEntryID)) {
        //     state.purchaseorder.entrySet.selected = state.purchaseorder.entrySet.selected.filter(item => item.FEntryID !== data.FEntryID)
        // } else {
        //     state.purchaseorder.entrySet.selected.push(data)
        // }
        state.purchaseorder.entrySet.selected = [data]
    }
    // ['refreshTableHeight'](state,data){
    //     state.purchaseorder.tableSet.height = data + 'px'
    // }
}
const actions = {

    //父表
    async ['fetch-client']({state,dispatch, rootState}, payload){
        if(state.purchaseorder.tableSet.loading == true) return
        payload.orderby = state.purchaseorder.tableSet.orderby
        let msg = {command: "purchase__list", message: payload, to: "client"}
        dispatch('channel/socket_send', msg, {root: true})
        state.purchaseorder.tableSet.loading = true
    },

    async ['return_purchase__list']({ state, commit }, payload) {
        state.purchaseorder.tableSet.loading = false
        commit('purchase_list', payload)
    },
    //子表
    async ['fetch-detail']({ state, dispatch, rootState }, payload) {
        if(state.purchaseorder.entrySet.loading == true) return
        let msg = { command: "purchase__entry", message: payload, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        state.purchaseorder.entrySet.loading = true
    },
    async ['return_purchase__entry']({ state, commit }, payload) {
        state.purchaseorder.entrySet.loading = false
        commit('purchase__entry', payload)
    },
    //|purchase__clean_send_state| 采购订单删除发送状态|  message: {'F_BillNo':''}
    async ['fetch-delete']({ state, dispatch, rootState }, payload) {
        let msg = {command: "purchase__clean_send_state", message: {'F_BillNo':state.purchaseorder.tableSet.selected[0].F_BillNo}, to: "client"}
        dispatch('channel/socket_send', msg, { root: true })
    },
    async ['return_purchase__clean_send_state']({ state, commit,dispatch }, payload) {
        console.log(payload,'res///');
       if(payload.success){
        const params = {multi_query: {}, query: {}}
        params.pagination = state.purchaseorder.tableSet.pagination
        dispatch('fetch-client',params)
       }
    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
