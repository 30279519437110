let cacheSize = parseInt(localStorage.getItem('pageSize'));
function setRowColor(item) {
    item.color = 'white'
    item.bgcolor = '#C10015'
}
const tableData = {
    listTable: {
        tableName:'table_purchase_receipt',
        loading: false,
        showSelection:true,
        selection: 'multiple',
        selected: [],
        rowkey: 'id',
        orderby: 'order by DELIVERY_NO desc, DELIVERY_ITEM_NO',
        pagination:{ rowsPerPage: cacheSize?cacheSize:30, rowsNumber: 0, page: 1 },
        data: [],
        columns: [
            // {
            //     check: true, required: false, name: 'id', label: 'ID', align: 'left', field: 'id', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            // {
            //     check: true, required: false, name: 'Option', label: '变更类型', align: 'left', field: 'Option', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            {
                check: true, required: false, name: 'erp_stock_number', label: 'ERP外购入库单号', align: 'left', field: 'erp_stock_number', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_SO_NO', label: '西门子销售订单号', align: 'left', field: 'F_SO_NO', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'SO_ITEM_NO', label: 'SO行号', align: 'right', field: 'SO_ITEM_NO', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_CUSTOMER_PO_NO', label: '客户订单号', align: 'left', field: 'F_CUSTOMER_PO_NO', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'CUSTOMER_ITEM_NO', label: 'ERP订单行号', align: 'right', field: 'CUSTOMER_ITEM_NO', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true,  name: 'match_state', label: '差异状态', align: 'right', field: 'match_state', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`,
                isShowState:true,
                state:{
                    '1':{name:'fas fa-exclamation-circle',class:'text-warning'},
                }
            },
            {
                check: true,  name: 'match_msg', label: '差异信息', align: 'right', field: 'match_msg', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true, required: false, name: 'DELIVERY_NO', label: '发货单号', align: 'left', field: 'DELIVERY_NO', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            // {
            //     check: true, required: false, name: 'DELIVERY_NO_dn', label: '发货单号DN', align: 'left', field: 'DELIVERY_NO_dn', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            {
                check: true, required: false, name: 'DELIVERY_ITEM_NO', label: '发货单行号', align: 'right', field: 'DELIVERY_ITEM_NO', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Shippingpoint', label: '发货点', align: 'left', field: 'Shippingpoint', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'UCR_NO', label: '运单号', align: 'left', field: 'UCR_NO', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            // {
            //     check: true, required: false, name: 'created_at', label: '创建时间', align: 'left', field: 'created_at', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            {
                check: true,  name: 'MLFB_Sort', label: '订货号', align: 'left', field: 'MLFB_Sort', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true,  name: 'Option', label: '选件', align: 'left', field: 'Option', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            },
            {
                check: true, required: false, name: 'DELIVERY_CREATEDATE', label: 'DN创建日期', align: 'left', field: 'DELIVERY_CREATEDATE', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'DELIVERY_CREATETIME', label: 'DN创建时间', align: 'left', field: 'DELIVERY_CREATETIME', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'DELIVERY_SENDDATE', label: '货物出库时间', align: 'left', field: 'DELIVERY_SENDDATE', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'DELIVERY_QUANTITY', label: '发货数量', align: 'right', field: 'DELIVERY_QUANTITY', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            // {
            //     check: true, required: false, name: 'DELIVERY_NO', label: 'DN发货单号', align: 'left', field: 'DELIVERY_NO', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            {
                check: true, required: false, name: 'supplier_name', label: '供应商', align: 'left', field: 'supplier_name', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'unit', label: '单位', align: 'left', field: 'unit', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'stock_name', label: '仓库名称', align: 'left', field: 'stock_name', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'make_mode', label: '生成模式', align: 'left', field: 'make_mode', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'maked_by', label: '操作人', align: 'left', field: 'maked_by', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'maked_at', label: '生成时间', align: 'left', field: 'maked_at', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
        ],
        tools: {
            show_toolbar: true,
            btns: [
                {
                    sign: 'update',
                    index: 1,
                    name: ['生成外购入库单', ''],
                    icon: 'fas fa-file-download',
                    click: ['outbound', 'tools_set'],
                },
                {
                    sign: 'exportDn',
                    index: 2,
                    name: ['导出数据', ''],
                    icon: 'fas fa-file-export',
                    click: ['outbound', 'tools_set'],
                },
                {
                    sign: 'refresh',
                    index: 3,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },
            ]

        },
        height:''

    },
}
const dialogData = {
    dnExportDialogData: {
        loading: false,
        dialog_class: 'default',
        btns: true,
        items: [
            {
                index: 2, label: '格式', showlabel: true, name: 'mode', value: '', type: 'select', filled: false, outlined: true, disable: false,
                rules: [''],
                options: ['XLSX', 'CSV'],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                },
                minWidth: 160
            },
            {
                index: 2, label: '数据集', showlabel: true, name: 'record', value: '', type: 'select', filled: false, outlined: true, disable: false,
                options: ['导出所有差异','导出所有','导出查询结果'],
                rules: [''],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                },
                minWidth: 160
            },
        ]
    },
}



const state = () => ({
    purchaseReceipt: Object.assign({}, tableData,dialogData)
})
const getters = {
    listTable: state => state.purchaseReceipt.listTable,
    dnExportDialogData: state => state.purchaseReceipt.dnExportDialogData,
}
const mutations = {
    clearDnExportDialog(state) {
        state.eorder.dnExportDialogData.items = state.eorder.dnExportDialogData.items.map(item => {
            if(item.name == 'mode'){
                item.value = 'XLSX'
            }else if(item.name == 'record'){
                item.value = '导出所有差异'
            }
        })
    },
    purchaseChecked(state,data){
        let ids = state.purchaseReceipt.listTable.selected.map(item=>item.id)
        if( ids.includes(data.id)){
            state.purchaseReceipt.listTable.selected = state.purchaseReceipt.listTable.selected.filter(item => item.id !== data.id)
        }else{
            state.purchaseReceipt.listTable.selected.push(data)
        }
    },
    clearSelected(state){
        state.purchaseReceipt.listTable.selected = []
    },
    setList(state,data){
        state.purchaseReceipt.listTable.data = data.data.data
        state.purchaseReceipt.listTable.data.forEach(item=>{
            if(item.match_state == '1'){
                setRowColor(item);
            }
        })
        state.purchaseReceipt.listTable.pagination.rowsNumber = data.data.count
    },
    ['refreshTableHeight'](state,data){
        state.purchaseReceipt.listTable.maxHeight = data + 'px'
    }
}
const actions = {
    //生成外购入库单 getlist
    async ['fetch_purchase_receipt']({ state, dispatch, rootState }, payload) {

                if(state.purchaseReceipt.listTable.loading == true) return
                payload.orderby = state.purchaseReceipt.listTable.orderby
                let msg = { command: "dn__list", message: payload, to: "client" }
                dispatch('channel/socket_send', msg, { root: true })
                state.purchaseReceipt.listTable.loading = true
    },
    async ['return_dn__list']({ state,commit }, payload) {
        state.purchaseReceipt.listTable.loading = false
        if(payload.success){
            commit('setList',payload)
        }
    },
    //生成外购入库单
    async ['update']({ state, dispatch, rootState }) {
        const DELIVERY_NO = state.purchaseReceipt.listTable.selected.map(item =>item.DELIVERY_NO)
                let msg = { command: "dn__erp", message: {DELIVERY_NO}, to: "client" }
                dispatch('channel/socket_send', msg, { root: true })
    },
    async ['return_dn__erp']({ state,commit,dispatch }, payload) {
        commit('clearSelected')
       if(payload.success){
        dispatch('response/success-tip',{message:'操作成功'},{root:true})
        const params = {multi_line : {keys: ''}}
        params.pagination = state.purchaseReceipt.listTable.pagination
        dispatch('fetch_purchase_receipt',params)
       }
    },

    async ['export']({ state, dispatch, rootState }, payload) {
        const params = state.purchaseReceipt.dnExportDialogData.items.map(item => {
            return {
                [item.name]: item.value
            }
        })
        let obj = {}
        obj.file_type = params[0].mode ? params[0].mode.toLowerCase() : 'xlsx';
        const {pagination, ...rest}=payload
        obj = {...obj, ...rest}
        let msg = { command: "dn__export", message: obj, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        dispatch('response/info-tip', { message: '正在导出' }, { root: true })
        state.purchaseReceipt.orderTable.loading = true
    },
    async ['return_dn__export']({ state, commit, dispatch }, payload) {
        if (payload.success) {
            window.open(payload.data, '_blank')
            dispatch('response/success-tip', { message: '导出成功' }, { root: true })
        }
        state.purchaseReceipt.orderTable.loading = false;
    },
}



export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
