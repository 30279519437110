let cacheSize = parseInt(localStorage.getItem('pageSize'))
const tableData = {
    countryTable: {
        tableName:'table_countryTable', 
        loading: false,
        rowkey: 'id',
        selection: 'multiple',
        selected: [],
        orderby: 'order by code',
        showSelection:true,
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        columns: [
            // {
            //     check: true, required: false, name: 'id', label: 'ID', align: 'left', field: 'id', sortable: true,
            //     format: (val) => `${val ? val : ''}`,
            // },
            {
                check: true, required: false, name: 'code', label: '国家编码', align: 'left', field: 'code', sortable: true,
                format: (val) => `${val ? val : ''}`,
            },
            {
                check: true, required: false, name: 'name', label: '国家名称', align: 'left', field: 'name', sortable: true,
                format: (val) => `${val ? val : ''}`,
            },
            {
                check: true, required: false, name: 'comms', label: '国家说明', align: 'left', field: 'comms', sortable: true,
                format: (val) => `${val ? val : ''}`,
            },
            {
                check: true, required: false, name: 'status', label: '状态', align: 'left', field: 'status', sortable: true,
                format: (val) => `${val == 1 ? '正常' : '关闭'}`,
            },
            {
                check: true, required: false, name: 'created_at', label: '更新时间', align: 'left', field: 'created_at', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
        ],
        data: [

        ],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [

                {
                    sign: 'refresh',
                    index: 2,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },
            ]
        },
        height: ''

    },
    paymentTable: {
        tableName:'table_paymentTable', 
        loading: false,
        rowkey: 'id',
        orderby: 'order by code',
        showSelection:true,
        selection: 'multiple',
        selected: [],
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        columns: [
            // {
            //     check: true, required: false, name: 'id', label: 'ID', align: 'left', field: 'id', sortable: true,
            //     format: (val) => `${val ? val : ''}`,
            // },
            {
                check: true, required: false, name: 'code', label: '付款方式编码', align: 'left', field: 'code', sortable: true,
                format: (val) => `${val ? val : ''}`,
            },
            {
                check: true, required: false, name: 'name', label: '付款方式名称', align: 'left', field: 'name', sortable: true,
                format: (val) => `${val ? val : ''}`,
            },
            {
                check: true, required: false, name: 'comms', label: '付款方式说明', align: 'left', field: 'comms', sortable: true,
                format: (val) => `${val ? val : ''}`,
            },
            {
                check: true, required: false, name: 'status', label: '状态', align: 'left', field: 'status', sortable: true,
                format: (val) => `${val == 1 ? '正常' : '关闭'}`,
            },
            {
                check: true, required: false, name: 'created_at', label: '更新时间', align: 'left', field: 'created_at', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
        ],
        data: [

        ],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [

                {
                    sign: 'refresh',
                    index: 2,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },
            ]
        },
        height: ''
    },
    addressTable: {
        tableName:'table_addressTable', 
        loading: false,
        rowkey: 'id',
        orderby: 'order by code',
        showSelection:true,
        selection: 'multiple',
        selected: [],
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        columns: [
            // {
            //     check: true, required: false, name: 'id', label: 'ID', align: 'left', field: 'id', sortable: true,
            //     format: (val) => `${val ? val : ''}`,
            // },
            {
                check: true, required: false, name: 'code', label: '收货地址编码', align: 'left', field: 'code', sortable: true,
                format: (val) => `${val ? val : ''}`,
            },
            {
                check: true, required: false, name: 'name', label: '收货地址名称', align: 'left', field: 'name', sortable: true,
                format: (val) => `${val ? val : ''}`,
            },
            {
                check: true, required: false, name: 'comms', label: '收货地址说明', align: 'left', field: 'comms', sortable: true,
                format: (val) => `${val ? val : ''}`,
            },
            {
                check: true, required: false, name: 'status', label: '状态', align: 'left', field: 'status', sortable: true,
                format: (val) => `${val == 1 ? '正常' : '关闭'}`,
            },
            {
                check: true, required: false, name: 'created_at', label: '更新时间', align: 'left', field: 'created_at', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
        ],
        data: [

        ],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [
                { index: 2, name: ['新增行'], icon: 'fas fa-plus', check_lines: 0, on_click: [], sign: 'add', },
                { index: 1, name: ['修改'], icon: 'fas fa-edit', check_lines: 0, on_click: [], sign: 'edit', },
                { index: 3, name: ['删除行'], icon: 'fas fa-trash', check_lines: 0, on_click: [], sign: 'delete', },
                {
                    sign: 'refresh',
                    index: 2,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },
            ]
        },
        height: ''
    }
}

const dialogData = {
    addDialog: {
        dialog_class: 'defalut',
        btns: true,
        items: [
            {
                index: 2, label: '编码', placeholder: '请输入编码', showlabel: true, name: 'code', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: true,
                rules: [
                    val => (val && val.length > 0) || '请输入编码'
                ],

            },
            {
                index: 0, label: '名称', placeholder: '请输入名称', showlabel: true, name: 'name', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: true,
                rules: [
                    val => (val && val.length > 0) || '请输入名称'
                ],

            },
            {
                index: 1, label: '说明', placeholder: '请输入说明', showlabel: true, name: 'comms', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: true,
                rules: [
                    val => (val && val.length > 0) || '请输入说明'
                ],

            },
            {
                index: 2, label: '状态', showlabel: true, name: 'status', value: '1', type: 'radio', filled: false, outlined: true, disable: false,
                variant: [
                    { label: '正常', value: '1' },
                    { label: '关闭', value: '0' }
                ],
                // minWidth: 160
            },
        ],
    },
    editDialog: {
        dialog_class: 'defalut',
        btns: true,
        items: [
            {
                index: 2, label: '编码', placeholder: '请输入编码', showlabel: true, name: 'code', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: false,
                rules: [
                    ''
                ],

            },
            {
                index: 0, label: '名称', placeholder: '请输入名称', showlabel: true, name: 'name', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: false,
                rules: [
                    ''
                ],

            },
            {
                index: 1, label: '说明', placeholder: '请输入说明', showlabel: true, name: 'comms', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: false,
                rules: [
                    ''
                ],

            },
            {
                index: 2, label: '状态', showlabel: true, name: 'status', value: '1', type: 'radio', filled: false, outlined: true, disable: false,
                variant: [
                    { label: '正常', value: '1' },
                    { label: '关闭', value: '0' }
                ],
                // minWidth: 160
            },




        ],
    },
}

const state = () => ({
    base: Object.assign({}, tableData, dialogData),
    tabMode: '',
})

const getters = {
    countryTable: state => state.base.countryTable,
    paymentTable: state => state.base.paymentTable,
    addressTable: state => state.base.addressTable,
    addDialog: state => state.base.addDialog,
    editDialog: state => state.base.editDialog,
}

const mutations = {
    countrysChecked(state, data) {
        let ids = state.base.countryTable.selected.map(item => item.id)
        if (ids.includes(data.id)) {
            state.base.countryTable.selected = state.base.countryTable.selected.filter(item => item.id !== data.id)
        } else {
            state.base.countryTable.selected.push(data)
        }

    },
    payChecked(state, data) {
        let ids = state.base.paymentTable.selected.map(item => item.id)
        if (ids.includes(data.id)) {
            state.base.paymentTable.selected = state.base.paymentTable.selected.filter(item => item.id !== data.id)
        } else {
            state.base.paymentTable.selected.push(data)
        }

    },
    addressChecked(state, data) {
        let ids = state.base.addressTable.selected.map(item => item.id)
        if (ids.includes(data.id)) {
            state.base.addressTable.selected = state.base.addressTable.selected.filter(item => item.id !== data.id)
        } else {
            state.base.addressTable.selected.push(data)
        }

    },

    set_list(state, data) {
        // if (state.tabMode == 'branch') {
        //     state.base.branchTable.data = data.data.data
        //     state.base.branchTable.pagination.rowsNumber = data.data.count
        // }
        // if (state.tabMode == 'company') {
        //     state.base.companyTable.data = data.data.data
        //     state.base.companyTable.pagination.rowsNumber = data.data.count
        // }
        if (state.tabMode == 'country') {
            state.base.countryTable.data = data.data.data
            state.base.countryTable.pagination.rowsNumber = data.data.count
        }
        if (state.tabMode == 'payment') {
            state.base.paymentTable.data = data.data.data
            state.base.paymentTable.pagination.rowsNumber = data.data.count
        }
        if (state.tabMode == 'receipt_address') {
            state.base.addressTable.data = data.data.data
            state.base.addressTable.pagination.rowsNumber = data.data.count
        }
    },
    //编辑回显
    dialogEdit(state) {
        // if (state.tabMode == "branch") {
        //     const selectedList = state.base.branchTable.selected
        //     state.base.editDialog.items = state.base.editDialog.items.map(item => {
        //         return {
        //             ...item,
        //             value: selectedList[0][item.name],
        //         }
        //     })
        // }
        // if (state.tabMode == "company") {
        //     const selectedList = state.base.companyTable.selected
        //     state.base.editDialog.items = state.base.editDialog.items.map(item => {
        //         return {
        //             ...item,
        //             value: selectedList[0][item.name],
        //         }
        //     })
        // }
        if (state.tabMode == "country") {
            const selectedList = state.base.countryTable.selected
            state.base.editDialog.items = state.base.editDialog.items.map(item => {
                return {
                    ...item,
                    value: selectedList[0][item.name],
                }
            })
        }
        if (state.tabMode == "payment") {
            const selectedList = state.base.paymentTable.selected
            state.base.editDialog.items = state.base.editDialog.items.map(item => {
                return {
                    ...item,
                    value: selectedList[0][item.name],
                }
            })
        }
        if (state.tabMode == "receipt_address") {
            const selectedList = state.base.addressTable.selected
            state.base.editDialog.items = state.base.editDialog.items.map(item => {
                return {
                    ...item,
                    value: selectedList[0][item.name],
                }
            })
        }


    },
    //清空
    clearDialog(state) {
        state.base.addDialog.items = state.base.addDialog.items.map(item => {
            return { ...item, value: '' }
        })
    },
    //
    clearSelected(state) {
        // if (state.tabMode == "branch") {
        //    state.base.branchTable.selected = []
        // }
        // if (state.tabMode == "company") {
        //     state.base.companyTable.selected = []

        // }
        if (state.tabMode == "country") {
            state.base.countryTable.selected = []

        }
        if (state.tabMode == "payment") {
            state.base.paymentTable.selected = []

        }
        if (state.tabMode == "receipt_address") {
            state.base.addressTable.selected = []

        }

    },
    ['refreshTableHeight'](state, data) {
        state.base.countryTable.maxHeight = data + 'px'
        state.base.paymentTable.maxHeight = data + 'px'
        state.base.addressTable.maxHeight = data + 'px'
    }
}
const actions = {
    //getList
    async fetch_list({ state, dispatch, rootState }, payload) {
        //保留tab页面状态
        state.tabMode = payload.base_name
        if (state.tabMode == "country") {
            payload.pagination = state.base.countryTable.pagination;
            if (state.base.countryTable.loading == true) return
            payload.orderby = state.base.countryTable.orderby
            let msg = { command: "base__list", message: payload, to: "client" }
            dispatch('channel/socket_send', msg, { root: true })
            state.base.countryTable.loading = true
        }
        if (state.tabMode == "payment") {
            payload.pagination = state.base.paymentTable.pagination;
            if (state.base.paymentTable.loading == true) return
            payload.orderby = state.base.paymentTable.orderby
            let msg = { command: "base__list", message: payload, to: "client" }
            dispatch('channel/socket_send', msg, { root: true })
            state.base.paymentTable.loading = true
        }
        if (state.tabMode == "receipt_address") {
            payload.pagination = state.base.addressTable.pagination;
            if (state.base.addressTable.loading == true) return
            payload.orderby = state.base.addressTable.orderby
            let msg = { command: "base__list", message: payload, to: "client" }
            dispatch('channel/socket_send', msg, { root: true })
            state.base.addressTable.loading = true
        }
    },
    async return_base__list({ state, commit }, payload) {
        // state.base.branchTable.loading = false
        // state.base.companyTable.loading = false
        state.base.countryTable.loading = false
        state.base.paymentTable.loading = false
        state.base.addressTable.loading = false
        commit('set_list', payload)
        commit('clearSelected')
    },
    //新增
    async fetch_add({ state, dispatch, rootState }) {
        let arr = state.base.addDialog.items.map(item => {
            return { [item.name]: item.value }
        })
        let message = {
            base_name: state.tabMode,
            data: [{ ...arr[0], ...arr[1], ...arr[2], ...arr[3] }]
        }
        let msg = { command: "base__add", message: message, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
    },
    async return_base__add({ state, commit, dispatch }, payload) {
        if (payload.success == true) {
            dispatch('response/success-tip', { message: '新增操作成功' }, { root: true })
            dispatch('fetch_list', { base_name: state.tabMode })
        }
    },
    //删除
    async fetch_delete({ state, dispatch, rootState }) {
                let ids = []
                if (state.tabMode == "country") {
                    ids = state.base.countryTable.selected.map(item => item.id)
                }
                if (state.tabMode == "payment") {
                    ids = state.base.paymentTable.selected.map(item => item.id)
                }
                if (state.tabMode == "receipt_address") {
                    ids = state.base.addressTable.selected.map(item => item.id)
                }
                let parmas = {
                    base_name: state.tabMode,
                    ids: ids
                }
                let msg = { command: "base__delete", message: parmas, to: "client" }
                dispatch('channel/socket_send', msg, { root: true })
    },
    async return_base__delete({ state, commit, dispatch }, payload) {
        if (payload.success == true) {
            dispatch('response/success-tip', { message: '删除成功' }, { root: true })
            dispatch('fetch_list', { base_name: state.tabMode })
        }
    },
    //跟新
    async fetch_edit({ state, dispatch, rootState }) {
                let id
                if (state.tabMode == "country") {
                    id = state.base.countryTable.selected[0].id
                }
                if (state.tabMode == "payment") {
                    id = state.base.paymentTable.selected[0].id
                }
                if (state.tabMode == "receipt_address") {
                    id = state.base.addressTable.selected[0].id
                }
                let data = {}, params = {}
                state.base.editDialog.items.map(item => {
                    data[item.name] = item.value
                })
                params.primary = 'id'
                params.data = [{ ...data, id }]
                params.base_name = state.tabMode
                let msg = { command: "base__patch", message: params, to: "client" }
                dispatch('channel/socket_send', msg, { root: true })
    },
    async retrun_base__patch({ state, commit, dispatch }, payload) {
        if (payload.success == true) {
            dispatch('response/success-tip', { message: '修改成功' }, { root: true })
            dispatch('fetch_list', { base_name: state.tabMode })
        }
    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
