import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';
import langCN from 'quasar/lang/zh-CN.js'
import iconSet from 'quasar/icon-set/fontawesome-v5.js'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import './styles/quasar.scss'
import router from './router'
import store from './store'
import './tools/console.js'  // 直接引入即可，因为是自执行函数
const quasar_options = {
    lang: langCN,
    iconSet: iconSet,
    plugins: {
        Notify,
        AppFullscreen,
        Loading,
		Dialog
    },
    config: {
        animations: 'all',
        loading:{
          message: '数据加载中，请稍候...',
          boxClass: 'bg-grey-2 text-grey-9',
          spinnerColor: 'primary',          
        }
    },
}
createApp(App)
    .use(Quasar, quasar_options)
    .use(store)
    .use(router)
    .mount('#app')
