import API from '@/apis'
const servicestateData = {
    loading:false,
    processing:false,   //用于在线更新、启动服务、停止服务
    aidRunState:false,
    clientRunState:false,
    lastState:{
        "windows_version": null,
        "windows_service_name": null,
        "install_file_path": null,
        "lanip": null,
        "ip": null,
        "version": null,
        "last_runtime": null,
    }
}
const state = () => ({
    servicestate: Object.assign({}, servicestateData)
})
const getters = {
    aidRunState: state => state.servicestate.aidRunState,
    clientRunState: state => state.servicestate.clientRunState,
    lastState: state => state.servicestate.lastState,
    loading: state => state.servicestate.loading,
    processing: state => state.servicestate.processing,
}
const mutations = {
    update_service_status(state,data){
        console.log("update_service_status","********",data)
        if (data.hasOwnProperty("main")) {
            state.servicestate.clientRunState = data.main
        } else if (data.hasOwnProperty("aid")) {
            state.servicestate.aidRunState = data.aid
        }
    },

    //更新主程序状态
    changeClientRunState(state,data){
        console.log("update_service_status","********",data)
        state.servicestate.clientRunState = data
    },

    //中间模块的数据更新
    update_last_service_state(state,data){
        console.log('update_last_service_state',data)
        state.servicestate.lastState = data
    },

    //刷新状态
    refresh_status(state,data){
        console.log('refresh_status',data)
        state.servicestate.lastState = {
            ...state.servicestate.lastState,
            ...data
        }
    }
}
const actions = {
    async ['fetch-client']({state,dispatch, rootState,commit}){
        if(state.servicestate.loading == true) return
        let msg = {command: "client_get__service_status", message: {}, to: "aid"}
        dispatch('channel/socket_send', msg, {root: true})

        let msg2 = {command: "client_get__service_status", message: {}, to: "client"}
        dispatch('channel/socket_send', msg2, {root: true})

        API.serviceState().then(res => {
            if (res.status == '200') {
                commit('update_last_service_state', res.data[0])
            }
        }).catch(error => {
        })
        
        state.servicestate.loading = true
        
    },
    async ['return_client_get__service_status'] ({state,commit}, payload){
        state.servicestate.loading = false
        commit('update_service_status',payload.data)
    },

    //点击刷新
    async ['refresh-state']({state,dispatch, rootState}){
        if(state.servicestate.processing == true) return
        dispatch('response/info-tip', { message: '执行中，请耐心等待' }, { root: true })
        state.servicestate.processing = true

        //检查WS连接是否正常
        let msg = {command: "client_get__main_state", message: {}, to: "client"}
        dispatch('channel/socket_send', msg, {root: true})
    },

    async ['return_client_get__main_state'] ({state,dispatch,commit}, payload){
        if(payload.success){
            dispatch('response/success-tip', { message: '程序响应成功' }, { root: true })
            commit('refresh_status',payload.data)
        } else {
            dispatch('response/error-tip', { message: '程序响应失败，请稍后重试，或联系管理员。' }, { root: true })
        }
        state.servicestate.processing = false
    },

    //检测是否有新版本
    async ['check_update_edi']({state,dispatch,commit}){
        state.servicestate.processing = true
        dispatch('response/info-tip', { message: '正在检测是否有新版本...' }, { root: true })
        API.checkServiceState().then(res => {
            if (res.status == '200') {
                let message = res.data.message
                if (message.length) {
                    //把错误提示出来
                    dispatch('response/error-tip', { message: message }, { root: true })
                } else {
                    dispatch("update_edi", res.data);
                }
            }
            state.servicestate.processing = false
        }).catch(error => {
            state.servicestate.processing = false
        })
    },

    //有新版本后直接升级
    async ['update_edi']({state,dispatch, rootState},payload){
        dispatch('response/info-tip', { message: '升级中，升级操作大约需要2分钟，请耐心等待...' }, { root: true })
        state.servicestate.processing = true

        let msg = {command: "update_main_program_online", message: payload, to: "aid"}
        dispatch('channel/socket_send', msg, {root: true})
    },

    async ['return_update_main_program_online'] ({state,dispatch,commit}, payload){
        if(payload.success){
            dispatch('response/success-tip', { message: payload.data.length ? payload.data : '更新成功' }, { root: true })
            dispatch("fetch-client");
        } else {
            dispatch('response/error-tip', { message: payload.data.length ? payload.data : '更新失败请稍后重试，或联系管理员。' }, { root: true })
        }
        state.servicestate.processing = false
    },

    async ['start_edi']({state,dispatch, rootState}){
        if(state.servicestate.processing == true) return
        dispatch('response/info-tip', { message: '正在启动...' }, { root: true })
        state.servicestate.processing = true

        let msg = {command: "running_main_program_online", message: {}, to: "aid"}
        dispatch('channel/socket_send', msg, {root: true})
    },

    async ['return_running_main_program_online'] ({state,dispatch,commit}, payload){
        if(payload.success){
            dispatch('response/success-tip', { message: payload.data.length ? payload.data : '启动成功' }, { root: true })
            commit('changeClientRunState',true)
        } else {
            dispatch('response/error-tip', { message: payload.data.length ? payload.data : '启动失败请稍后重试，或联系管理员。' }, { root: true })
        }
        state.servicestate.processing = false
    },

    async ['stop_edi']({state,dispatch, rootState}){
        if(state.servicestate.processing == true) return
        dispatch('response/info-tip', { message: '正在停止...' }, { root: true })
        state.servicestate.processing = true

        let msg = {command: "shutdown_main_program_online", message: {}, to: "aid"}
        dispatch('channel/socket_send', msg, {root: true})
    },

    async ['return_shutdown_main_program_online'] ({state,dispatch,commit}, payload){
        if(payload.success){
            dispatch('response/success-tip', { message: payload.data.length ? payload.data : '停止成功' }, { root: true })
            commit('changeClientRunState',false)
        } else {
            dispatch('response/error-tip', { message: payload.data.length ? payload.data : '停止失败请稍后重试，或联系管理员。' }, { root: true })
        }
        state.servicestate.processing = false
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}