const PAGES = {
    home: { id: 1, icon: 'fas fa-home', title: '主页', name: 'home', to: '/', path: '/' },
    purchaseOrder: { id: 1, icon: 'fa fa-shopping-cart', title: 'ERP采购订单', name: 'purchase-order', to: '/purchase-order', path: '/purchase-order' },
    purchasePost: { id: 2, icon: 'fas fa-cloud-upload-alt', title: '订单发送', name: 'purchasepost', to: '/purchase-post', path: '/purchase-post' },
    eorder: { id: 3, icon: 'fas fa-calendar-check', title: '电子订单状态', name: 'eorder', to: '/eorder', path: '/eorder' },
    materials: { id: 1, icon: 'fas fa-cube', title: '物料主数据', name: 'materials', to: '/materials', path: '/materials' },
    stock: { id: 2, icon: 'fas fa-warehouse', title: '库存查询', name: 'stock', to: '/stock', path: '/stock' },
    // erpStock: { id: 9, icon: 'fas fa-warehouse', title: 'ERP库存查询', name: 'erp-stock', to: '/erp-stock', path: '/erp-stock' },
    spr: { id: 3, icon: 'fas fa-tag', title: '特价单查询', name: 'spr', to: '/spr', path: '/spr' },
    largeOrderDiscounts: { id: 3, icon: 'fas fa-donate', title: '大订单折扣查询', name: 'largeorderdiscounts', to: '/zhdl', path: '/zhdl' },
    outstandingAccount: { id: 3, icon: 'fas fa-stamp', title: '未清账款查询', name: 'outstandingaccount', to: '/open-payment', path: '/open-payment' },
    invoiceQuery: { id: 3, icon: 'fas fa-money-check', title: '发票信息查询', name: 'invoiceQuery', to: '/invoice-query', path: '/invoice-query' },
    credit: { id: 3, icon: 'far fa-credit-card', title: '信用查询 ', name: 'credit', to: '/credit', path: '/credit' },
    bom: { id: 3, icon: 'fas fa-project-diagram', title: 'BOM查询', name: 'bom', to: '/bom', path: '/bom' },
    purchaseList: { id: 3, icon: 'fas fa-file-download', title: '生成外购入库单', name: 'purchaselist', to: '/purchase-receipt', path: '/purchase-receipt' },
    packingList: { id: 1, icon: 'fas fa-truck-loading', title: '装箱单信息', name: 'packinglist', to: '/packing-list', path: '/packing-list' },
    serialNumber: { id: 2, icon: 'fas fa-puzzle-piece', title: '序列号', name: 'serialnumber', to: '/serial-number', path: '/serial-number' },
    base: { id: 6, icon: 'fas fa-database', title: '基础数据', name: 'base', to: '/base', path: '/base' },
    interfaceState: { id: 3, icon: 'fas fa-chart-bar', title: '接口状态', name: 'interfacestate', to: '/interface-state', path: '/interface-state' },
    serviceState: { id: 3, icon: 'fab fa-windows', title: '服务状态', name: 'servicestate', to: '/service-state', path: '/service-state' },
    users: { id: 1, icon: 'fas fa-users-cog', title: '用户管理', name: 'users', to: '/users', path: '/users' },
    permission: { id: 2, icon: 'fas fa-id-badge', title: '用户权限', name: 'permission', to: '/user-permission', path: '/user-permission' },
    systemSettings: { id: 4, icon: 'fas fa-cogs', title: '系统设置', name: 'systemsettings', to: '/system-settings', path: '/system-settings' },
    erpFields: { id: 7, icon: 'fas fa-coins', title: 'ERP更新配置', name: 'erpfields', to: '/erp-fields', path: '/erp-fields' },
    systemLogs: { id: 5, icon: 'fas fa-file-medical-alt', title: '系统日志', name: 'systemlogs', to: '/system-logs', path: '/system-logs' },
}
const pagesArr = Object.values(PAGES)
const pages = {
    pagesObj: PAGES,
    pagesArr,
}
function matchPage(path) {
    for (let i = 0; i < pagesArr.length; i++) {
        if (pagesArr[i].path === path) {
            return pagesArr[i]
        }
    }
    return null
}

const LeftMenuData = {
    left_menu: {
        status: true,
        menus: [
            [
                PAGES.purchaseOrder,
                PAGES.eorder,
            ],
            [
                PAGES.materials,
                PAGES.stock,
                // PAGES.erpStock,
                PAGES.spr,
                PAGES.largeOrderDiscounts,
                PAGES.outstandingAccount,
                PAGES.invoiceQuery,
                PAGES.credit,
                PAGES.bom,
            ],
            [
                PAGES.purchaseList,
                PAGES.packingList,
                PAGES.serialNumber,
            ],
            [
                PAGES.base,
                PAGES.interfaceState,
                PAGES.serviceState,
                PAGES.users,
                PAGES.permission,
                PAGES.systemSettings,
                PAGES.erpFields,
                PAGES.systemLogs,
            ],
        ]
    }
}


const rightDrawerSatus = {
    right_drawer: {
        status: true,
    }
}
// const base_tabs = [{ icon: 'fas fa-home', name: 'home', path: '/', title: '主页' }]
const base_tabs = [PAGES.stock]
// const base_tabs = []

const stickyTab = {
    //如果是erp登录  navbar默认是空  如果是入口登录的,navbar默认是ERP采购订单
    tabs: erpPlug && erpPlug.params ?
       ( sessionStorage.getItem('emis-page-sticky-tabs') ? JSON.parse(sessionStorage.getItem('emis-page-sticky-tabs')) : [] ):
        (sessionStorage.getItem('emis-page-sticky-tabs') ? JSON.parse(sessionStorage.getItem('emis-page-sticky-tabs')) : base_tabs)
}
// const dialogStatus = {
//     defalutDialog:{
//         status:true,
//     }
// }
const state = () => ({
    page: Object.assign({}, LeftMenuData, rightDrawerSatus, stickyTab, pages)
})
const getters = {
    BaseMenu: state => state.page.left_menu,
    drawerStatus: state => state.page.right_drawer.status,
    getStickyTabs: state => state.page.tabs,
    // dialogStatus: state => state.page.defalutDialog.status
    pagesObj: state => state.page.pagesObj,
    pagesArr: state => state.page.pagesArr,
    tabs: state => state.page.tabs,
}
const mutations = {
    ['change-left-menu-status'](state) {
        state.page.left_menu.status = !state.page.left_menu.status
    },
    ['change-right-drawer-status'](state) {
        state.page.right_drawer.status = !state.page.right_drawer.status
    },
    // ['change-dialog'](state){
    //     state.page.defalutDialog.status = !state.page.defalutDialog.status
    // }
    ['add_tab'](state, data) {
        let tabs = state.page.tabs
        let obj = tabs.filter(it => {
            return it.name == data.name
        })
        if (obj.length < 1) {
            tabs.push(data)
            sessionStorage.setItem('emis-page-sticky-tabs', JSON.stringify(tabs))
        }
    },
    ['delete_tab'](state, data) {
        let obj = state.page.tabs.filter(it => {
            return it.name != data.name
        })
        state.page.tabs = obj
        sessionStorage.setItem('emis-page-sticky-tabs', JSON.stringify(obj))
    },
}
const actions = {
    ['add_sticky_tab']({ state, commit }, payload) {
        let ms = state.page.left_menu.menus
        // let obj = { icon: 'fas fa-home', name: 'home', path: '/', title: '主页', to: '/' }
        let obj = {}
        let res = matchPage(payload.path)
        if (res && res.name !== PAGES.home.name) {
            obj.icon = res.icon
            obj.name = res.name
            obj.path = res.to
            obj.title = res.title
            commit('add_tab', obj)
        }
        // if (obj.name != 'main') {
        //     commit('add_tab', obj)
        // }

    },
    ['delete_sticky_tab']({ commit }, payload) {
        commit('delete_tab', payload)
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
