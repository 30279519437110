const netmanagerData = {
    netData: [
        // {
        //     api: '',
        //     template: '',
        //     timeout: 20,
        //     begin_time: null     //时间戳
        // }
    ],
    timer:null
}

const state = () => ({
    netmanager: Object.assign({}, netmanagerData)
})
const getters = {
    netData: state => state.netmanager.netData,
    timer: state => state.netmanager.timer,
}

const mutations = {
    //把网络加入到堆栈
    add_net_to_manager(state, payload) {
        state.netmanager.netData.push({
            api: payload.api,
            template: payload.template ? payload.template : '',
            timeout: (payload.timeout && (typeof payload.timeout === 'number')) ? payload.timeout : 30,
            begin_time: Math.round(new Date() / 1000)
        })
        nlog(`++++++${payload.api}加入网络管理类`,'netData = ',state.netmanager.netData)
    },

    //移除网络
    remove_net_from_manager(state, payload) {
        state.netmanager.netData = state.netmanager.netData.filter((item) => item.api !== payload.api && item !== payload.template)
        nlog(`------${payload.api}从网络管理类移除`,'netData = ',state.netmanager.netData)
    },

    //移除全部网络
    remove_all_net_from_manager(state, payload) {
        state.netmanager.netData = []
        nlog('- - - - - -移除全部网络')
    }
}

const actions = {
    //网络发起
    net_request({ state, commit, dispatch }, payload) {
        nlog(`${payload.api} net_request`)
        if (!state.netmanager.timer) {
            nlog('检测到未启动定时器，先去创建定时器')
            dispatch('setup')
        }
        commit('add_net_to_manager', payload)
    },
    //网络取消
    net_cancle({ state, commit, dispatch }, payload) {
        commit('remove_net_from_manager', payload)
    },

    //网络成功
    net_sucess({ state, commit, dispatch }, payload) {
        nlog(`${payload.api} net_sucess`)
        commit('remove_net_from_manager', payload)
    },

    //网络失败
    net_fail({ state, commit, dispatch }, payload) {
        nlog(`${payload.api} net_fail`)
        commit('remove_net_from_manager', payload)
    },

    //网络超时
    net_timeout(state, payload) {
        state.commit('remove_net_from_manager', payload)

        //通知对应的页面，去修改loading值
        let msg = {
            message: {
                success: false,
                data: '网络超时，请稍后再试',
                item: payload,
            },
            command: payload.api
        }
        state.dispatch('channel/socket_msg_timeout',msg,{root: true})
        state.dispatch('response/info-tip', { message: '正在获取数据,请稍后...' }, { root: true })
    },

    //开始监测
    setup({state, dispatch, commit}) {
        //停止旧定时器
        if (state.netmanager.timer) {
            dispatch('stop')
        }

        //开启新定时器
        nlog('netmanager 开始监测')
        state.netmanager.timer = setInterval(() => {
            nlog('netmanager 定时器运行中...',state.netmanager.netData)
            for (const item of state.netmanager.netData) {
                let now = Math.round(new Date() / 1000)
                let before = item.begin_time
                //订单发送页面，数据校验（purchase__check），发送订单（purchase__send）超时后不清除状态
                if (now - before >= item.timeout && !['purchase__check','purchase__send'].includes(item.api)) {
                    dispatch('net_timeout',item)
                }
            }
        }, 1000);
    },

    //停止监测
    stop({state, commit}) {
        commit('remove_all_net_from_manager')
        if (state.netmanager.timer) {
            clearInterval(state.netmanager.timer)
            state.netmanager.timer = null
        }
        nlog('netmanager 停止监测')
    }
}

function nlog(...args) {
    if (localStorage.getItem('nlog') === 'open') {
        console.log(...args);
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
