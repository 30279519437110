let cacheSize = parseInt(localStorage.getItem('pageSize'))
import API from '../apis'
import utils from '@/tools'
const authData = {
    authenticated: localStorage.getItem('authenticated') ? true : false,
    userBase: localStorage.getItem('userBase') ? JSON.parse(localStorage.getItem('userBase')) : {},
    userInfo: {},
    isLoginDisabled:false
}
const usersList = {
    tableSet: {
        tableName:'table_user',
        loading: false,
        showSelection:true,
        selected: [],
        selection: 'multiple',
        rowkey: 'id',
        // pagination: { rowsPerPage: 5, rowsNumber: 0, page: 1, search: '' },
        pagination: { rowsPerPage: cacheSize?cacheSize:5, rowsNumber: 0, page: 1, search: '' },
        userRootData:[],
        allUserRootList:[],//管理的所有账号
        data: [
            // {
            //     'id': 1, 'username': 'siemens-admin', 'name': '超级管理', 'email': '', 'mobile': '010-87682132-1',
            //     'is_superuser': true, 'ban': true, 'date_joined': '2023-07-10 09:54:21', 'password': ''
            // },
            // {
            //     'id': 2, 'username': '刁小艳', 'name': '刁小艳', 'email': 'dxy828@126.com', 'mobile': '',
            //     'is_superuser': false, 'ban': false, 'date_joined': '2023-07-10 11:02:33', 'password': ''
            // },
            // {
            //     'id': 3, 'username': '金玲', 'name': '金玲', 'email': '', 'mobile': '', 'password': '',
            //     'is_superuser': false, 'ban': false, 'date_joined': '2023-07-10 11:05:22'
            // },
        ],
        columns: [
            // { name: 'id', align: 'left', label: 'ID', field: 'id', sortable: true },
            { name: 'username', align: 'left', label: '用户名', field: 'username', sortable: true },
            { name: 'name', align: 'left', label: '名称', field: 'name', sortable: true },
            { name: 'email', align: 'left', label: '邮箱', field: 'email', sortable: true },
            { name: 'mobile', align: 'left', label: '联系电话', field: 'mobile', sortable: true },
            { name: 'is_superuser', align: 'left', label: '管理员', field: 'is_superuser', sortable: true },
            { name: 'ban', align: 'left', label: '禁用状态', field: 'ban', sortable: true },
            { name: 'date_joined', align: 'left', label: '加入时间', field: 'date_joined', sortable: true },
        ],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [
                {
                    sign: 'add',
                    index: 1,
                    name: ['新增用户', ''],
                    icon: 'fas fa-user-plus',
                    click: ['outbound', 'tools_set'],
                },
                {
                    sign: 'edit',
                    index: 2,
                    name: ['修改用户', ''],
                    icon: 'fas fa-user-cog',
                    click: ['outbound', 'tools_set'],
                },
                {
                    sign: 'delete',
                    index: 3,
                    name: ['删除用户', ''],
                    icon: 'fas fa-user-times',
                    click: ['outbound', 'tools_set'],
                },
                {
                    sign: 'refresh',
                    index: 4,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },
            ]
        },
        height:''
    }
}

const dialogData = {
    dialogFormData: {
        dialog_class: 'defalut',
        btns: true,
        items: [
            {
                index: 0, label: '用户名', placeholder: '请输入用户名', showlabel: true, name: 'username', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: true,
                rules: [
                    val => (val && val.length > 0) || '请输入用户名'
                ],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                }
            },
            {
                index: 1, label: '密码', placeholder: '请输入密码', showlabel: true, name: 'password', value: '', type: 'password', filled: false, outlined: true, disable: false,
                required: true,
                rules: [
                    val => (val && val.length > 0) || '请输入密码'
                ],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                }
            },
            {
                index: 2, label: '名称', placeholder: '请输入名称', showlabel: true, name: 'name', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: true,
                rules: [
                    val => (val && val.length > 0) || '请输入名称'
                ],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                }
            },
            {
                index: 4, label: '邮箱', placeholder: '请输入邮箱', showlabel: true, name: 'email', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: false,
                rules: [
                    val => /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(val) || '请输入正确的邮箱',
                ],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                }
            },
            {
                index: 5, label: '联系电话', placeholder: '请输入联系电话', showlabel: true, name: 'mobile', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: true,
                rules: [
                    val => (val && val.length > 0) || '请输入联系电话'
                ],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                }
            },

            // {
            //     index: 2, label: '管理员', showlabel: true, name: 'is_superuser', value: '', type: 'radio', filled: false, outlined: true, disable: false,
            //     variant: [
            //         { label: '是', value: true },
            //         { label: '否', value: false }
            //     ],

            //     minWidth: 160
            // },
            // {
            //     index: 2, label: '状态', showlabel: true, name: 'ban', value: '', type: 'radio', filled: false, outlined: true, disable: false,
            //     variant: [
            //         { label: '正常', value: true },
            //         { label: '停用', value: false }

            //     ],
            //     minWidth: 160
            // },
        ],
    },
    editUserDialog: {
        dialog_class: 'defalut',
        btns: true,
        items: [
            {
                index: 0, label: '用户名', placeholder: '请输入用户名', showlabel: true, name: 'username', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: false,
                rules: [
                    ''
                ],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                }
            },
            {
                index: 1, label: '密码', placeholder: '请输入密码', showlabel: true, name: 'password', value: '', type: 'password', filled: false, outlined: true, disable: false,
                required: false,
                rules: [
                    ''
                ],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
                }
            },
            {
                index: 2, label: '名称', placeholder: '请输入名称', showlabel: true, name: 'name', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: false,
                rules: [
                    ''
                ],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                }
            },
            {
                index: 4, label: '邮箱', placeholder: '请输入邮箱', showlabel: true, name: 'email', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: false,
                rules: [
                    ''
                ],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                }
            },
            {
                index: 5, label: '联系电话', placeholder: '请输入联系电话', showlabel: true, name: 'mobile', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: false,
                rules: [
                    ''
                ],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                }
            },
        ],
    },

    groupDialog: {
        dialog_class: 'defalut',
        btns: true,
        items: [
            {
                index: 0, label: '组名称', placeholder: '支持中文', showlabel: true, name: 'name', value: '', type: 'input', filled: false, outlined: true, disable: false,
                required: true,
                rules: [
                    val => (val && val.length > 0) || '必填项'
                ],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                }
            },
        ],
    }

}

const PermissionData = {
    //用户组
    group: [
        // { 'id': 1, 'name': '管理组' },
        // { 'id': 2, 'name': '商务组' },
    ],
    //组权限设置
    permissions: [
        // { 'id': 1, 's_name': '用户管理', enable: true },
        // { 'id': 2, 's_name': '权限分配', enable: true },
        // { 'id': 3, 's_name': '订单发送', enable: true },
        // { 'id': 4, 's_name': '数据查询', enable: true },
        // { 'id': 5, 's_name': '系统配置', enable: true },
    ],
    //组内用户
    // groupUser:[{"id": 1, "username": "siemens-admin"}]
    groupUser: {
        list: []
    },
    isGetData_userList:false,
    isGetData_group:false
}
const errorDetail = {
    permissionsErrorDetail:null,    //权限列表
    groupUsersErrorDetail:null,     //组内用户列表
    groupErrorDetail:null,      //用户组
    usersErrorDetail:null,      //用户列表
}
const state = () => ({
    user: Object.assign({}, authData, usersList, PermissionData, dialogData, errorDetail)
})
const getters = {
    isLogin: state => state.user.authenticated,
    userBase: state => state.user.userBase,
    usersTable: state => state.user.tableSet,
    usersErrorDetail: state => state.user.usersErrorDetail,
    permissionsErrorDetail: state => state.user.permissionsErrorDetail,
    groupUsersErrorDetail: state => state.user.groupUsersErrorDetail,
    groupErrorDetail: state => state.user.groupErrorDetail,
    userList: state => state.user.tableSet.data, //用户
    userRootList: state => state.user.tableSet.userRootData, //用户权限
    allUserRootList: state => state.user.tableSet.allUserRootList, //当前用户管理的账号
    getPermissions: state => state.user.permissions,
    getGroup: state => state.user.group,
    getGroupUser: state => state.user.groupUser,
    dialogData: state => state.user.dialogFormData,
    editUserDialog: state => state.user.editUserDialog,
    groupDialog: state => state.user.groupDialog,
    isLoginDisabled:state => state.user.isLoginDisabled,
    isGetData_userList:state => state.user.isGetData_userList,
    isGetData_group:state => state.user.isGetData_group,
}
const mutations = {
    userChecked(state,data){
        let ids = state.user.tableSet.selected.map(item=>item.id)
        if( ids.includes(data.id)){
            state.user.tableSet.selected = state.user.tableSet.selected.filter(item => item.id !== data.id)
        }else{
            state.user.tableSet.selected.push(data)
        }
    },
    clearUserSelected(state){
        state.user.tableSet.selected = []
    },
    ['login-success'](state, data) {
        state.user.authenticated = true
        localStorage.setItem('authenticated', 1)
        state.user.userBase = data
        localStorage.setItem('userBase', JSON.stringify(data))
    },
    ['logout'](state) {
        localStorage.removeItem('authenticated')
        localStorage.removeItem('userBase')
        sessionStorage.removeItem('user-key')
        state.user.authenticated = false
    },
    ['clearDialog'](state) {
        state.user.dialogFormData.items = state.user.dialogFormData.items.map(item => {
            return {
                ...item,
                value: ''
            }
        })
    },
    ['clearGroupDialog'](state) {
        state.user.groupDialog.items = state.user.groupDialog.items.map(item => {
            return {
                ...item,
                value: ''
            }
        })
    },


    ['clearPermissions'](state) {
        for (let i = 0; i < state.user.permissions.length; i++) {
            state.user.permissions[i].enable = false;
        }
    },
    ['clearGroup'](state) {
        for (let i = 0; i < state.user.group.length; i++) {
            state.user.group[i].enable = false;
        }
    },
    ['clearGroupUser'](state){
        state.user.groupUser.list = []
    },

    ['dialogEdit'](state) {
        const selectedList = state.user.tableSet.selected
        state.user.editUserDialog.items = state.user.editUserDialog.items.map(item => {
            return {
                ...item,
                value: selectedList[0][item.name]
            }
        })
    },
    ['copyDialog'](state) {
        let list = {}
        state.user.editUserDialog.items.map(item => {
            list[item.name] = item.value
        })
        state.copyDialog = list
    },
    ['GETLIST'](state, data) {
        state.user.tableSet.data = data.results
        state.user.tableSet.pagination.rowsNumber = data.count
    },
    ['setGroupUser'](state, data) {
        state.user.tableSet.userRootData = data
        state.user.isGetData_userList = true
    },
    ['setAllGroupUser'](state, data) {
        state.user.tableSet.allUserRootList = data
    },
    //用户列表
    ['update_usersErrorDetail'](state,data){
        state.user.usersErrorDetail = data
    },
    ['update_permissionsErrorDetail'](state,data){
        state.user.permissionsErrorDetail = data
    },
    //组内用户列表
    ['update_groupUsersErrorDetail'](state,data){
        state.user.groupUsersErrorDetail = data
    },
    ['update_groupErrorDetail'](state,data){
        state.user.groupErrorDetail = data
    },

    ['setPermissions'](state, data) {
        state.user.permissions = data
    },
    ['setGroup'](state, data) {
        state.user.group = data
        state.user.isGetData_group = true
    },
    ['refreshTableHeight'](state,data){
        state.user.tableSet.maxHeight = data + 'px'
    },
    ['update_isLoginDisabled'](state,data){
        state.user.isLoginDisabled = data
    }
}
const actions = {
    async ['getAllRootUserList']({ state, commit }) {
        let query = '?&page=1&page_size=999&search='
        await API.userGetList(query).then(res => {
            if (res.status == '200') {
                commit('setAllGroupUser', res.data.results)
            } else {
                commit('setAllGroupUser',[])
            }
        }).catch(error => {
            commit('setAllGroupUser',[]);
        })
    },
    async ['get-captcha-data']({ dispatch }) {
        return new Promise((resolve, reject) => {
            API.getCaptcha().then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    async ['api-login']({ dispatch, commit }, payload) {
        let post_data = payload.login_data.value
        post_data.hashkey = payload.captcha_data.value.hashkey
        if (!post_data.hashkey) {
            let res = { status: 400, data: { error_type: '', message: '未正确获取到验证码图片。' } }
            dispatch('response/set-response', res, { root: true })
            return
        }
        if (!post_data.username) {
            let res = { status: 400, data: { error_type: 'username', message: '请填写登录名。' } }
            dispatch('response/set-response', res, { root: true })
            return
        }
        if (!post_data.password) {
            let res = { status: 400, data: { error_type: 'password', message: '请填写登录密码。' } }
            dispatch('response/set-response', res, { root: true })
            return
        }
        if (!post_data.captcha) {
            let res = { status: 400, data: { error_type: 'captcha', message: '请填写图片验证码。' } }
            dispatch('response/set-response', res, { root: true })
            return
        }
        await API.login(post_data).then(res => {
            commit('login-success', res.data.data)
            // commit('channel/update_channel_options', null, { root: true })
            // dispatch('channel/socket_init', null, { root: true })
        })
    },
    //收到登陆失效的消息，走退出登录流程
    async ['received_login_disable']({ state, commit, dispatch }) {
        if (process.env.NODE_ENV === "development" && localStorage.getItem('more-device-online') === 'open') {
            return
        }

        commit('update_isLoginDisabled', true)
        dispatch('channel/socket_close', null, { root: true })
        // localStorage.removeItem('authenticated')
        // localStorage.removeItem('userBase')
        // sessionStorage.removeItem('user-key')
        // state.user.userBase = null
        // state.user.authenticated = false
    },
    ['api-logout']({ state, commit }) {
        API.logout().then(res => {
            commit('logout')
        }).catch(error => {
            commit('logout')
        })
    },
    //user
    async ['onSearch']({ dispatch, state }, payload) {
        state.user.tableSet.pagination.search = payload
        state.user.tableSet.pagination.page = 1
        dispatch('getList')
    },
    async ['getList']({ state, commit }) {
        if (state.user.tableSet.loading == true) return
        state.user.tableSet.loading = true
        const page = state.user.tableSet.pagination.page
        const size = state.user.tableSet.pagination.rowsPerPage
        const search = state.user.tableSet.pagination.search
        let query = '?'
        if (page) { query += `&page=${page}` }
        if (size) { query += `&page_size=${size}` }
        if (search) { query += `&search=${search}` }
        await API.userGetList(query).then(res => {
            if (res.status == '200') {
                state.user.tableSet.loading = false
                commit('update_usersErrorDetail',null)
                commit('GETLIST', res.data)
            } else {
                commit('update_usersErrorDetail',"数据获取失败，请稍后再试")
            }
        }).catch(error => {
            commit('update_usersErrorDetail',error.data.detail)
        })
    },
    async ['addUser']({ state, commit, dispatch }) {
        let params = {}
        state.user.dialogFormData.items.map(item => {
            params[item.name] = item.value
        })
        params.company = state.user.userBase.company_id
        const res = await API.userAdd(params)
        if (res.status == '201') {
            dispatch('response/success-tip', { message: '新增用户操作成功' }, { root: true })
            dispatch('getList')
        }

    },
    async ['updateUser']({ state, dispatch }) {
        const id = state.user.tableSet.selected[0].id
        let params = {}
        state.user.editUserDialog.items.map(item => {
            params[item.name] = item.value
        })
        const data = utils.funDifference(params, state.copyDialog)
        const res = await API.userEdit(id, data)
        if (res.status == 200) {
            dispatch('response/success-tip', { message: '修改用户操作成功' }, { root: true })
            dispatch('getList')
        }
        state.user.tableSet.selected = []
    },
    async ['deleteUser']({ state, dispatch }) {
        const selectedIds = state.user.tableSet.selected.map(item => item.id)
        const res = await API.userDelete({ ids: selectedIds })
        if (res.status == '200') {
            dispatch('response/success-tip', { message: '删除用户操作成功' }, { root: true })
            dispatch('getList')
        }
    },
    //permission
    async ['fetchPageData']({ dispatch, commit }) {
        await dispatch('fetch_permissions_list')
        await dispatch('fetch_users_list')
        await dispatch('fetch_group_list')
        dispatch("getAllRootUserList");
    },

    async ['fetch_permissions_list']({ dispatch, commit }) {
        await API.getPermissionsList().then(res => {
            // console.log(res)
            commit('update_permissionsErrorDetail',null)
            commit('setPermissions', res.data)
        }).catch(error => {
            //   error.error_type = 'fetch_permissions_list'
            //   error.error_name = '读取权限失败：'
            //   dispatch('validate/response-error',error, {root: true})
            commit('update_permissionsErrorDetail',error.data.detail)
            commit('setPermissions', [])
            console.log(error)
        })
    },
    async ['fetch_users_list']({ state,commit, dispatch }) {
        await API.getNowUser(state.user.userBase.id).then(res => {
            commit('update_groupUsersErrorDetail',null)
            commit('setGroupUser', [res.data])
        }).catch(error => {
            //   error.error_type = 'fetch_users_list'
            //   error.error_name = '读取用户列表失败：'
            //   dispatch('validate/response-error',error, {root: true})
            commit('update_groupUsersErrorDetail',error.data.detail)
            commit('setGroupUser', [])
        })
    },
    async ['fetch_group_list']({ dispatch, commit }) {
        await API.getGroupList().then(res => {
            commit('update_groupErrorDetail',null)
            commit('setGroup', res.data)
        }).catch(error => {
            //   error.error_type = 'fetch_group_list'
            //   error.error_name = '读取用户组失败：'
            //   dispatch('validate/response-error',error, {root: true})
            commit('update_groupErrorDetail',error.data.detail)
            commit('setGroup', [])
        })
    },
    async ['addGroup']({ state, dispatch, commit }) {
        let params = {}
        state.user.groupDialog.items.map(item => {
            params[item.name] = item.value
        })
        await API.postAddGroup(params).then(res => {
            dispatch('response/success-tip', { message: '添加组操作成功' }, { root: true })
            dispatch('fetchPageData')
        }).catch(error => {
            dispatch('response/error-tip', { message: '添加组操作失败' }, { root: true })
        })
    },
    async ['deleteGroup']({ state, dispatch }, payload) {
        await API.deleteGroup(payload).then(res => {
            dispatch('response/success-tip', { message: '删除组成功' }, { root: true })
            dispatch('fetchPageData')
        }).catch(error => {
            dispatch('response/error-tip', { message: '删除失败' }, { root: true })
        })
    },
    async ['saveGroup']({ state, dispatch }, payload) {
        const id = payload
        let data = []
        state.user.permissions.map(item => {
            if (item.enable) {
                data.push(item.id)
            }
        })
        await API.saveGroup(id, data).then(res => {
            dispatch('fetchPageData')
            dispatch('response/success-tip', { message: '用户权限保存成功' }, { root: true })
        })
    },
    async ['saveUser']({ state, dispatch }, payload) {
        const id = payload
        let groups = []
        state.user.group.map(item => {
            if (item.enable) {
                groups.push(item.id)
            }
        })
        let user_permissions = []
        state.user.permissions.map(item => {
            if (item.enable) {
                user_permissions.push(item.id)
            }
        })
        await API.saveUser(id, { groups, user_permissions }).then(res => {
            dispatch('fetchPageData')
            dispatch('response/success-tip', { message: '修改用户权限成功' }, { root: true })
        })
    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
